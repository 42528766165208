import { FetchCardapioApiAsMasterUser } from 'api/cardapio/utils';
import { ApiMutation } from 'api/interfaces';
import { obterSessoesProdutos } from 'api/cardapio/queries';
import { QueryCache, useMutation, MutationConfig } from 'react-query';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

interface DesativarProdutoInput {
  empresaId: string;
  produtoId: string;
}

class DesativarProduto implements ApiMutation<DesativarProdutoInput, void> {
  async execute({ empresaId, produtoId }: DesativarProdutoInput) {
    await FetchCardapioApiAsMasterUser(`/${empresaId}/produto/${produtoId}`, {
      method: 'DELETE',
    });
  }

  updateCache(input: DesativarProdutoInput, retorno: void, cache: QueryCache) {
    const sessaoKey = obterSessoesProdutos.getKey(input.empresaId);

    return cache.invalidateQueries(sessaoKey);
  }
}

export const desativarProduto = new DesativarProduto();

export const useDesativarProduto = (
  config?: MutationConfig<void, unknown, DesativarProdutoInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Produto deletado com sucesso!',
    },
    desativarProduto,
  );

  return useMutation(desativarProduto.execute, MergeDeep(defaultConfig, config));
};

import React, { useEffect } from 'react';
import { ThemeProvider, ThemeOptions, createMuiTheme, CssBaseline } from '@material-ui/core';
import { SaurusTema } from 'tema';
import { ValidarCor } from 'utils';
import { CardapioEmpresaTema, CardapioEmpresa } from 'api/cardapio/models';
import { useObterDadosEmpresa } from 'api/cardapio/queries';
import { NotificationError } from 'erros';

interface EmpresaTemaContextType {
  tema?: CardapioEmpresaTema;
  isSuccess?: boolean;
  empresaLocalizada?: boolean;
  carregandoEmpresa?: boolean;
  atualizarTema?: (tema?: CardapioEmpresaTema) => any;
  atualizarEmpresa?: (id: string) => any;
  empresaId?: string;
  empresa?: CardapioEmpresa;
}

const EmpresaTemaContext = React.createContext<EmpresaTemaContextType>({
  tema: undefined,
  empresaLocalizada: undefined,
  carregandoEmpresa: undefined,
  atualizarTema: undefined,
  empresaId: undefined,
  empresa: undefined,
});

export const useEmpresaTema = () => {
  return React.useContext(EmpresaTemaContext);
};

export interface EmpresaTemaProviderProps {
  children: React.ReactNode;
}

export const EmpresaTema = ({ children }: EmpresaTemaProviderProps) => {
  const [empresaId, definirEmpresaId] = React.useState<string | undefined>(undefined);
  const [tema, setarTema] = React.useState(SaurusTema);
  const [empLocalizada, setarEmpLocalizada] = React.useState<boolean | undefined>(undefined);

  const {
    data: empresa,
    isLoading: carregandoEmpresa,
    error,
    refetch: obterDadosEmpresa,
  } = useObterDadosEmpresa(empresaId || '', {
    enabled: false,
    retry(n, error: any) {
      if (n > 0) return false;
      if (error.httpresponse) {
        if (error.httpresponse.status < 500)
          return false;
      }
      return true;
    },
    useErrorBoundary: false,
    onSuccess(d) {
      setarEmpLocalizada(true);
    }
  });

  React.useEffect(() => {
    if (error) {
      let err = (error as any);
      if (err.obj) {
        switch (err.obj.status) {
          case 410:
            setarEmpLocalizada(false);
            return;
        }
      }
      throw (error);
    }
  }, [error]);

  const {
    primaryContrast,
    primaryDark,
    primaryLight,
    primaryMain,
    secondaryContrast,
    secondaryDark,
    secondaryLight,
    secondaryMain,
    textPrimary,
    textSecondary,
    type,
  } = empresa?.style || {};

  React.useEffect(() => {
    if (!empresaId) return;

    if (!(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(empresaId || '')))
      throw new NotificationError('error', 'O link que você digitou está inválido e não permite nenhum tipo de ação. Leia novamente o QRCode para acessar o cardápio do estabelecimento.', 'URL Inválida!', 'UUID inválido', false);

    obterDadosEmpresa();
  }, [empresaId, obterDadosEmpresa]);

  const obterTipo = React.useCallback((type?: string | number) => {
    if (typeof type === 'number') {
      return type === 0 ? 'light' : 'dark';
    }

    return type === 'dark' || type === '1' ? 'dark' : 'light';
  }, []);

  const saurusTema = React.useMemo(() => {
    return {
      primaryMain: ValidarCor(primaryMain)
        ? (primaryMain as string)
        : SaurusTema.palette.primary.main,
      primaryDark: ValidarCor(primaryDark)
        ? (primaryDark as string)
        : SaurusTema.palette.primary.dark,
      primaryLight: ValidarCor(primaryLight)
        ? (primaryLight as string)
        : SaurusTema.palette.primary.light,
      primaryContrast: ValidarCor(primaryContrast)
        ? (primaryContrast as string)
        : SaurusTema.palette.primary.contrastText,
      secondaryMain: ValidarCor(secondaryMain)
        ? (secondaryMain as string)
        : SaurusTema.palette.secondary.main,
      secondaryDark: ValidarCor(secondaryDark)
        ? (secondaryDark as string)
        : SaurusTema.palette.secondary.dark,
      secondaryLight: ValidarCor(secondaryLight)
        ? (secondaryLight as string)
        : SaurusTema.palette.secondary.light,
      secondaryContrast: ValidarCor(secondaryContrast)
        ? (secondaryContrast as string)
        : SaurusTema.palette.secondary.contrastText,
      textPrimary: ValidarCor(textPrimary)
        ? (textPrimary as string)
        : SaurusTema.palette.text.primary,
      textSecondary: ValidarCor(textSecondary)
        ? (textSecondary as string)
        : SaurusTema.palette.text.secondary,
      type: type || 0,
    } as CardapioEmpresaTema;
  }, [
    primaryContrast,
    primaryDark,
    primaryLight,
    primaryMain,
    secondaryContrast,
    secondaryDark,
    secondaryLight,
    secondaryMain,
    textPrimary,
    textSecondary,
    type,
  ]);

  const atualizarEmpresa = React.useCallback((empresaId: string) => {

    //SE ESTÁ DEFINIDO PQ ESTADO PADRÃO É UNDEFINED
    if (empresaId)
      definirEmpresaId(empresaId);
  }, []);

  const atualizarTema = React.useCallback(
    (input?: CardapioEmpresaTema) => {
      const tipo = obterTipo(input?.type);
      const proximoTema = createMuiTheme({
        palette: {
          primary: {
            main: ValidarCor(input?.primaryMain)
              ? input?.primaryMain
              : SaurusTema.palette.primary.main,
            light: ValidarCor(input?.primaryLight)
              ? input?.primaryLight
              : SaurusTema.palette.primary.light,
            dark: ValidarCor(input?.primaryDark)
              ? input?.primaryDark
              : SaurusTema.palette.primary.dark,
            contrastText: ValidarCor(input?.primaryContrast)
              ? input?.primaryContrast
              : SaurusTema.palette.primary.contrastText,
          },
          secondary: {
            main: ValidarCor(input?.secondaryMain)
              ? input?.secondaryMain
              : SaurusTema.palette.secondary.main,
            light: ValidarCor(input?.secondaryLight)
              ? input?.secondaryLight
              : SaurusTema.palette.secondary.light,
            dark: ValidarCor(input?.secondaryDark)
              ? input?.secondaryDark
              : SaurusTema.palette.secondary.dark,
            contrastText: ValidarCor(input?.secondaryContrast)
              ? input?.secondaryContrast
              : SaurusTema.palette.secondary.contrastText,
          },
          text: {
            primary: ValidarCor(input?.textPrimary)
              ? input?.textPrimary
              : SaurusTema.palette.text.primary,
            secondary: ValidarCor(input?.textSecondary)
              ? input?.textSecondary
              : SaurusTema.palette.text.secondary,
          },
          type: tipo,
          background: {
            default: tipo === 'light' ? '#fafafa' : '#121212',
          },
        },
        overrides: SaurusTema.overrides,
        props: SaurusTema.props,
      } as ThemeOptions);
      setarTema(proximoTema);
    },
    [obterTipo],
  );

  useEffect(() => {
    atualizarTema(saurusTema);
  }, [atualizarTema, saurusTema]);

  return (
    <ThemeProvider theme={tema}>
      <CssBaseline>
        <EmpresaTemaContext.Provider
          value={{
            empresaLocalizada: empLocalizada,
            carregandoEmpresa: carregandoEmpresa,
            atualizarTema,
            atualizarEmpresa,
            empresaId,
            tema: empresa?.style,
            empresa: empresa,
          }}
        >
          {children}
        </EmpresaTemaContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
};

import * as Yup from 'yup';

const NomeValidacao = Yup.string()
    .min(2, 'Você precisa informar ao menos 2 caracteres.')
    .max(250, 'A informações pode ter no máximo 250 caracteres.')
    .required('Campo Obrigatório.');
const InfAdicValidacao = Yup.string()
    .max(250, 'Informação pode ter no máximo 250 caracteres.')
const MedidaValidacao = Yup.string()
    .min(1, 'Você precisa informar ao menos 1 caracter.')
    .max(5, 'Informação pode ter no máximo 5 caracteres.')
    .required('Campo Obrigatório.').test('len', 'Medida inválida.', val => (val||'').trim().length>0);

export const ProdutoValidacao = Yup.object().shape({
    nome: NomeValidacao,
    informacao: InfAdicValidacao,
    medida: MedidaValidacao,
});


import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    padding: '.5rem',
    position: 'relative',
  },
  picker: {
    padding: '0 5px',
  },
  descricao: {
    marginBottom: '10px',
  },
  loading: {
    marginTop: '30px',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  spinner: {
    marginTop: '10px',
  },
  acoes: {
    display: 'flex',
    margin: '10px 0px',
    width: '100%',
    justifyContent: 'flex-end',
  },
  acao: {
    textTransform: 'none',
  },
  marginBottom: { marginBottom: '1rem' },
}));

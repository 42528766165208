import React from 'react';
import { Switch } from 'react-router-dom';
import { CardapioRouter } from './cardapio/router';
import { NovoCadastroPagina } from 'modulos';
import { Route } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';

export const Router = () => {
  return (

    <Switch>
      <Route path="/:empresaId" titulo="" component={CardapioRouter} />
      <Route
        titulo={VariaveisAmbiente.appName + " - Bem Vindo!"}
        exact
        path="/"
        component={NovoCadastroPagina}
      />
    </Switch>
  );
};

import React, { useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, Typography, Grid, Divider } from '@material-ui/core';

import { DadosDelivery, EnumOpcoesDelivery } from 'storage';
import { EmpresaMaster } from 'api/master/models';
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
}));

export interface OpcoesDeliveryProps {
  deliveryDados: DadosDelivery | undefined;
  empresaDados: EmpresaMaster | undefined;
  permiteEntrega?: string;
  permiteRetirada?: string;
  quandoClicar: () => any;
}
export const OpcoesDelivery = ({
  deliveryDados,
  empresaDados,
  permiteEntrega,
  permiteRetirada,
  quandoClicar,
}: OpcoesDeliveryProps) => {
  const classes = useStyles();

  const capitalize = useCallback((value: any) => {
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);
  const enderecoFormatado = useCallback(
    (lgr, nro, bairro, municipio, uf) => {
      return `${capitalize(lgr.toLowerCase())}, 
    ${capitalize(nro.toLowerCase())} - 
    ${capitalize(bairro.toLowerCase())}, 
    ${capitalize(municipio.toLowerCase())}/${uf}`;
    },
    [capitalize],
  );

  if (
    (deliveryDados?.tipo === EnumOpcoesDelivery.Entrega && permiteEntrega === 'false') ||
    (deliveryDados?.tipo === EnumOpcoesDelivery.Retirada && permiteRetirada === 'false')
  ) {
    return <></>;
  }
  return (
    <>
      {deliveryDados && (permiteEntrega === 'true' || permiteRetirada === 'true') && (
        <>
          <Divider style={{ marginBottom: '1rem' }} />
          <Grid onClick={quandoClicar} className={classes.root}>
            <Grid container justify="space-between">
              <Typography variant="h5" color="primary">
                {deliveryDados?.tipo === EnumOpcoesDelivery.Entrega
                  ? 'Entregar Em: '
                  : 'Retirar Em: '}
              </Typography>
              <EditIcon color="primary" />
            </Grid>

            <Typography variant="body1" color="primary">
              {deliveryDados?.tipo === EnumOpcoesDelivery.Entrega
                ? enderecoFormatado(
                    deliveryDados?.lgr || '',
                    deliveryDados?.nro || '',
                    deliveryDados?.bairro || '',
                    deliveryDados?.municipio || '',
                    deliveryDados?.uf || '',
                  )
                : enderecoFormatado(
                    empresaDados?.lgr || '',
                    empresaDados?.nro || '',
                    empresaDados?.bairro || '',
                    empresaDados?.municipio || '',
                    empresaDados?.uf || '',
                  )}
            </Typography>

            {deliveryDados?.tipo === EnumOpcoesDelivery.Retirada && (
              <Typography variant="body1" color="primary">
                <strong>Pessoa a retirar:</strong> {deliveryDados?.nome}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

import React, { ChangeEvent, useCallback, useEffect, memo, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, IconButton, Fade, InputBase, InputAdornment } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

export interface BarraPesquisaBarProps {
  pesquisa: boolean;
  termoPesquisa?: string;
  modo?: 'static' | 'dinamic';
  quandoPesquisar?: (event: ChangeEvent<HTMLInputElement>) => any;
  onPesquisaClick?: () => any;
  limparPesquisa?: () => any;
}

const useStyles = makeStyles((theme) => ({
  searchBarContainer: ({ modo }: any) => ({
    position: 'relative',
    width: modo === 'static' ? '90%' : '100%',
  }),
  searchBar: {
    display: 'flex',
    background: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)',
  },
  inputRoot: {
    color: 'inherit',
    padding: '0 15px',
    '& input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  },
  input: ({ modo }: any) => ({
    padding: modo === 'static' ? '10px' : theme.spacing(3, 1, 3, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  }),
  searchIcon: {
    color: theme.palette.text.primary,
  },
  cancelSearchIcon: ({ modo }: any) => ({
    color: theme.palette.text.primary,
    position: 'absolute',
    right: 12,
    top: modo === 'static' ? -2 : 12,
    margin: 'auto',
  }),
}));
export const BarraPesquisaComp = ({
  pesquisa,
  modo,
  termoPesquisa,
  onPesquisaClick,
  quandoPesquisar,
  limparPesquisa,
}: BarraPesquisaBarProps) => {
  const classes = useStyles({ modo });
  const cancelSearch = useCallback(() => {
    if (limparPesquisa) limparPesquisa();
    if (onPesquisaClick && modo !== 'static') onPesquisaClick();
  }, [limparPesquisa, onPesquisaClick, modo]);
  const inputNumeroRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (pesquisa) {
      inputNumeroRef?.current?.focus();
    }
  }, [pesquisa]);
  return (
    <>
      {pesquisa && (
        <Fade in={pesquisa} timeout={200} style={{ transitionDelay: pesquisa ? '0.3s' : 'unset' }}>
          <div className={classes.searchBarContainer}>
            <InputBase
              inputRef={inputNumeroRef}
              className={classes.searchBar}
              value={termoPesquisa}
              onChange={quandoPesquisar}
              startAdornment={
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              }
              classes={{
                input: classes.input,
                root: classes.inputRoot,
              }}
              placeholder="Buscar Produto"
            />
            {pesquisa && (
              <IconButton
                onClick={cancelSearch}
                aria-label="pesquisar"
                aria-haspopup="true"
                className={classes.cancelSearchIcon}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </Fade>
      )}
    </>
  );
};
export const BarraPesquisa = memo(BarraPesquisaComp);

import { flatten, isEmpty } from 'lodash';
import { useState, useEffect, createRef, useCallback } from 'react';
import { SessoesProps } from './sessoes-props';
import { search } from 'ss-search';
import { CardapioProduto } from 'api/cardapio/models';

type SessoesRef = { [key: string]: React.MutableRefObject<HTMLDivElement | null> };

export const useSessoes = ({ sessoes, carregando }: SessoesProps) => {
  const [sessoesRef, definirReferencias] = useState<SessoesRef>({});

  useEffect(() => {
    const resultado = (Array.isArray(sessoes) ? sessoes : [])?.reduce<SessoesRef>(
      (resultado, sessao) => ({
        ...resultado,
        [sessao?.categoriaId || '']: createRef<HTMLDivElement | null>(),
      }),
      {},
    );

    if (resultado) {
      definirReferencias(resultado);
    }
  }, [sessoes]);

  const rolarParaSessao = useCallback(
    (sessaoId: string) => {
      const ref = isEmpty(sessoesRef) ? undefined : sessoesRef[sessaoId];

      if (Boolean(ref?.current) === false) {
        return;
      }

      const offset = -110;
      const sessaoTop = ref?.current?.getBoundingClientRect().top || 0;
      const top = sessaoTop + window.pageYOffset + offset;

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    },
    [sessoesRef],
  );

  const pesquisarSessoes = useCallback(
    (palavraChave?: string) => {
      const sessoesFormatadas = flatten(
        sessoes?.reduce<any[]>((resultado, valorAtual) => {
          const { produtos, categoria } = valorAtual;

          const produtosFormatados = produtos.map((produto) => ({
            ...produto,
            categoria,
          }));

          return resultado.concat(...produtosFormatados);
        }, []),
      );

      const pesquisarResultado = palavraChave
        ? search(
            sessoesFormatadas,
            ['categoria', 'nome', 'descricao', 'medida', 'valor'],
            palavraChave,
          )
        : [];

      return pesquisarResultado.reduce((resultado, valorAtual) => {
        const produto = resultado.find((valor: any) => valor.id === valorAtual.id);

        if (produto) {
          return resultado;
        } else {
          return [...resultado, valorAtual];
        }
      }, [] as CardapioProduto[]);
    },
    [sessoes],
  );

  return {
    dados: {
      sessoesRef,
    },
    operacoes: {
      rolarParaSessao,
      pesquisarSessoes,
    },
  };
};

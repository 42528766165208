import React from 'react';
import { ReactQueryErrorResetBoundary } from 'react-query';
import { ErroGenerico } from 'modulos/outros/erro-generico';
interface CapturarErrosComponentProps {
  reset?: () => void;
  detalhe: string;
  titulo: string;
}

const WithReactQueryReset = (Component: any) => (props: any) => {
  return (
    <ReactQueryErrorResetBoundary>
      {({ reset }) => <Component reset={reset} {...props} />}
    </ReactQueryErrorResetBoundary>
  );
};

class CapturarErrosComponent extends React.Component<CapturarErrosComponentProps> {
  state: { erro: any } = {
    erro: null,
  };

  titulo: string;
  detalhe: string;

  constructor(props: CapturarErrosComponentProps) {
    super(props);
    this.detalhe = props.detalhe;
    this.titulo = props.titulo;
    this.retry = this.retry.bind(this);
    this.retryNotificationError = this.retryNotificationError.bind(this);
  }

  componentDidCatch(error: any) {
    this.setState({
      erro: error,
    });
  }

  retry() {
    const { reset } = this.props;

    if (reset) {
      reset();
    }

    this.setState({
      erro: null,
    });
  }

  retryNotificationError() {
    this.setState({
      erro: null,
    });
  }

  render() {
    const { erro } = this.state;

    if (Boolean(erro)) {
      return <ErroGenerico
        onRetry={this.state.erro.permitirNovamente === false ? undefined : this.retry}
        titulo={this.state.erro.titulo || 'Erro não Esperado'} mensagem={this.state.erro.mensagem || this.state.erro.message} detalhe={this.state.erro?.detalhe} />;
    }

    return this.props.children;
  }
}

export const CapturarErros = WithReactQueryReset(CapturarErrosComponent);

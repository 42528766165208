import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { CardapioEmpresa } from 'api/cardapio/models';
import { useQuery, QueryConfig } from 'react-query';

class ObterDadosEmpresa implements ApiQuery<string, CardapioEmpresa> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(empresaId: string): any[] {
    return ['ObterDadosEmpresa', empresaId];
  }

  execute(_: any, empresaId: string) {
    return FetchCardapioApiAsPublicUserJSON<CardapioEmpresa>(
      empresaId,
      `/${empresaId}/configuracao/cardapio`,
    );
  }
}

export const obterDadosEmpresa = new ObterDadosEmpresa();

export const useObterDadosEmpresa = (props: string, config: QueryConfig<CardapioEmpresa>) => {
  return useQuery(obterDadosEmpresa.getKey(props), obterDadosEmpresa.execute, config);
};

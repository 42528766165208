import { FetchPedidosApiWithUserJSON } from 'api/pedidos/utils';
import { ApiLoja } from 'api/pedidos/models/api-loja';
import { UsuarioMaster } from 'componentes';

class SalvarDadosLoja {
    constructor() {
        this.execute = this.execute.bind(this);
    }

    async execute(loja: ApiLoja, documento: string) {
        var token = UsuarioMaster.fromLocalStorageToken()?.token || '';
        return await FetchPedidosApiWithUserJSON<any>(token, `/v1/loja`, {
            method: 'POST',
            body: JSON.stringify(loja),
            headers: {
                documento: documento
            }
        });
    }
}

export const salvarDadosLojaPost = new SalvarDadosLoja();

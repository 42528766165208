import {
  useObterDadosEmpresa,
  useObterCategorias,
  SessaoProduto,
  useObterSessoesProdutos,
} from 'api/cardapio/queries';
import {
  useAtualizarDadosEmpresa,
  useDesativarCategoria,
  useAtualizarCategoria,
  useDesativarProduto,
  useAtualizarProduto,
} from 'api/cardapio/mutations';
import { useCallback } from 'react';
import { CardapioCategoria, CardapioProduto } from 'api/cardapio/models';

export const useCardapioApi = (empresaId: string) => {
  const { refetch: buscarEmpresa, ...empresa } = useObterDadosEmpresa(empresaId, {
    enabled: empresaId,
  });

  const { refetch: buscarSessoes, ...sessoes } = useObterSessoesProdutos(empresaId, {
    enabled: empresaId,
  });

  const { refetch: buscarCategorias, ...categorias } = useObterCategorias(empresaId, {
    enabled: empresaId,
  });

  const [desativarCategoria, desativarCategoriaDados] = useDesativarCategoria();
  const [atualizarEmpresa, atualizarEmpresaDados] = useAtualizarDadosEmpresa();
  const [atualizarCategoria, atualizarCategoriaDados] = useAtualizarCategoria();
  const [desativarProduto, desativarProdutoDados] = useDesativarProduto();
  const [atualizarProduto, atualizarProdutoDados] = useAtualizarProduto();

  const deletarCategoriaWrap = useCallback(
    (categoria?: CardapioCategoria) => {
      return desativarCategoria({
        categoriaId: categoria?.id || '',
        empresaId,
      });
    },
    [desativarCategoria, empresaId],
  );

  const reativarCategoriaWrap = useCallback(
    (categoria?: CardapioCategoria) => {
      if (categoria != null) {
        return atualizarCategoria({
          id: categoria.id || '',
          empresaId: empresaId,
          imagemUrl: categoria.imagemUrl,
          descricao: categoria.descricao,
          paiId: categoria.paiId,
          cor: categoria.cor,
          rank: categoria.rank,
          ativo: true,
        });
      }
    },
    [atualizarCategoria, empresaId],
  );

  const desativarCategoriaWrap = useCallback(
    (categoria?: CardapioCategoria) => {
      if (categoria != null) {
        return atualizarCategoria({
          id: categoria.id || '',
          empresaId: empresaId,
          imagemUrl: categoria.imagemUrl,
          descricao: categoria.descricao,
          paiId: categoria.paiId,
          cor: categoria.cor,
          rank: categoria.rank,
          ativo: false,
        });
      }
    },
    [atualizarCategoria, empresaId],
  );

  const desativarProdutoWrap = useCallback(
    (produto?: CardapioProduto) => {
      return desativarProduto({
        empresaId,
        produtoId: produto?.id || '',
      });
    },
    [desativarProduto, empresaId],
  );

  const rebuscarSessoes = useCallback( () => {
    buscarSessoes();
  },[buscarSessoes]);
  return {
    dados: {
      empresa,
      categorias: {
        ...categorias,
        data: Array.isArray(categorias.data) ? categorias.data : ([] as CardapioCategoria[]),
      },
      sessoes: {
        ...sessoes,
        data: Array.isArray(sessoes.data) ? sessoes.data : ([] as SessaoProduto[]),
      },
    },
    operacoes: {
      atualizarEmpresa: {
        atualizarEmpresa,
        ...atualizarEmpresaDados,
      },
      desativarCategoria: {
        executar: desativarCategoriaWrap,
        ...desativarCategoriaDados,
      },
      reativarCategoria: {
        executar: reativarCategoriaWrap,
        ...atualizarEmpresaDados,
      },
      deletarCategoria: {
        executar: deletarCategoriaWrap,
        ...atualizarEmpresaDados,
      },
      atualizarCategoria: {
        executar: atualizarCategoria,
        ...atualizarCategoriaDados,
      },
      desativarProduto: {
        executar: desativarProdutoWrap,
        ...desativarProdutoDados,
      },
      atualizarProduto: {
        executar: atualizarProduto,
        ...atualizarProdutoDados,
      },
      buscarSessoes:{
        executar:rebuscarSessoes,
      }
    },
  };
};

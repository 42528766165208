import React, { FC, ReactElement } from 'react';
import { Typography, Grid, Fade } from '@material-ui/core';
import { CarregandoProps } from './carregando-props';
import { useStyles } from './carregando-styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { DocumentTitle } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';
import { MasterPagina } from 'modulos';
import gif from 'arquivos-estaticos/global/imagens/loading.gif';

export const Carregando: FC<CarregandoProps> = ({
  tipo = 'spinner',
  skeletonVariant,
  width,
  height,
  carregando,
  mensagem,
  titulo,
  className,
  modelo,
}) => {
  const classes = useStyles();
  const tituloDocument: ReactElement = (
    <DocumentTitle titulo={VariaveisAmbiente.appName + ' - Carregando...'}></DocumentTitle>
  );

  if (tipo === 'skeleton') {
    return (
      <React.Fragment>
        {carregando && tituloDocument}
        <Fade in={carregando} mountOnEnter unmountOnExit>
          <Skeleton
            variant={skeletonVariant || 'rect'}
            height={height}
            width={width}
            className={className}
          />
        </Fade>
      </React.Fragment>
    );
  }

  const conteudoCarregando = (
    <Fade in={carregando} mountOnEnter unmountOnExit>
      <Grid
        className={
          modelo === 'fixed'
            ? classNames(classes.containerTelaCheia, className)
            : modelo === 'absolute'
            ? classNames(classes.containerTelaCheiaModal, className)
            : className
        }
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={0}
      >
        {/* <CircularProgress size={65} className={classes.spinner} disableShrink={true} /> */}
        <img
          className={classNames(classes.spinner, classes.loadingGif)}
          src={gif}
          alt="carregando gif"
        ></img>

        {titulo && (
          <Typography variant="h4" align="center">
            <strong>{titulo}</strong>
          </Typography>
        )}
        {mensagem && (
          <Typography align="center" variant="body1">
            {mensagem}
          </Typography>
        )}
      </Grid>
    </Fade>
  );

  return (
    <React.Fragment>
      {carregando && tituloDocument}
      {carregando && modelo === 'fixed' && <MasterPagina>{conteudoCarregando}</MasterPagina>}
      {carregando && modelo !== 'fixed' && <React.Fragment>{conteudoCarregando}</React.Fragment>}
    </React.Fragment>
  );
};

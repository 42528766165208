import React, { useCallback, memo } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import EditIcon from '@material-ui/icons/Edit';
import CropFreeIcon from '@material-ui/icons/CropFree';

import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SettingsIcon from '@material-ui/icons/Settings';

export interface MeuMenuProps {
  wrapNavEdicao: () => any;
  setCarregando: (carregando: boolean) => any;
  onVisualizarClick: () => any;
  onEditarConfiguracoesClick: () => any;
  gerarQRCode: () => any;
  somenteLeitura: boolean;
  contextoSelecionado?: string;
}

const useStyles = makeStyles((theme) => ({
  btnMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 500,
    marginLeft: '.5em',
    color: theme.palette.text.primary,
    padding: '5px',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.4),

      borderRadius: '3px',
    },
  },
  pointer: { cursor: 'pointer' },
  btnBar: { color: '#FFFFFF', fontSize: '1.5rem', fontWeight: 500 },
  meuMenu: {
    display: 'flex',
    marginRight: '1rem',

    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
    },
  },
  active: {
    backgroundColor: fade(theme.palette.primary.main, 0.4),

    borderRadius: '3px',
  },
}));
export const MeuMenuComp = ({
  wrapNavEdicao,
  setCarregando,
  onVisualizarClick,
  onEditarConfiguracoesClick,
  gerarQRCode,
  somenteLeitura,
  contextoSelecionado,
}: MeuMenuProps) => {
  const classes = useStyles();

  const handleCloseWrapper = useCallback((funcao, carregando?: boolean) => {
    setCarregando(carregando || false);
    carregando ? setTimeout(() => funcao(), 1) : funcao();
  }, [setCarregando]);

  return (
    <div className={classes.meuMenu}>
      <Typography
        color="textPrimary"
        className={classNames(
          classes.btnMenu,
          contextoSelecionado === 'visualizacao' && classes.active,
        )}
        onClick={() => handleCloseWrapper(onVisualizarClick, !somenteLeitura)}
      >
        <OpenInBrowserIcon color="inherit" style={{ marginRight: 10 }} />
        Modo Visualização
      </Typography>
      <Typography
        onClick={() => handleCloseWrapper(wrapNavEdicao, somenteLeitura)}
        className={classNames(classes.btnMenu, contextoSelecionado === 'edicao' && classes.active)}
      >
        <EditIcon color="inherit" style={{ marginRight: 10 }} />
        Modo Edição
      </Typography>

      <Typography
        color="textPrimary"
        className={classes.btnMenu}
        onClick={() => handleCloseWrapper(gerarQRCode, false)}
      >
        <CropFreeIcon color="inherit" style={{ marginRight: 10 }} />
        Gerar Código QR
      </Typography>
      <Typography
        color="textPrimary"
        className={classNames(
          classes.btnMenu,
          contextoSelecionado === 'configuracoes' && classes.active,
        )}
        onClick={() => handleCloseWrapper(onEditarConfiguracoesClick, false)}
      >
        <SettingsIcon color="inherit" style={{ marginRight: 10 }} />
        Configurações
      </Typography>
    </div>
  );
};
export const MeuMenu = memo(MeuMenuComp);

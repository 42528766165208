import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { useQuery, QueryConfig } from 'react-query';

export interface ObterMedidaProdutoInput {
  medida?: string;
  empresaId: string;
}

class ObterMedidaProduto implements ApiQuery<ObterMedidaProdutoInput, string[]> {
  getKey(params: ObterMedidaProdutoInput): any[] {
    return ['ObterMedidaProduto', params];
  }

  execute(_: any, { empresaId, medida }: ObterMedidaProdutoInput): Promise<string[]> {
    return FetchCardapioApiAsMasterUserJSON<string[]>(
      `/${empresaId}/produto-variacao/medida?descricao=${encodeURIComponent(medida || '')}`,
    );
  }
}

export const obterMedidaProduto = new ObterMedidaProduto();

export const useObterMedidaProduto = (
  input: ObterMedidaProdutoInput,
  config?: QueryConfig<string[], ObterMedidaProdutoInput>,
) => {
  return useQuery(obterMedidaProduto.getKey(input), obterMedidaProduto.execute, config);
};

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { Titulo, Fab } from 'componentes';
import { CategoriasCarroselProps } from './categorias-carrosel-props';
import { CategoriaCarroselInstancia } from './categorias-carrosel-instancia';
import { useStyles } from './categorias-carrosel-styles';
import { CategoriaCarroselConfig } from './categorias-carrosel-config';
import { useCategoriasCarrosel } from './use-categorias-carrosel';
import { Categoria } from 'modulos/cardapio/componentes/categorias/categoria';
import { EditarCategoriaModal } from 'modulos/cardapio/componentes/categorias/editar-categoria-modal';
import Slider from 'react-slick';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export const CategoriasCarrosel = React.forwardRef<
  CategoriaCarroselInstancia,
  CategoriasCarroselProps
>((props, ref) => {
  const classes = useStyles();
  const sliderContainer = React.useRef<HTMLDivElement>(null);
  const slider = React.useRef<Slider>(null);

  const {
    className,
    carregando = false,
    somenteLeitura,
    empresaId,
    deletarCategoria,
    desativarCategoria,
    reativarCategoria,
    salvarCategoria,
    atualizandoCategoria,
  } = props;
  const { dados, operacoes } = useCategoriasCarrosel(props);
  React.useImperativeHandle(ref, () => ({
    editarCategoria: editarWrapper,
  }));

  const editarWrapper = (categoriaId: string) => {
    if (dados.categorias) {
      let categoria = dados.categorias.find((x) => x.id === categoriaId);
      operacoes.editar(categoria);
    }
  };

  if (carregando === false && dados.existeCategorias === false && somenteLeitura) {
    return null;
  }

  const categorias = Array.isArray(dados.categorias)
    ? somenteLeitura
      ? dados.categorias.filter((x) => x.ativo === true)
      : dados.categorias
    : [];

  const catOrdenadas = (categorias || []).sort(function (a, b) {
    if ((a.rank || 0) < (b.rank || 0)) return -1;
    if ((a.rank || 0) > (b.rank || 0)) return 1;
    if ((a.descricao || '').toLowerCase() < (b.descricao || '').toLowerCase()) return -1;
    if ((a.descricao || '').toLowerCase() > (b.descricao || '').toLowerCase()) return 1;
    return 0;
  });

  return (
    <div className={className}>
      <div className={classes.header}>
        <Titulo>Categorias</Titulo>
        {somenteLeitura === false && (
          <Fab
            onClick={operacoes.abrirCadastroCategoria}
            color="primary"
            size="small"
            tooltip="Adicionar Categoria"
          >
            <AddIcon />
          </Fab>
        )}
      </div>
   
      <div ref={sliderContainer} className={classes.root}>
        {carregando === false && dados.existeCategorias ? (
          <Fab
            onClick={slider.current?.slickPrev}
            color="default"
            size="small"
            className={classes.btnVoltar}
          >
            <ArrowBackIosRoundedIcon />
          </Fab>
        ) : null}
        <div className={classes.slider}>
          <Slider ref={slider} {...CategoriaCarroselConfig}>
            {(carregando ? Array.from(new Array(20)) : catOrdenadas)?.map((categoria, index) => (
              <div key={categoria?.id || index} className={classes.slide}>
                <Categoria
                  carregando={carregando}
                  categoria={categoria}
                  somenteLeitura={somenteLeitura}
                  onClick={operacoes.selecionarCategoria}
                  editar={operacoes.editar}
                  desativar={desativarCategoria}
                  reativar={reativarCategoria}
                  deletar={deletarCategoria}
                />
              </div>
            ))}
          </Slider>
        </div>
        {carregando === false && dados.existeCategorias ? (
          <Fab
            onClick={slider.current?.slickNext}
            color="default"
            size="small"
            className={classes.btnAvancar}
          >
            <ArrowForwardIosRoundedIcon />
          </Fab>
        ) : null}
      </div>

      {somenteLeitura === false && (
        <EditarCategoriaModal
          aberto={dados.editarCategoria as boolean}
          categoriaId={dados.editarCategoria as string}
          quandoForFechado={operacoes.fecharEdicaoCategoria}
          empresaId={empresaId}
          deletarCategoria={deletarCategoria}
          atualizandoCategoria={atualizandoCategoria}
          salvarCategoria={salvarCategoria}
        />
      )}
    </div>
  );
});

import { VariaveisAmbiente } from 'ambiente';
import { isEmpty } from 'lodash';
import { timeStorage } from 'utils';

const TokenIntegradorKey = 'tokenMasterIntegrador';
let ObtendoToken = false;

const FetchApiMasterToken = async(empresaId:string): Promise<any> =>{
  const newToken = await fetch(
    `${VariaveisAmbiente.masterApi}/token/integradores/${btoa('s@urusIntegradores')}${(isEmpty(empresaId) ? '' : '?idEmpresa=' + empresaId)}`,
  )
    .then((response) => {
      if (response.ok)
        return response.json();
      if (isEmpty(empresaId))
        return null as any;
      return FetchApiMasterToken('');
    })
    .catch((e) => null);
    return newToken;
}

const FetchTokenIntegrador = async (empresaId: string): Promise<string> => {
  const newToken = await FetchApiMasterToken(empresaId) as any;

  if (!newToken) {
    throw new Error('Não foi possível realizar a autenticação!');
  }

  const expiration = 1 * 24 * 60; // 1 Dia

  timeStorage.save(TokenIntegradorKey, { empresaId: empresaId, token: newToken?.token }, expiration);

  return newToken.token as string;
};

export const ObterTokenIntegrador = (empresaId: string): Promise<string> => {
  const token: string = timeStorage.load(TokenIntegradorKey);
  if (token) {
    if ((token as any).empresaId === empresaId)
      return Promise.resolve((token as any).token);
    else
      timeStorage.remove(TokenIntegradorKey);
  }

  if (ObtendoToken === true) {
    return new Promise<string>((resolve, reject) =>
      setTimeout(() => {
        ObterTokenIntegrador(empresaId).then(resolve).catch(reject);
      }, 500),
    );
  }

  ObtendoToken = true;
  return FetchTokenIntegrador(empresaId).finally(() => {
    ObtendoToken = false;
  });
};
export const RemoverToken=()=>{
  timeStorage.remove(TokenIntegradorKey);
}
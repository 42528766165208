import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: ({ somenteLeitura }: any) => (somenteLeitura === false ? {} : {}),
  card: {
    boxShadow: `${theme.shadows[0]}`,
    borderRadius: '2px',
    border: `1px solid ${fade(theme.palette.divider, 0.1)}`,
    position: 'relative',
  },
  conteudo: {
    display: 'flex !important',
    overflow: 'hidden',
    minHeight: '150px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '110px',
    },
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    padding: '15px',
    justifyContent: 'space-between',
    minHeight: '150px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '160px',
    },
  },
  text: {
    textOverflow: 'ellipsis',
    lineHeight: 1.3,
    overflow: 'hidden',
    display: '-webkit-box',
    boxOrient: 'vertical',
  },
  nome: {
    lineClamp: 2,
  },
  descricao: {
    lineClamp: 5,
  },
  valor: {
    color: theme.palette.primary.main,
  },
  img: {
    width: '140px',
    height: '140px',
    borderRadius: 5,
    flex: '1 0 auto',
    margin: '10px',
    objectFit: 'cover',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '110px',
      height: '110px',
    },
  },
  deleteButton: {
    padding: '9px',
  },
  deleteIcon: {
    marginRight: '5px',
  },
  medida: {
    fontSize: '1rem',
  },
}));

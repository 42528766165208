import React, { useCallback, useState } from 'react';
import { InputComMascara } from 'componentes/inputs/input-com-mascara';
import { InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useConsultaCep } from 'webservice/consulta-cep';
import { BuscarCepResultado } from 'webservice/consulta-cep/models';
import { BuscarCepInputProps } from './cep-input-props'
import { stringNumeros } from 'utils/string-numeros';

export function CepInput(props: BuscarCepInputProps) {
  const { onChange, value: propValue, quandoEncontrarUmCepExecutar, ...outros } = props || {};

  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);
  const { carregando, consultarCep } = useConsultaCep();
  const [value, setarValue] = useState<any>(propValue);

  return (
    <InputComMascara
      {...outros}
      guiarUsuario={false}
      naoMostrarMascaraComoCaracter={true}
      mascara="99999-999"
      value={value}
      onChange={(event) => {
        setarValue(event.target.value);

        if (onChange) {
          onChange(event);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={async (event) => {
                const result = await consultarCep(stringNumeros(value));
                if (result?.sucesso && quandoEncontrarUmCepExecutar) {
                  quandoEncontrarUmCepExecutar(result?.dados as BuscarCepResultado);
                }
              }}
              onMouseDown={handleMouseDownPassword}
              disabled={carregando}
              edge="end"
            >
              {carregando ? <CircularProgress size={25} /> : <SearchRoundedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Carregando, useNotificacoes } from 'componentes';

import { makeStyles, Typography, Button, TextField, Divider } from '@material-ui/core';
import { useObterProduto } from 'api/cardapio/queries';
import { MergedCardapioMaster } from 'api/merged';
import { ItemSacola, useAtualizarSacola } from 'storage';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: { justifyContent: 'center', flexWrap: 'wrap' },
  },

  imgContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    maxWidth: '400px',
    maxHeight: '400px',
    minWidth: '400px',
    minHeight: '400px',
    marginBottom: '1rem',
    marginRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      maxHeight: '250px',
      minWidth: '250px',
      minHeight: '250px',
      marginRight: 'unset',
    },
  },
  container: {},
  info: {
    marginRight: 'unset',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      marginRight: 'auto',
    },
  },
  footerContainer: {
    marginTop: 'auto',
  },

  btnConfirmar: {
    color: 'white',
    [theme.breakpoints.down('sm')]: { flex: '1 1 60%', margin: '2%' },

    marginLeft: '.8rem',
  },
  iconBasket: {
    marginRight: '.5rem',
  },
  seletor: {
    width: '100px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    border: '1px solid #9A9A9A',
    alignItems: 'center',
    padding: '3px',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: { flex: '1 1 30%', margin: '2%' },
  },
  selectorValue: {
    textAlign: 'center',
  },
  adicionar: {
    color: theme.palette.secondary.main,
  },
  remover: { color: theme.palette.secondary.main },
  btn: {
    cursor: 'pointer',
  },

  btnsConfirmar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' },
  },
  observacao: {
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
}));

export interface VisualizarProdutoModalProps {
  aberto: boolean;
  produtoId: string | undefined;
  empresaId: string;
  empresaDados: MergedCardapioMaster | undefined;
  quandoForFechado: any;
  itemSacola?: ItemSacola;
}

export const VisualizarProdutoModal = ({
  aberto,
  produtoId,
  empresaId,
  empresaDados,
  quandoForFechado,
  itemSacola,
}: VisualizarProdutoModalProps) => {
  const classes = useStyles();

  const { showErrorMessage } = useNotificacoes();
  const { data: produto, isLoading: carregandoProduto } = useObterProduto(
    {
      empresaId,
      produtoId: produtoId || '',
    },
    {
      enabled: Boolean(empresaId) && Boolean(produtoId) && Boolean(aberto),
      useErrorBoundary: false,
      onError: (error: any) => {
        if (quandoForFechado) quandoForFechado();

        showErrorMessage(`Erro ao Localizar o Produto '${produtoId}'. Detalhe: ${error.message}`);
      },
    },
  );
  const [atualizarSacola] = useAtualizarSacola();
  const [carregando, setCarregando] = useState(false);
  const [quantidade, setQuantidade] = useState(1);
  const [observacao, setObservacao] = useState('');

  //controle do carregando
  useEffect(() => {
    let isCarregando = carregandoProduto;
    setCarregando(isCarregando);

    if (itemSacola) {
      setQuantidade(itemSacola.quantidade);
      setObservacao(itemSacola.observacao);
    }
  }, [aberto, carregandoProduto, itemSacola]);

  const valoresIniciais = useCallback(() => {
    setQuantidade(1);
    setObservacao('');
  }, []);
  //quando fechar
  const fechar = useCallback(() => {
    valoresIniciais();
    quandoForFechado();
  }, [quandoForFechado, valoresIniciais]);

  const adicionarSacola = useCallback(() => {
    if (itemSacola) {
      itemSacola.quantidade = quantidade;
      itemSacola.observacao = observacao;

      atualizarSacola({ idEmpresa: empresaId, item: itemSacola, editando: true }, {});
    } else {
      atualizarSacola(
        {
          idEmpresa: empresaId,
          item: {
            ...produto,
            quantidade: quantidade,
            observacao: observacao,
            valorTotal: (produto!.valor * quantidade),
            valorAdicional: 0,
            valorDesconto: 0
          } as ItemSacola,
          editando: false,
        },
        {},
      );
    }
    valoresIniciais();

    quandoForFechado();
  }, [
    atualizarSacola,
    empresaId,
    itemSacola,
    observacao,
    quandoForFechado,
    produto,
    quantidade,
    valoresIniciais,
  ]);

  const handleChangeQuantidade = useCallback(
    (operacao) => {
      switch (operacao) {
        case '+':
          let qtd = quantidade;
          qtd++;
          setQuantidade(qtd);
          break;

        case '-':
          let _qtd = quantidade;
          _qtd--;
          _qtd = _qtd < 1 ? 1 : _qtd;
          setQuantidade(_qtd);
          break;
      }
    },
    [quantidade, setQuantidade],
  );
  const handleChangeObservacao = useCallback((event) => {
    setObservacao(event.target.value);
  }, []);

  const total = useCallback(() => {
    return ((produto?.valor || itemSacola?.valor || 0) * quantidade).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }, [itemSacola?.valor, produto?.valor, quantidade]);
  return (
    <>
      <Modal
        tamanho="md"
        aberto={aberto}
        titulo={produto?.nome || itemSacola?.nome || ''}
        quandoForFechado={fechar}
        zIndex="9999"
      >
        <div className={classes.container}>
          <div className={classes.content}>
            {(produto?.imagemUrl || itemSacola?.imagemUrl || '').length > 0 ? (
              <div
                className={classes.imgContainer}
                style={{
                  backgroundImage: `url(${produto?.imagemUrl || itemSacola?.imagemUrl || ''})`,
                }}
              ></div>
            ) : null}

            <div className={classes.info}>
              <Typography align="justify" gutterBottom>
                {produto?.descricao || itemSacola?.descricao || ''}
              </Typography>
              <Typography
                variant="h5"
                color="secondary"
                style={{ fontWeight: 450, marginTop: '1rem' }}
                gutterBottom
              >
                <div>
                  {(produto?.valor || itemSacola?.valor || 0).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}

                  <span style={{ fontSize: '.9rem', fontWeight: 700 }}> {produto?.medida}</span>
                </div>
              </Typography>
              {(empresaDados?.empresaMaster?.telefone || '').length > 0 ? (
                <>
                  <TextField
                    className={classes.observacao}
                    InputLabelProps={{ shrink: true }}
                    label="Alguma Observação?"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleChangeObservacao}
                    value={observacao}
                    placeholder="molho extra, sem cebola, etc."
                    variant="outlined"
                  />

                  <div className={classes.footerContainer}>
                    <Divider style={{ margin: '.5rem 0rem' }} />

                    <div className={classes.btnsConfirmar}>
                      <div className={classes.seletor}>
                        <RemoveIcon
                          onClick={() => handleChangeQuantidade('-')}
                          className={classNames(classes.remover, classes.btn)}
                        />
                        <Typography variant="h6" color="secondary">
                          {quantidade}
                        </Typography>
                        <AddIcon
                          onClick={() => handleChangeQuantidade('+')}
                          className={classNames(classes.adicionar, classes.btn)}
                        />
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.btnConfirmar}
                        onClick={() => adicionarSacola()}
                        style={{ padding: '8px 16px' }}
                      >
                        <span className={classes.iconBasket}>
                          {itemSacola ? 'Atualizar' : 'Adicionar'}
                        </span>
                        {total()}
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <Carregando
          carregando={carregando}
          modelo="absolute"
          titulo="Carregando Produto"
          mensagem="Por favor aguarde enquanto carregamos o produto..."
        ></Carregando>
      </Modal>
    </>
  );
};

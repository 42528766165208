import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
export const DeliveryDadosFormClienteValidation = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório.'),
  documento: Yup.string()
    .optional()
    .test('docvalido', 'Documento inválido', (val) => {
      const valid = validateCPF(val || '');
      return valid || (val || '').replace(/\D/gim, '').trim().length === 0;
    }),

  telefone: Yup.string()
    .optional()
    .test('len', 'Telefone inválido.', (val) => {
      const val_length_without_dashes = (val || '').replace(/\D/gim, '').trim();
      return val_length_without_dashes.length >= 10 || val_length_without_dashes.length === 0;
    }),
});

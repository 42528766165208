import React from 'react';
import { makeStyles, Typography, Paper, InputBase, Fade } from '@material-ui/core';
import { BotaoTransparente } from 'componentes';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import classNames from 'classnames';

export interface CardapioHeaderTextProps {
  text?: string;
  variant?: string;
  somenteLeitura: boolean;
  editando: boolean;
  strong?: boolean;
  tipoEdicao?: string;
  className?: string;
  quandoEdicaoForIniciada?: () => any;
  quandoTextoForAlterado?: (event: React.ChangeEvent<HTMLInputElement>) => any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  typography: ({ strong, variant }: any) => ({
    color: theme.palette.text.primary,
    textShadow: '2px 2px 5px rgba(0,0,0,0.2)',
    marginRight: '10px',
    fontWeight: strong ? 'inherit' : 300,
    fontSize: variant === 'h3' ? '2.375rem' : '1.75rem',
  }),
  paper: {
    margin: 0,
    padding: '2.5px 15px',
    minWidth: '300px',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    display: 'flex',
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    padding:'15px 0px',
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    paddingRight:'10px',
    '& input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 0.8,
    },
  },
  iconButton: {
    padding: '8px',
  },
  icon: {
    fontSize: '1rem',
  },
  marginRight: {
    marginRight: '5px',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const CardapioHeaderText = ({
  text,
  somenteLeitura,
  tipoEdicao,
  className,
  quandoEdicaoForIniciada,
  quandoTextoForAlterado,
  editando,
  variant,
  strong = true,
}: CardapioHeaderTextProps) => {
  const classes = useStyles({ strong, variant: variant || 'h3' });

  const existeTexto = Boolean(text);
  const mostrarEditarCompleto =
    existeTexto === false && editando === false && somenteLeitura === false;
  const mostrarEditar = editando === false && somenteLeitura === false;
  const mostrarTexto = existeTexto && editando === false;

  return (
    <div className={classNames(className, classes.root)}>
      <Fade in={mostrarTexto} mountOnEnter unmountOnExit>
        <div className={classes.textContainer}>
          <Typography
            className={classes.typography}
            align="center"
            variant={(variant as any) || 'h3'}
          >
            {strong ? <strong>{text}</strong> : text}
          </Typography>
          {mostrarEditar && (
            <BotaoTransparente
              tipo="iconButton"
              onClick={quandoEdicaoForIniciada}
              className={classes.iconButton}
            >
              <EditRoundedIcon className={classes.icon} fontSize="small" />
            </BotaoTransparente>
          )}
        </div>
      </Fade>
      {editando && (
        <Paper component="form" variant="outlined" className={classes.paper}>
          <InputBase
            type="text"
            value={text}
            onChange={quandoTextoForAlterado}
            placeholder={tipoEdicao}
            classes={{
              input: classes.input,
              root: classes.inputRoot,
            }}
            onFocus={(e)=>{e.target.select();}}
            autoFocus
          />
          <EditRoundedIcon fontSize="small" />
        </Paper>
      )}
      <Fade in={mostrarEditarCompleto} mountOnEnter unmountOnExit>
        <BotaoTransparente tipo="button" onClick={quandoEdicaoForIniciada} size="small">
          <AddRoundedIcon fontSize="small" className={classes.marginRight} />
          {`Adicionar ${tipoEdicao || ''}`}
        </BotaoTransparente>
      </Fade>
    </div>
  );
};

import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
export const DeliveryDadosFormRetiradaValidation = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório.'),
  documento: Yup.string()
    .required('O documento é obrigatório.')
    .test('docvalido', 'Documento inválido', (val) => {
      const valid = validateCPF(val || '');
      return valid;
    }),
  telefone: Yup.string()
    .required('O telefone é obrigatório.')
    .test('len', 'Telefone inválido.', (val) => {
      const val_length_without_dashes = (val || '').replace(/\D/gim, '').trim();
      return val_length_without_dashes.length >= 10;
    }),
});

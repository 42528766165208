import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {},
  },
  titulo: {
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    lineHeight: '40px',
  },
  destaque: {
    color: theme.palette.primary.main,
  },
  emptyState: {
    margin: '50px 0',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  tituloEdicao: {
    display: 'flex',
  },
  btnsEdicao: {
    marginLeft: '1rem',
  },
}));

import React from 'react';
import { Titulo, Fab, EmptyState } from 'componentes';
import { SessaoProps } from './sessao-props';
import { useStyles } from './sessao-styles';
import { isEmpty } from 'lodash';
import { Grid, Fade } from '@material-ui/core';
import { Produto } from 'modulos/cardapio/componentes/produtos/produto';
import { Skeleton } from '@material-ui/lab';
import InView from 'react-intersection-observer';
import AddIcon from '@material-ui/icons/Add';
import { CardapioProduto } from 'api/cardapio/models';
import classNames from 'classnames';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';


export const Sessao = React.forwardRef<HTMLDivElement, SessaoProps>(
  (
    {
      carregando: carregandoProp,
      sessao,
      destaque,
      somenteLeitura,
      sessaoDeBusca,
      deletar,
      onProdutoClick,
      quandoForVisualizada,
      className,

      adicionarProdutoClick,
      desativarCategoria,
      reativarCategoria,
      editarCategoria,
     
    },
    ref,
  ) => {
    const classes = useStyles();



    const adicionarProdutoClickWrap = React.useCallback(() => {
      if (adicionarProdutoClick) {
        adicionarProdutoClick(sessao);
      }
    }, [adicionarProdutoClick, sessao]);

    const quandoForVisualizadaWrap = React.useCallback(
      (inView?: boolean, entry?: IntersectionObserverEntry) => {
        if (inView === false) {
          return;
        }
        if (quandoForVisualizada) {
          quandoForVisualizada(sessao);
        }
      },
      [quandoForVisualizada, sessao],
    );

    const desativarCategoriaWrap = React.useCallback(() => {
      if (desativarCategoria) {
        desativarCategoria(sessao?.categoriaId || '');
      }
    }, [desativarCategoria, sessao]);
    const reativarCategoriaWrap = React.useCallback(() => {
      if (reativarCategoria) {
        reativarCategoria(sessao?.categoriaId || '');
      }
    }, [reativarCategoria, sessao]);
    const editarCategoriaWrap = React.useCallback(() => {
      if (editarCategoria) {
        editarCategoria(sessao?.categoriaId || '');
      }
    }, [editarCategoria, sessao]);


    const carregando = Boolean(carregandoProp) || Boolean(sessao) === false;
    const existeProdutos = isEmpty(sessao?.produtos) === false;
    const mostrarEmptyState = carregando === false && existeProdutos === false;

    return (
      <div ref={ref} className={classNames(classes.root, className)}>
        <InView threshold={0.4} onChange={quandoForVisualizadaWrap}>
          {carregando && <Skeleton variant="text" height={50} />}
          {carregando === false && (
            <div className={classes.titulo}>
              <div className={classes.tituloEdicao}>
                <Titulo>
                  {sessao?.categoria}
                  {destaque && <span className={classes.destaque}>{destaque}</span>}
                </Titulo>
                <div className={classes.btnsEdicao}>
                  {somenteLeitura === false && sessaoDeBusca === false && (
                    <>
                      <Fab
                        tooltip={`Editar ${sessao?.categoria || 'Categoria'}`}
                        size="small"
                        color="primary"
                        style={{ marginRight: '10px' }}
                        onClick={editarCategoriaWrap}
                      >
                        <EditRoundedIcon />
                      </Fab>
                      {sessao?.ativo === true && (
                        <Fab
                          tooltip={`Desativar ${sessao?.categoria || 'Categoria'}`}
                          size="small"
                          color="primary"
                          carregando={carregando}
                          onClick={desativarCategoriaWrap}
                        >
                          <CloseIcon />
                        </Fab>
                      )}
                      {sessao?.ativo === false && (
                        <Fab
                          tooltip={`Reativar ${sessao?.categoria || 'Categoria'}`}
                          size="small"
                          color="primary"
                          carregando={carregando}
                          onClick={reativarCategoriaWrap}
                        >
                          <ReplayIcon />
                        </Fab>
                      )}
                    </>
                  )}
                </div>
              </div>
              {somenteLeitura === false && sessaoDeBusca === false && (
                <Fab
                  color="primary"
                  size="small"
                  tooltip={`Adicionar Produto em ${sessao?.categoria}`}
                  onClick={adicionarProdutoClickWrap}
                >
                  <AddIcon />
                </Fab>
              )}
            </div>
          )}
          {mostrarEmptyState && (
            <EmptyState
              titulo={`Nenhum produto cadastrado em '${sessao?.categoria}'`}
              mensagem={
                somenteLeitura ? (
                  'Talvez o produto que você esteja procurando esteja em outra sessão.'
                ) : (
                  <div>
                    <strong onClick={adicionarProdutoClickWrap} className={classes.link}>
                      Clique Aqui
                    </strong>{' '}
                    ou no botão acima para incluir um produto nesta categoria
                  </div>
                )
              }
              className={classes.emptyState}
            />
          )}
          {mostrarEmptyState === false && (
            <Grid container spacing={3}>
              {(carregando
                ? Array.from(new Array(6))
                : sessao?.produtos || ([] as CardapioProduto[])
              ).map((produto, index) => (
                <Fade
                  key={produto?.id || index}
                  mountOnEnter
                  unmountOnExit
                  in={true}
                  timeout={500}
                  style={{
                    transitionDelay: `${100 * index}ms`,
                    transformOrigin: '0 0 0',
                  }}
                  appear={true}
                >
                  <Grid item xs={12} md={6}>
                    <Produto
                      produto={produto}
                      somenteLeitura={somenteLeitura}
                      carregando={carregando}
                      deletar={deletar}
                      onClick={onProdutoClick}
                    />
                  </Grid>
                </Fade>
              ))}
            </Grid>
          )}
        </InView>
      </div>
    );
  },
);

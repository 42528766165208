import { useNotificacoes } from 'componentes';
import { MutationConfig, useQueryCache } from 'react-query';
import { ApiMutation } from 'api/interfaces';
import { NotificationError, AuthError } from 'erros';
import { SaurusError } from 'erros/saurus-error';

interface UseMutationConfigProps {
  sucesso: string;
}

export const useMutationConfig = <Output, Input, Mutation extends ApiMutation<any, any>>(
  input: UseMutationConfigProps,
  mutation: Mutation,
) => {
  const cache = useQueryCache();
  const { showMessage, showSuccessMessage } = useNotificacoes();

  return {
    onSuccess: (dados, variaveis) => {
      mutation.updateCache(variaveis, dados, cache);
      showSuccessMessage(input.sucesso);
    },
    onError: (error: any) => {
      if (error instanceof NotificationError) {
        showMessage(error.message, error.tipo);
      } if (error instanceof SaurusError) {
        showMessage(error.message, 'error');
      } else if (error instanceof AuthError) {
        window.location.reload();
      } else {
        showMessage(error.message, 'error');
        console.log(error)
      }
    },
  } as MutationConfig<Output, unknown, Input>;
};

import React, { useRef, useState, useCallback, memo } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';
import { makeStyles, Fab, Tooltip, Typography, TextField, MenuItem } from '@material-ui/core';

import { Modal } from '../modal';

import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignitens: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  offSetImpressao: {
    display: 'flex',
    justifyContent: 'center',
  },
  AreaImpressao: { width: 'fit-content', padding: '1rem' },
  [`@media print`]: {
    AreaImpressao: {
      display: 'flex',
      justifyContent: 'center',
      alignitens: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '3rem',
    },
  },
  borda: {
    border: `1px solid`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  icon: { color: 'white' },
  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  nomeEmpresa: {
    color: theme.palette.text.primary,
  },
  infoEmpresa: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
  opcoes: {
    display: 'flex',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fieldTamanho: {
    marginRight: '.5rem',
  },
}));

export interface QRCodeModalProps {
  aberto: boolean;
  empresaId: string;
  nomeEmpresa?: string;
  infoEmpresa?: string;
  quandoForFechado: any;
}

const QRCodeModalComp = ({
  aberto,
  empresaId,
  quandoForFechado,
  infoEmpresa,
  nomeEmpresa,
}: QRCodeModalProps) => {
  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });
  const componentRef = useRef(null);

  const TAMANHOS = [
    {
      value: 100,
      label: 'Pequeno',
    },
    {
      value: 300,
      label: 'Médio',
    },
    {
      value: 500,
      label: 'Grande',
    },
  ];
  const TITULO = [
    {
      value: 1,
      label: 'Mostrar título',
    },
    {
      value: 0,
      label: 'Não mostrar título',
    },
  ];

  const INFORMACAO = [
    {
      value: 1,
      label: 'Mostrar informação',
    },
    {
      value: 0,
      label: 'Não mostrar informação',
    },
  ];

  const BORDA = [
    {
      value: 0,
      label: 'Não Mostrar borda',
    },
    {
      value: 1,
      label: 'Mostrar borda',
    },
  ];

  const [tamanho, setTamanho] = useState(100);
  const [opcoes, setOpcoes] = useState({
    titulo: 1,
    info: 1,
    borda: 0,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (event.target.name) {
        case 'tamanho':
          setTamanho(parseInt(event.target.value));
          break;
        default:
          setOpcoes({ ...opcoes, [event.target.name]: parseInt(event.target.value) });
          break;
      }
    },
    [opcoes],
  );

  return (
    <Modal tamanho="md" aberto={aberto} titulo="Código QR" quandoForFechado={quandoForFechado}>
      <div>
        <div className={classes.container}>
          <div className={classes.opcoes}>
            <TextField
              className={classes.fieldTamanho}
              id="select-titulo"
              select
              name="titulo"
              label="Título"
              value={opcoes.titulo}
              onChange={handleChange}
              helperText=""
            >
              {TITULO.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.fieldTamanho}
              id="select-info"
              select
              label="Informação"
              name="info"
              value={opcoes.info}
              onChange={handleChange}
              helperText=""
            >
              {INFORMACAO.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.fieldTamanho}
              id="select-tamanho"
              select
              name="tamanho"
              label="Tamanho"
              value={tamanho}
              onChange={handleChange}
              helperText=""
            >
              {TAMANHOS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.fieldTamanho}
              id="select-borda"
              select
              name="borda"
              label="Borda"
              value={opcoes.borda}
              onChange={handleChange}
              helperText=""
            >
              {BORDA.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className={classes.offSetImpressao}>
            <div
              ref={componentRef}
              className={
                opcoes.borda === 1
                  ? `${classes.AreaImpressao} ${classes.borda}`
                  : classes.AreaImpressao
              }
            >
              <div>
                <div>
                  {opcoes.titulo === 1 ? (
                    <Typography
                      variant="h4"
                      component="h2"
                      className={classes.nomeEmpresa}
                      gutterBottom={!(opcoes.titulo === 1)}
                    >
                      {nomeEmpresa}
                    </Typography>
                  ) : null}
                  {opcoes.info === 1 ? (
                    <Typography
                      variant="body1"
                      component="h2"
                      gutterBottom
                      className={classes.infoEmpresa}
                    >
                      {infoEmpresa}
                    </Typography>
                  ) : null}
                </div>

                <QRCode
                  value={`https://cardapio.saurus.net.br/${empresaId}`}
                  size={tamanho}
                  renderAs="svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <Tooltip title="Imprimir" placement="top">
            <Fab onClick={handlePrint} color="secondary" style={{ marginRight: 10 }}>
              <PrintIcon className={classes.icon} />
            </Fab>
          </Tooltip>
          <Tooltip title="Download" placement="top">
            <Fab
              onClick={() =>
                exportComponentAsPNG(componentRef, {
                  fileName: `${(nomeEmpresa || '').length > 0 ? `${nomeEmpresa}_` : ''}QRcode`,
                  html2CanvasOptions: {},
                })
              }
              color="secondary"
            >
              <GetAppIcon className={classes.icon} />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};
export const QRCodeModal = memo(QRCodeModalComp);

import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUser } from 'api/cardapio/utils';
import { CardapioEmpresa } from 'api/cardapio/models';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterDadosEmpresa } from 'api/cardapio/queries';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

class AtualizarDadosEmpresa implements ApiMutation<Partial<CardapioEmpresa>, void> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  async execute(empresa: Partial<CardapioEmpresa>) {
    await FetchCardapioApiAsMasterUser(`/${empresa.empresaId}/configuracao/cardapio`, {
      method: 'PATCH',
      body: JSON.stringify(empresa),
    });
  }

  updateCache(input: Partial<CardapioEmpresa>, retorno: void, cacheInstance: QueryCache) {
    const empresaKey = obterDadosEmpresa.getKey(input.empresaId || '');
    return cacheInstance.invalidateQueries(empresaKey);
  }
}

export const atualizarDadosEmpresa = new AtualizarDadosEmpresa();

export const useAtualizarDadosEmpresa = (
  config?: MutationConfig<void, unknown, Partial<CardapioEmpresa>>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Empresa atualizada com sucesso!',
    },
    atualizarDadosEmpresa,
  );
  return useMutation(atualizarDadosEmpresa.execute, MergeDeep(defaultConfig, config));
};

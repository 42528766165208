import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex:2,
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

import React from 'react';
import { Container } from '@material-ui/core';
import { CardapioHeader } from './cardapio-header';
import { CardapioHeaderNavBar } from './cardapio-navbar';
import { Sacola } from './cardapio-sacola';
import { BottomSacola } from './cardapio-sacola/componentes';
import {
  Sessoes,
  Sessao,
  EditarProdutoModal,
  VisualizarProdutoModal,
} from 'modulos/cardapio/componentes';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { useCardapioApi, useMasterApi, useCardapioLogica } from './hooks';
import { CategoriasCarrosel } from 'modulos/cardapio/componentes/categorias';
import { CategoriaCarroselInstancia } from 'modulos/cardapio/componentes/categorias/categorias-carrosel/categorias-carrosel-instancia';
import { CardapioProps } from './cardapio-props';
import { useStyles } from './cardapio-styles';
import { SessoesInstancia } from 'modulos/cardapio/componentes/produtos';
import { CardapioFooter } from './cardapio-footer';
import { MasterPagina } from 'modulos';
import { MergedCardapioMaster } from 'api/merged';
import { EditarDados } from 'modulos/cardapio/paginas/edicao/edicao-header/editar-dados';

export const Cardapio = ({ somenteLeitura, configurando, contextoSelecionado }: CardapioProps) => {
  const classes = useStyles();
  const sessoes = React.useRef<SessoesInstancia>(null);
  const pesquisa = React.useRef<HTMLDivElement | null>(null);
  const menuNavRef = React.useRef<HTMLDivElement | null>(null);
  const refCategorias = React.useRef<CategoriaCarroselInstancia>(null);
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
  const { empresaId } = useParams<{ empresaId: string }>();
  const { dados: apiDados, operacoes: api } = useCardapioApi(empresaId);
  /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
  const { dados: apiMasterDados, operacoes: operacoesApiMaster } = useMasterApi(empresaId);

  const { dados: logicaDados, operacoes: logica } = useCardapioLogica(
    sessoes,
    refCategorias,
    pesquisa,
    menuNavRef,
    apiDados.sessoes.data || [],
    apiDados.categorias.data || [],
    api,
    empresaId,
    somenteLeitura,
  );

  const carregandoCategoria =
    apiDados.categorias.isLoading ||
    apiDados.categorias.isFetching ||
    api.atualizarCategoria.isLoading ||
    api.desativarCategoria.isLoading ||
    api.reativarCategoria.isLoading ||
    api.deletarCategoria.isLoading;
  return (
    <MasterPagina>
      <React.Fragment>
        <CardapioHeaderNavBar
          empresaId={empresaId}
          somenteLeitura={somenteLeitura}
          nomeEmpresa={apiDados.empresa.data?.nome}
          infoEmpresa={apiDados.empresa.data?.informacao}
          logoUrl={apiDados.empresa.data?.logo}
          logout={logica.logout}
          imagemUrl={apiDados.empresa.data?.capa}
          categorias={apiDados.categorias.data}
          referencia={menuNavRef}
          navegarEditar={logica.navegarParaEdicao}
          quandoSacolaClick={logica.abrirSacola}
          setConfigurando={logica.configurandoWrapper}
          configurando={configurando || false}
          termoPesquisa={logicaDados.termoPesquisa}
          categoriaSelecionada={logicaDados.categoriaSendoExibida}
          contextoSelecionado={contextoSelecionado}
          quandoPesquisar={logica.quandoTermoForDigitadoNoNavbar}
          categoriaClick={logica.categoriaClick}
          limparTermo={logica.limparTermoPesquisa}
        />
        <div ref={logicaDados.header}>
          <CardapioHeader
            atualizando={api.atualizarEmpresa.isLoading || apiDados.empresa.isFetching}
            solicitarAtualizacao={api.atualizarEmpresa.atualizarEmpresa}
            somenteLeitura={somenteLeitura}
            empresa={apiDados.empresa.data}
            empresaMaster={apiMasterDados.empresa.data}
            className={classes.header}
            configurando={configurando || false}
          />

          {!configurando && (
            <Container fixed>
              {/* <div ref={pesquisa}></div> */}
              {logicaDados.pesquisando && !configurando && (
                <div ref={pesquisa}>
                  <Sessao
                    somenteLeitura={somenteLeitura}
                    sessaoDeBusca={true}
                    carregando={logicaDados.carregandoPesquisa}
                    destaque={`"${logicaDados.termoPesquisa}"`}
                    className={classes.pesquisa}
                    sessao={{
                      categoria: 'Buscando por: ',
                      categoriaId: '',
                      ativo: true,
                      produtos: logicaDados.resultadoPesquisa,
                    }}
                    onProdutoClick={logica.abrirEdicaoProduto}
                    deletar={api.desativarProduto.executar}
                    adicionarProdutoClick={logica.abrirAdicaoProduto}
                  />
                </div>
              )}
              <CategoriasCarrosel
                ref={refCategorias}
                empresaId={empresaId}
                somenteLeitura={somenteLeitura}
                categorias={apiDados.categorias.data}
                carregando={carregandoCategoria}
                deletarCategoria={logica.deletarCategoriaFullWrapper}
                desativarCategoria={api.desativarCategoria.executar}
                reativarCategoria={api.reativarCategoria.executar}
                onCategoriaClick={logica.categoriaClick}
                atualizandoCategoria={carregandoCategoria}
                salvarCategoria={logica.atualizarCategoriaWrapper}
              />
            </Container>
          )}
        </div>

        {!configurando && (
          <>
            <Container fixed>
              <Sessoes
                ref={sessoes}
                carregando={apiDados.sessoes.isLoading || apiDados.sessoes.isFetching}
                sessoes={apiDados.sessoes.data}
                somenteLeitura={somenteLeitura}
                className={classes.sessao}
                quandoSessaoForVisualizada={logica.quandoSessaoForExibida}
                deletar={api.desativarProduto.executar}
                adicionarProdutoClick={logica.abrirAdicaoProduto}
                onProdutoClick={logica.abrirEdicaoProduto}
                deletarCategoria={logica.deletarCategoriaWrapper}
                desativarCategoria={logica.desativarCategoriaWrapper}
                reativarCategoria={logica.reativarCategoriaWrapper}
                editarCategoria={logica.editarCategoriaWrapper}
              />
              {/* <ScrollToTop /> */}
              <Sacola
                empresaDados={
                  {
                    empresaMaster: apiMasterDados.empresa.data,
                    cardapioEmpresa: apiDados.empresa.data,
                  } as MergedCardapioMaster
                }
                empresaId={empresaId}
                sacolaAberta={logicaDados.sacola}
                quandoFechado={logica.fecharSacola}
              />
            </Container>
            {somenteLeitura && isMobile && (
              <BottomSacola empresaId={empresaId} quandoClicado={logica.abrirSacola} />
            )}
          </>
        )}

        {configurando && (
          <EditarDados
            empresaId={empresaId}
            aberto={configurando || false}
            quandoForFechado={logica.navegarParaEdicao}
          />
        )}

        <CardapioFooter empresa={apiDados.empresa.data} />
        {somenteLeitura === false ? (
          <EditarProdutoModal
            empresaId={empresaId}
            aberto={logicaDados.produtoAberto}
            produtoId={logicaDados.produtoId}
            categoriaId={logicaDados.categoriaSelecionada}
            quandoForFechado={logica.fecharEdicaoProduto}
            atualizandoProduto={api.atualizarProduto.isLoading}
            salvarProduto={api.atualizarProduto.executar}
          />
        ) : (
          <VisualizarProdutoModal
            aberto={logicaDados.produtoAberto}
            empresaId={empresaId}
            empresaDados={
              {
                empresaMaster: apiMasterDados.empresa.data,
                cardapioEmpresa: apiDados.empresa.data,
              } as MergedCardapioMaster
            }
            quandoForFechado={logica.fecharEdicaoProduto}
            produtoId={logicaDados.produtoId}
          ></VisualizarProdutoModal>
        )}
      </React.Fragment>
    </MasterPagina>
  );
};

import React, { ReactElement } from 'react';
import { useTheme, Container, useMediaQuery, makeStyles, Grid } from '@material-ui/core';
import { useResources } from 'resources';
import { MasterPagina } from '.';

const useStyles = makeStyles((theme) => ({
  gridSaurus: {
    top:'20px',
    height: '80px',
    position: 'fixed',
    bottom: '5px',
    right: '15px',
    opacity: '1',
  },
  gridSaurusMob: {
    height: '6em',
    borderTop: '1px dotted #D0D0D0',
    marginTop: '2em',
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  saurusImg: {
    height: '100%',
    margin: 'auto',
    display: 'flex',
  },
}));

interface SimplesPaginaProps {
  children: ReactElement;
}

export const SimplesPagina = ({ children }: SimplesPaginaProps) => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();
  const resources = useResources();

  return (
    <MasterPagina>
      <Container fixed>
        <Grid className={isMobile ? classes.gridSaurusMob : classes.gridSaurus} xs={12}>
          <img
            className={classes.saurusImg}
            src={resources.paginas.paginaSimples.logo.src}
            alt={resources.paginas.paginaSimples.logo.alt}
          />
        </Grid>
        <Grid xs={12}>{children}</Grid>
      </Container>
    </MasterPagina>
  );
};

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Divider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { DeliveryDadosInstancia } from './componentes';
import { DeliveryDadosEntregaForm, DeliveryDadosClienteForm } from './componentes';
import { Modal } from 'componentes/modal';
import { DadosDelivery, EnumOpcoesDelivery } from 'storage/delivery/models';
import { useAtualizarDadosDelivery } from 'storage/delivery/mutations';
import { EmpresaMaster } from 'api/master/models/empresa-master';
const useStyles = makeStyles((theme) => ({
  contentModal: {
    padding: '16px 16px 5px 16px',
  },
  cardContentRoot: {
    padding: '5px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
}));

export interface DeliveryModalProps {
  aberto: boolean;
  empresaMaster: EmpresaMaster | undefined;
  empresaId: string;
  permiteEntrega?: string;
  permiteRetirada?: string;
  dadosDelivery: DadosDelivery | undefined;
  quandoForFechado: any;
}

export const DeliveryModal = ({
  aberto,
  empresaMaster,
  empresaId,
  permiteEntrega,
  permiteRetirada,
  dadosDelivery,
  quandoForFechado,
}: DeliveryModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(EnumOpcoesDelivery.Entrega);

  const refRetirada = useRef<DeliveryDadosInstancia>(null);
  const refEntrega = useRef<DeliveryDadosInstancia>(null);

  const [atualizarDados] = useAtualizarDadosDelivery();

  useEffect(() => {
    if (dadosDelivery?.tipo === undefined) {
      setValue(
        permiteEntrega === 'true' ? EnumOpcoesDelivery.Entrega : EnumOpcoesDelivery.Retirada,
      );
    } else {
      if (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega) {
        setValue(
          permiteEntrega === 'true' ? EnumOpcoesDelivery.Entrega : EnumOpcoesDelivery.Retirada,
        );
      }
      if (dadosDelivery?.tipo === EnumOpcoesDelivery.Retirada) {
        setValue(
          permiteRetirada === 'true' ? EnumOpcoesDelivery.Retirada : EnumOpcoesDelivery.Entrega,
        );
      }
    }
  }, [aberto, dadosDelivery, permiteEntrega, permiteRetirada]);

  const handleChange = (panel: number) => {
    setValue(panel);
  };
 
  const fechar = () => {
    quandoForFechado(
      dadosDelivery === undefined ||
        (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega && permiteEntrega === 'false') ||
        (dadosDelivery?.tipo === EnumOpcoesDelivery.Retirada && permiteRetirada === 'false'),
    );
  };

  const capitalize = useCallback((value: any) => {
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);
  const enderecoFormatado = useCallback(
    (lgr, nro, bairro, municipio, uf) => {
      return `${capitalize(lgr.toLowerCase())}, 
    ${capitalize(nro.toLowerCase())} - 
    ${capitalize(bairro.toLowerCase())}, 
    ${capitalize(municipio.toLowerCase())}/${uf}`;
    },
    [capitalize],
  );

  const confirmarForms = () => {
    let dadosEntrega;
    let dadosCliente = refRetirada.current?.submit();
    if (value === EnumOpcoesDelivery.Entrega) {
      dadosEntrega = refEntrega.current?.submit();
    }

    if (dadosCliente.sucesso && (dadosEntrega?.sucesso || value === EnumOpcoesDelivery.Retirada)) {
      let dadosDelivery = { ...dadosCliente?.data, ...dadosEntrega?.data } as DadosDelivery;
      dadosDelivery.tipo = value;
      atualizarDados({ dados: dadosDelivery, idEmpresa: empresaId });
      quandoForFechado();
    }
  };
  return (
    <Modal
      tamanho="md"
      aberto={aberto}
      titulo="Dados Delivery"
      quandoForFechado={fechar}
      zIndex="9999"
    >
      <DeliveryDadosClienteForm ref={refRetirada} dadosDelivery={dadosDelivery} modo={value} />
      <Grid container spacing={1} style={{ marginTop: '1rem' }}>
        {permiteRetirada === 'true' && (
          <Grid item style={{ width: '100%' }}>
            <Card
              variant="outlined"
              style={{
                display: 'flex',
                border:
                  value === EnumOpcoesDelivery.Retirada
                    ? `1px solid ${theme.palette.primary.main}`
                    : '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => handleChange(EnumOpcoesDelivery.Retirada)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  checked={value === EnumOpcoesDelivery.Retirada}
                  style={{ marginLeft: !isMobile ? '5px' : 'unset' }}
                />
              </div>
              <div>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Typography variant="h5">Retirar no Local</Typography>
                  <Typography variant="body1">
                    {enderecoFormatado(
                      empresaMaster?.lgr || '',
                      empresaMaster?.nro || '',
                      empresaMaster?.bairro || '',
                      empresaMaster?.municipio || '',
                      empresaMaster?.uf || '',
                    )}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        )}

        {permiteEntrega === 'true' && (
          <Grid item style={{ width: '100%' }}>
            <Card
              variant="outlined"
              style={{
                display: 'flex',
                border:
                  value === EnumOpcoesDelivery.Entrega
                    ? `1px solid ${theme.palette.primary.main}`
                    : '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => handleChange(EnumOpcoesDelivery.Entrega)}
            >
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Typography variant="h5" style={{ display: isMobile ? 'flex' : 'initial' }}>
                  <Checkbox checked={value === EnumOpcoesDelivery.Entrega} color="primary" />
                  Entregar no Meu Endereço
                </Typography>
                <div style={{ marginLeft: !isMobile ? '11px' : 'unset' }}>
                  <DeliveryDadosEntregaForm ref={refEntrega} dadosDelivery={dadosDelivery} />
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Divider style={{ marginTop: '1rem' }} />
      <Grid container justify="flex-end" style={{ marginTop: '.5rem' }} onClick={confirmarForms}>
        <Button color="primary">Confirmar</Button>
      </Grid>
    </Modal>
  );
};

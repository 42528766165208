import React, { useState, useLayoutEffect, useRef, useEffect, memo } from 'react';
import { CardapioCategoria } from 'api/cardapio/models';
import { makeStyles, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: theme.palette.text.primary,
  },
  tab: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

export interface CategoriaTabsProps {
  categorias?: CardapioCategoria[];
  categoriaSelecionada?: string;
  categoriaClick?: (categoria?: CardapioCategoria) => any;
}
export const CategoriaTabsComp = ({
  categorias,
  categoriaSelecionada,
  categoriaClick,
}: CategoriaTabsProps) => {
  const classes = useStyles();

  const catOrdenadas = (categorias || []).sort(function (a, b) {
    if ((a.rank || 0) < (b.rank || 0)) return -1;
    if ((a.rank || 0) > (b.rank || 0)) return 1;
    if ((a.descricao || '').toLowerCase() < (b.descricao || '').toLowerCase()) return -1;
    if ((a.descricao || '').toLowerCase() > (b.descricao || '').toLowerCase()) return 1;
    return 0;
  });

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  //SESSAO PARA SETAR O WIDTH CERTO DO NAVBAR
  const [width] = useWindowSize();
  const [widthEachItem, setWidthEachItem] = useState<number>(0);
  const [widthNav, setWidhtNav] = useState<number>(0);
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [widthMenu, setWidhtMenu] = useState<number>(0);
  /* eslint-enable  @typescript-eslint/no-unused-vars */
  const itemtab = useRef(null);
  const tabs = useRef(null);
  const appBar = useRef(null);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH
    const newWidth = width;
    if (newWidth === width)
      setWidthEachItem(itemtab.current ? (itemtab.current as any).offsetWidth : 0);
  }, [width]);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH
    const newWidth = width;
    if (newWidth === width) setWidhtNav(tabs.current ? (tabs.current as any).offsetWidth : 0);
  }, [width]);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH

    const newWidth = width;
    if (newWidth === width) setWidhtMenu(appBar.current ? (appBar.current as any).offsetWidth : 0);
  }, [width]);

  return (
    <>
      <Tabs
        ref={tabs}
        indicatorColor="primary"
        variant={catOrdenadas.length * widthEachItem < widthNav ? 'standard' : 'scrollable'}
        centered={catOrdenadas.length * widthEachItem < widthNav ? true : false}
        scrollButtons="on"
        // classes={{
        //   flexContainer: classes.tabs,
        // }}
        textColor="primary"
        value={categoriaSelecionada || false}
      >
        {catOrdenadas?.map((categoria, index) =>
          categoria.ativo ? (
            <Tab
              ref={itemtab}
              key={categoria.id || index}
              className={classes.tab}
              label={categoria?.descricao}
              value={categoria?.id}
              onClick={() => {
                if (categoriaClick) {
                  categoriaClick(categoria);
                }
              }}
            />
          ) : null,
        )}
      </Tabs>
    </>
  );
};
export const CategoriaTabs = memo(CategoriaTabsComp);

import { AmbienteEnviado } from 'ambiente';
import { cardapioSaurus, menuVip } from './res';

const generateObjectRes = () => {
  switch (AmbienteEnviado.toString()) {
    case 'menuVip':
      return menuVip.getObject();
    case 'cardapioSaurus':
      return cardapioSaurus.getObject();
    default:
      return menuVip.getObject();
  }
};

const res = generateObjectRes();

export const useResources = () => {
  return res;
};

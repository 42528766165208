import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
    marginTop: 40,
  },
  legenda: {
    marginBottom: '5px',
  },
  slider: {
    width: '100%',
    height: '85px',
  },
  slide: {
    padding: '0px 5px',
    "&:focus": {
      outline:'none',
    },
  },
  slideSel: {
    marginRight: 30,
  },
  margin: {
    margin: '0px 5px',
  },
  btnVoltar: {
    position: 'absolute',
    zIndex: 2,
    left: '-10px',
    opacity: 0.5,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  btnAvancar: {
    position: 'absolute',
    zIndex: 2,
    right: '-10px',
    opacity: 0.5,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  voltar: {
    marginBottom: '20px',
    textTransform: 'none',
  },
  breadcrumb: {
    margin: '0px',
    marginTop: '-25px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  link: {
    cursor: 'pointer',
  },
}));

import { createMuiTheme, ThemeOptions } from '@material-ui/core';

export const SaurusTemaOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#0E72AC',
      light: '#57a0de',
      dark: '#00477c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#999999',
      light: '#cacaca',
      dark: '#6b6b6b',
      contrastText: '#555',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      autoComplete: 'off',
      fullWidth: true,
      margin: 'dense',
    },
    MuiButton: {
      variant: 'contained',
      style:{
        cursor:'pointer',
      },
      disableElevation: true,
      color: 'primary',
      fullWidth: false,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
};

export const SaurusTema = createMuiTheme(SaurusTemaOptions);

import { VariaveisAmbiente } from 'ambiente';
import { ItemSacola, DadosDelivery, EnumOpcoesDelivery } from 'storage';

export const generatePedidoWhatsApp = () => {
  const gerarPedido = (
    nomeEmpresa: string,
    telefone: string,
    itens: ItemSacola[],
    totalItens: number,
    total: string,
    permiteEntrega: boolean,
    permiteRetirada: boolean,
    dadosDelivery?: DadosDelivery,
  ) => {
    let url = VariaveisAmbiente.whatsAppLink;
    let mensagem = `Olá ${nomeEmpresa}, gostaria de fazer o pedido:`;

    mensagem += formatItens(itens);
    mensagem += '%0A' + linhaChar('-', 60);

    mensagem += '%0a' + 'Itens: ' + totalItens; // eslint-disable-line no-useless-concat
    mensagem += '%0a' + 'Total do Pedido: ' + bold(`${total}`); // eslint-disable-line no-useless-concat
    mensagem += '%0A' + linhaChar('-', 60);
    mensagem += permiteEntrega || permiteRetirada ? formatDelivery(dadosDelivery) : '';

    return url
      .replace('@telefone', `55${telefone.replace(/\D+/g, '')}`)
      .replace('@mensagem', mensagem)
      .replace(' ', '%20');
  };

  const formatItens = (itens: ItemSacola[]) => {
    let produtos = '';

    itens.forEach((element) => {
      produtos += `%0a* ${bold(`${element.quantidade}x`)} ${element.nome}`;
      produtos += element.observacao.length > 0 ? '%0a%09%09' + bold(`Obs.: ${element.observacao}`) : '';
    });
    return produtos;
  };
  const formatDelivery = (dadosDelivery?: DadosDelivery) => {
    if (dadosDelivery) {
      switch (dadosDelivery?.tipo) {
        case EnumOpcoesDelivery.Entrega:
          return formatEntrega(dadosDelivery);
        case EnumOpcoesDelivery.Retirada:
          return formatRetirada(dadosDelivery);
      }
    }

    return '';
  };
  const formatEntrega = (dadosDelivery?: DadosDelivery) => {
    return `%0A**${bold('ENTREGA')}**${
      (dadosDelivery?.nome || '').length > 0 ? '%0A' + bold(`Nome: ${dadosDelivery?.nome}`) : ''
    }${
      (dadosDelivery?.documento || '').length > 0
        ? '%0A' + bold(`Documento: ${dadosDelivery?.documento}`)
        : ''
    }${
      (dadosDelivery?.telefone || '').length > 0
        ? `%0ACelular: ${dadosDelivery?.telefone.replace('_', '')}`
        : ''
    }
    %0A* ${bold('Endereço Para Entrega:')}%0A${bold(`${dadosDelivery?.lgr}, ${dadosDelivery?.nro}`)}
%0A${bold(`Bairro: ${dadosDelivery?.bairro}`)}%0A${bold(
      `Cidade: ${dadosDelivery?.municipio}/${dadosDelivery?.uf}`,
    )}%0A${bold(`CEP: ${dadosDelivery?.cep}`)}${
      (dadosDelivery?.cpl || '').length > 0 ? `%0AComplemento: ${dadosDelivery?.cpl}` : ''
    }${
      (dadosDelivery?.referencia || '').length > 0
        ? `%0APonto de Referência: ${dadosDelivery?.referencia}`
        : ''
    }`;
  };
  const formatRetirada = (dadosDelivery?: DadosDelivery) => {
    return `%0A**${bold('RETIRADA')}**%0A* ${bold('Dados Para Retirada:')}%0A${bold(
      `Nome: ${dadosDelivery?.nome}`,
    )}%0A${bold(
      `Documento: ${dadosDelivery?.documento}`,
    )}%0ACelular: ${dadosDelivery?.telefone.replace('_', '')}`;
  };
  const bold = (text: string) => {
    return `%2A${text}%2A`;
  };
  /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
  const monospaced = (text: string) => {
    return '```' + text + '```';
  };

  /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
  const italic = (text: string) => {
    return `_${text}_`;
  };
  const linhaChar = (char, size) => {
    let line = '';
    for (let i = 0; i < size; i++) {
      line = line + char;
    }
    return line;
  };

  return {
    gerarPedido,
  };
};

import React from 'react';
import { Botao, BotaoProps } from '../botao';
import { makeStyles, IconButton, IconButtonProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  botao: {
    background: 'rgba(0,0,0, 0.4)',
    border: '0px solid rgba(255,255,255, 0.3)',
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.25)',
    color: theme.palette.common.white,
    padding: '5px 15px',
    '&:hover': {
      background: 'rgba(0,0,0, 0.6) !important',
      boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
    },
  },
  iconButton: {
    background: 'rgba(0,0,0, 0.3)',
    border: '0px solid rgba(255,255,255, 0.3)',
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
    color: theme.palette.common.white,
    '&:hover': {
      background: 'rgba(0,0,0, 0.6) !important',
    },
  },
}));

type BotaoTransparenteProps<Tipo extends 'button' | 'iconButton'> = Tipo extends 'button'
  ? Omit<BotaoProps, 'color' | 'variant'> & {
    tipo: Tipo;
  }
  : Omit<IconButtonProps, 'color'> & {
    tipo: Tipo;
  };

export const BotaoTransparente = <Tipo extends 'button' | 'iconButton'>({
  className,
  tipo,
  ...props
}: BotaoTransparenteProps<Tipo>) => {
  const classes = useStyles();

  return tipo === 'iconButton' ? (
    <IconButton
      {...(props as any)}
      color="inherit"
      className={classNames(classes.iconButton, className)}
    />
  ) : (
    <Botao
      {...props}
      variant="text"
      color="inherit"
      className={classNames(classes.botao, className)}
    />
  );
};

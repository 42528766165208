import React, { ReactElement } from 'react';
import {
    makeStyles,
} from '@material-ui/core';

import BgImg from 'arquivos-estaticos/global/imagens/bg.png';

const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundImage: `url('${BgImg}')`,
        filter: theme.palette.type === 'dark' ? undefined : 'invert(1)',
        backgroundPosition: 'center',
        backgroundRepeatX: 'repeat',
        backgroundRepeatY: 'no-repeat',
        backgroundSize: 'auto 100%',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0.04,
        zIndex: 0,
    },
    conteudo: {
        position: 'relative',
        zIndex: 1,
    },
}));

interface MasterPaginaProps {
    children: ReactElement;
}

export const MasterPagina = ({ children }: MasterPaginaProps) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.bg}></div>
            <div className={classes.conteudo}>
                {children}
            </div>
        </React.Fragment>
    );
};

import React, { Fragment } from 'react';
import { Container, useTheme, useMediaQuery, Button, Fade} from '@material-ui/core';
import {
  CardapioHeaderCapa,
  CardapioHeaderLogo,
  CardapioHeaderText,
  CardapioHeaderInfo,
} from './componentes';
import { MergedCardapioMaster } from 'api/merged';
import { Carregando, useUsuarioLogado } from 'componentes';
import { CardapioHeaderProps } from './cardapio-header-props';
import { useCardapioHeader } from './use-cardapio-header';
import { useStyles } from './cardapio-header-styles';

import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

export const CardapioHeader = (props: CardapioHeaderProps) => {
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { className, atualizando, } = props;
  const { dados, operacoes } = useCardapioHeader(props);
  const { usuario } = useUsuarioLogado();
  const classes = useStyles({
    usuarioInvalido: usuario?.invalido(),
  });

  return (
    <Container className={classNames(className, classes.root)} disableGutters={isSmallDesktop}>
      <Fragment>
        <CardapioHeaderCapa
          height={isMobile ? 'calc(100vh - 150px)' : '60vh'}
          capaUrl={dados?.empresa?.capa}
          somenteLeitura={!props.configurando}
          quandoUmaNovaCapaForSelecionada={operacoes.quandoOsDadosMudarem}
        >
          <CardapioHeaderLogo
            somenteLeitura={!props.configurando}
            logoUrl={dados.empresa?.logo}
            onLogoChange={operacoes.quandoOsDadosMudarem}
            className={[classes.block, classes.logo].join(' ')}
          />
          <CardapioHeaderText
            text={dados.empresa?.nome || props.empresaMaster?.fant}
            somenteLeitura={!props.configurando}
            editando={dados.editandoNome}
            quandoEdicaoForIniciada={operacoes.quandoEdicaoNomeForIniciada}
            quandoTextoForAlterado={operacoes.quandoNomeEmpresaForAlterada}
            tipoEdicao="Nome da Empresa"
            className={classes.block}
          />
          <CardapioHeaderText
            text={dados.empresa?.informacao}
            editando={dados.editandoInformacao}
            somenteLeitura={!props.configurando}
            quandoEdicaoForIniciada={operacoes.quandoEdicaoInformacoesIniciada}
            quandoTextoForAlterado={operacoes.quandoInformacoesForAlterada}
            tipoEdicao="Informações"
            strong={false}
            className={classes.block}
            variant="h5"
          />
          {!props.configurando && (
            <CardapioHeaderInfo
              empresa={
                {
                  cardapioEmpresa: dados.empresa,
                  empresaMaster: props.empresaMaster,
                } as MergedCardapioMaster
              }
            />
          )}
          {/* {dados.mostrarConfirmacao === false && !props.configurando && (
            <CardapioHeaderBusca
              {...busca}
              className={classes.busca}
              referencia={props.referencia}
            />
          )} */}
          <Carregando
            carregando={atualizando || dados.carregando}
            modelo="absolute"
            titulo={atualizando ? 'Atualizando Capa' : 'Buscando Informações'}
            mensagem={
              atualizando
                ? 'Aguarde enquanto atualizamos as configurações do seu estabelecimento.'
                : ''
            }
            className={classes.carregando}
          />
        </CardapioHeaderCapa>
        <Fade in={dados.mostrarConfirmacao} mountOnEnter unmountOnExit>
          <div className={classes.actions}>
            <Button
              size="medium"
              color="default"
              className={classes.action}
              onClick={operacoes.quandoCancelado}
            >
              <CloseIcon fontSize="small" style={{ marginRight: 10 }} />
              Cancelar
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.action}
              onClick={operacoes.quandoAplicado}
            >
              <CheckIcon fontSize="small" style={{ marginRight: 10 }} />
              Salvar
            </Button>
          </div>
        </Fade>
      </Fragment>
    </Container>
  );
};


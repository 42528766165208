import React, { ReactElement } from 'react';
import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Button,
  makeStyles,
} from '@material-ui/core';
import TristeImg from 'arquivos-estaticos/global/imagens/triste.png';
import { SimplesPagina } from '../templates';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    minHeight: '100vh',
  },
  naoEncontradoImg: {
    objectFit: 'contain',
    width: '100%',
    margin: '0 auto',
  },
  voltar: {
    marginTop: '10px',
  },
}));

interface NaoEncontradoPaginaProps {
  titulo?: string;
  mensagem?: ReactElement;
  acaoFn?: () => any;
  acaoText?: string;
}

export const NaoEncontradoPaginaHOC = (
  props: NaoEncontradoPaginaProps = {
    titulo : undefined,
    mensagem : undefined,
  },
) => () => {
  return <NaoEncontradoPagina {...props} />;
};

export const NaoEncontradoPagina = ({
  titulo,
  mensagem,
  acaoFn,
  acaoText,
}: NaoEncontradoPaginaProps) => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();

  const mostrarVoltar = Boolean(acaoFn) && Boolean(acaoText);

  return (
    <SimplesPagina>
      <Grid
        container
        spacing={2}
        className={classes.gridRoot}
        direction={isMobile ? 'column' : 'row-reverse'}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Grid container justify="center">
            <Grid item xs={3} sm={8}>
              <img
                className={classes.naoEncontradoImg}
                src={TristeImg}
                alt="Imagem não encontrado"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            align={isMobile ? 'center' : 'left'}
            gutterBottom
          >
            <strong>{titulo || 'Cardápio não Encontrado'}</strong>
          </Typography>
          <Typography variant="subtitle1" align={isMobile ? 'center' : 'left'}>
            {mensagem ? mensagem : <Typography variant="subtitle1">Verifique se você digitou o link corretamente ou solicite o <strong>QR Code</strong> com um atendente.</Typography>} 
          </Typography>
          {mostrarVoltar && (
            <Button
              fullWidth={isMobile}
              onClick={acaoFn}
              className={classes.voltar}
              variant="contained"
              color="primary"
              size="large"
            >
              {acaoText}
            </Button>
          )}
        </Grid>
      </Grid>
    </SimplesPagina>
  );
};

import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { CardapioCategoria } from 'api/cardapio/models';
import { useQuery, QueryConfig } from 'react-query';

interface ObterCategoriaInput {
  categoriaId: string;
  empresaId: string;
}

class ObterCategoria implements ApiQuery<ObterCategoriaInput, CardapioCategoria> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: ObterCategoriaInput): any[] {
    return ['ObterCategoria', params];
  }

  execute(_: any, { empresaId, categoriaId }: ObterCategoriaInput) {
    return FetchCardapioApiAsPublicUserJSON<CardapioCategoria>(
      empresaId,
      `/${empresaId}/categoria/${categoriaId}`,
    ).then(
      (categoria) => ({
        cor: categoria?.cor,
        descricao: categoria?.descricao,
        id: categoria?.id,
        imagemUrl: categoria?.imagemUrl,
        ativo: categoria?.ativo,
        paiId: categoria?.paiId || '',
        rank: categoria?.rank,
        nivel: 0,
        descFormatado: '',
        herancaId: '',
      } as CardapioCategoria)
    );
  }
}

export const obterCategoria = new ObterCategoria();

export const useObterCategoria = (
  props: ObterCategoriaInput,
  config: QueryConfig<CardapioCategoria>,
) => {
  return useQuery(obterCategoria.getKey(props), obterCategoria.execute, config);
};

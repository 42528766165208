import React, { useState, useCallback, MouseEvent, memo } from 'react';
import { makeStyles, Drawer, Typography, Divider, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import {ModalConfirmacao } from '.';
import EditIcon from '@material-ui/icons/Edit';

import CropFreeIcon from '@material-ui/icons/CropFree';

import MenuIcon from '@material-ui/icons/Menu';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

export interface DrawerMenuProps {
  wrapNavEdicao: () => any;
  setCarregando: (carregando: boolean) => any;
  onVisualizarClick: () => any;
  onEditarConfiguracoesClick: () => any;
  gerarQRCode: () => any;
  logout: () => any;
  abrirSacola: () => any;
  buscarProds: () => any;
  capa?: string;
  somenteLeitura: boolean;
  rotaInvalida?: boolean;
  logoUrl?: string;
}

const useStyles = makeStyles((theme) => ({
  imagemCapa: ({ capa }: any) => ({
    backgroundImage: capa ? `url(${capa})` : 'none',
    height: '108px',
    backgroundRepeat: 'no-repeat',
    borderRadius: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  }),
  btnMenu: { display: 'flex', alignItems: 'center' },
  pointer: { cursor: 'pointer' },
  btnBar: { color: '#FFFFFF', fontSize: '1.5rem', fontWeight: 500 },
  drawer: {
    width: '60%',
  },
  logo: {
    marginRight: 'unset',
    maxHeight: '80px',
  },
  listHover: {
    '&:hover,&:active,&:focus': {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    },
  },
  menuDrawerIcon: {
    marginRight: 'auto',
    fontSize: '2.5rem',
  },
  sacolaPath: { fill: theme.palette.text.primary },
  sacola: { marginRight: '10px', marginLeft: '2px' },
}));
export const DrawerMenuComp = ({
  somenteLeitura,
  rotaInvalida,
  capa,
  logoUrl,
  wrapNavEdicao,
  setCarregando,
  onVisualizarClick,
  onEditarConfiguracoesClick,
  gerarQRCode,
  logout,
  abrirSacola,
  buscarProds,
}: DrawerMenuProps) => {
  const classes = useStyles({ capa });
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [modal, setModal] = useState(false);
  const handleModal = useCallback(() => {
    setModal((prev)=>!prev);
  }, []);
  const handleMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorMenu(event.currentTarget);
    },
    [setAnchorMenu],
  );
  const handleClose = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleCloseWrapper = useCallback(
    (funcao, carregando?: boolean) => {
      handleClose();
      setCarregando(carregando || false);
      setTimeout(() => funcao(), 1);
    },
    [handleClose, setCarregando],
  );

  return (
    <>
      <Typography
        aria-haspopup="true"
        className={classNames(classes.menuDrawerIcon, classes.pointer)}
        onClick={handleMenu}
      >
        <MenuIcon
          color="inherit"
          style={{ fontWeight: 'inherit', fontSize: 'inherit', marginBottom: -6 }}
        />
      </Typography>
      <Drawer
        anchor="left"
        open={Boolean(anchorMenu)}
        onClose={handleClose}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.imagemCapa}>
          <img src={logoUrl} className={classes.logo} alt="Logo Empresa"></img>
        </div>
        <Divider />
        <List>
          {rotaInvalida === false ? (
            <>
              <ListItem
                className={classes.listHover}
                onClick={() =>
                  handleCloseWrapper(somenteLeitura ? wrapNavEdicao : onVisualizarClick, true)
                }
              >
                <>
                  {somenteLeitura ? (
                    <Typography
                      className={classNames(classes.btnMenu, classes.pointer)}
                      color="textPrimary"
                    >
                      <EditIcon color="inherit" style={{ marginRight: 10 }} />
                      Editar Cardápio
                    </Typography>
                  ) : (
                    <Typography
                      color="textPrimary"
                      className={classNames(classes.btnMenu, classes.pointer)}
                    >
                      <OpenInBrowserIcon color="inherit" style={{ marginRight: 10 }} />
                      Visualizar Cardápio
                    </Typography>
                  )}
                </>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(onEditarConfiguracoesClick)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <SettingsIcon color="inherit" style={{ marginRight: 10 }} />
                  Configurações
                </Typography>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(gerarQRCode)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <CropFreeIcon color="inherit" style={{ marginRight: 10 }} />
                  Gerar Código QR
                </Typography>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(abrirSacola)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <svg
                    height="23"
                    viewBox="0 0 82 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.sacola}
                  >
                    <path
                      d="M73.0938 73.8L66.9938 24.5C66.6938 23.2 65.5938 22.2 64.2938 22.2H52.2938V18.1C52.2938 7.2 46.1938 0 37.1938 0C28.1938 0 22.0938 7.3 22.0938 18.1V22.2H8.79382C7.49382 22.2 6.39382 23.2 6.09382 24.6L0.0938185 73.9C-0.306181 76.6 0.593819 79.5 2.39382 81.5C4.09382 83.6 6.79382 84.9 9.69382 84.9H63.2938C66.0938 84.9 68.8938 83.6 70.4938 81.6C72.4938 79.5 73.4938 76.5 73.0938 73.8ZM27.2938 22.3V18.2C27.2938 12.3 29.8938 5.4 37.0938 5.4C44.2938 5.4 46.8938 12.3 46.8938 18.2V22.3H27.2938Z"
                      className={classes.sacolaPath}
                    />
                  </svg>
                  Abrir Sacola
                </Typography>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(buscarProds)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <SearchRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                  Buscar Produto
                </Typography>
              </ListItem>
            </>
          )}

          <Divider />
          {rotaInvalida === false ? (
            <ListItem
              className={classes.listHover}
              onClick={() => handleCloseWrapper(logout, !somenteLeitura)}
            >
              <Typography
                color="textPrimary"
                className={classNames(classes.btnMenu, classes.pointer)}
              >
                <ExitToAppRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                Deslogar
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              className={classes.listHover}
              onClick={() => handleCloseWrapper(handleModal)}
            >
              <Typography
                color="textPrimary"
                className={classNames(classes.btnMenu, classes.pointer)}
              >
                <AccountCircleRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                Entrar
              </Typography>
            </ListItem>
          )}
        </List>
      </Drawer>

      <ModalConfirmacao
      aberto={modal}
      quandoLogar={() => handleCloseWrapper(wrapNavEdicao, false)}
      quandoCancelar={handleModal}
      />
    </>
  );
};

export const DrawerMenu = memo(DrawerMenuComp);

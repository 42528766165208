import React from 'react';
import { makeStyles, BottomNavigation, Typography } from '@material-ui/core';
import { useObterSacola, ItemSacola } from 'storage';

import { ButtonSacola } from './button-sacola';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 .5rem',
  },
  sacola: { height: '35px' },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textos:{
      color:'white'
  }
}));
export interface BottomSacolaProps {
  empresaId: string;
  quandoClicado?: () => any;
}

export const BottomSacola = ({ empresaId, quandoClicado }: BottomSacolaProps) => {
  const itens = useObterSacola({ empresaId: empresaId });
  const classes = useStyles();
  const totalSacola = React.useCallback(() => {
    let total =
      (itens?.data || ([] as ItemSacola[])).length > 0
        ? itens?.data?.map((item) => item.valor * item.quantidade).reduce((prev, next) => prev + next)
        : 0;

    return (total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  },[itens]);
  return (
    <>
      {(itens?.data || []).length > 0 ? (
        <div onClick={quandoClicado}>
          <BottomNavigation className={classes.root}>
            <ButtonSacola empresaId={empresaId} corLogo="branco" colorBadge="secondary" />
            <Typography variant="h6" className={classes.textos}>Sacola</Typography>

            <Typography variant="h6" className={classes.textos}>{totalSacola()}</Typography>
          </BottomNavigation>
        </div>
      ) : null}
    </>
  );
};

import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUser } from 'api/cardapio/utils';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterCategorias } from 'api/cardapio/queries';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

export interface DesativarCategoriaInput {
  empresaId: string;
  categoriaId: string;
}

class DesativarCategoria implements ApiMutation<DesativarCategoriaInput, void> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  async execute({ categoriaId, empresaId }: DesativarCategoriaInput) {
    await FetchCardapioApiAsMasterUser(`/${empresaId}/categoria/${categoriaId}`, {
      method: 'DELETE',
    });
  }

  updateCache(input: DesativarCategoriaInput, retorno: void, queryCache: QueryCache) {
    const categoriasKey = obterCategorias.getKey(input.empresaId);

    return queryCache.invalidateQueries(categoriasKey);
  }
}

export const desativarCategoria = new DesativarCategoria();

export const useDesativarCategoria = (
  config?: MutationConfig<void, unknown, DesativarCategoriaInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Categoria deletada com sucesso.',
    },
    desativarCategoria,
  );

  return useMutation(desativarCategoria.execute, MergeDeep(defaultConfig, config));
};

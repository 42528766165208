import { useObterDadosEmpresaMaster } from 'api/master';
import { useAtualizarDadosEmpresaMaster } from 'api/master/mutations';
import { useCallback } from 'react';
import { EmpresaMaster } from 'api/master/models';
export const useMasterApi = (empresaId: string) => {
  const { refetch: buscarDados, ...empresa } = useObterDadosEmpresaMaster(empresaId, {
    enabled: empresaId,
  });

  const [
    atualizarCadastroEmpresaMaster,
    atualizarCadastroEmpresaDados,
  ] = useAtualizarDadosEmpresaMaster();

  const atualizarCadastroEmpresa = useCallback(
    (empresa?: EmpresaMaster) => {
      if (empresa != null) {
        return atualizarCadastroEmpresaMaster(empresa);
      }
    },
    [atualizarCadastroEmpresaMaster],
  );
  return {
    dados: {
      empresa,
    },
    operacoes: {
      atualizarCadastroEmpresa: {
        executar: atualizarCadastroEmpresa,
        ...atualizarCadastroEmpresaDados,
      },
    },
  };
};

import { makeStyles } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  botaoContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
  },
  popover: {
    pointerEvents: 'none',
  },
  delete: {
    marginTop: '10px',
    textTransform: 'none',
  },
  deleteIcon: {
    marginRight: '5px',
  },
  acoes: {
    display: 'flex',
    pointerEvents: 'auto',
    justifyContent: 'center',
  },
  acoesFechado: {
    transition: 'top 0s linear 0.2s',
    pointerEvents: 'none',
  },
  acao: {
    marginTop: '10px',
    marginBottom: '20px',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: emphasize(theme.palette.primary.main, 0.2),
    },
    transition: `${theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    })}, opacity 0.8s`,
    opacity: 1,
  },
  acaoFechado: {
    opacity: 0,
    transform: 'scale(0)',
  },
  botao: {
    position: 'relative',
    pointerEvents: 'auto',
    width: '175px',
    height: '85px',
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      width: '120px',
    },
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  textoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  texto: {
    fontSize: '0.9rem',
    color: '#000',
    letterSpacing: '0.6px',
    padding: `${theme.spacing(4)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px`,
      fontSize: '0.85rem',
      letterSpacing: '0.3px',
    },
  },
  textoBtn: {
    color: theme.palette.primary.contrastText,
  },
  textoImg: {
    color: theme.palette.primary.contrastText,
  },
  status: {
    position: 'absolute',
    right: 5,
    top: 5,
    textAlign:'center',
    width:30,
    lineHeight:'8px',
    fontSize:8,
    color: theme.palette.primary.contrastText,


  }
}));

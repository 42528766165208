import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, ItemSacola, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

export interface EsvaziarSacolaInput {
  idEmpresa: string;
}

class EsvaziarSacola implements ApiMutation<EsvaziarSacolaInput, ItemSacola> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ idEmpresa }: EsvaziarSacolaInput) {
    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
    ) as ItemSacola[];

    return storageCardapio.save(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
      itens.filter((x) => x.idRestaurante !== idEmpresa),
    );
  }

  updateCache(input: EsvaziarSacolaInput, retorno: ItemSacola, cache: QueryCache) {
    const sessoesKey = obterSacola.getKey({ empresaId: input.idEmpresa });
    return cache.invalidateQueries(sessoesKey);
  }
}

export const esvaziarSacola = new EsvaziarSacola();

export const useEsvaziarSacola = (
  config?: MutationConfig<ItemSacola, unknown, EsvaziarSacolaInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Sacola Esvaziada!',
    },
    esvaziarSacola,
  );

  return useMutation(esvaziarSacola.execute, MergeDeep(defaultConfig, config));
};

import { ApiQuery } from 'api/interfaces';
import { CardapioProduto, CardapioCategoria } from 'api/cardapio/models';
import { FetchCardapioApiAsMasterUserJSON,FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { UsuarioMaster } from 'componentes';
import { useQuery, QueryConfig } from 'react-query';

interface ObterProdutoInput {
  empresaId: string;
  produtoId: string;
}

interface JSONApiFoto {
  url: string;
}

interface JSONApi {
  id: string;
  descricao: string;
  infAdic: string;
  variacao: {
    descricao: string;
    medida: string;
    foto: JSONApiFoto[];
    valor: number;
  }[];
  prodCategoria: {
    categoria: {
      id: string;
      descricao: string;
      imagemUrl: string;
      paiId: string;
    };
  }[];
}

interface ObterProdutoRetorno extends CardapioProduto {
  categoria: CardapioCategoria;
}

class ObterProduto implements ApiQuery<ObterProdutoInput, ObterProdutoRetorno> {
  getKey(params: ObterProdutoInput): any[] {
    return ['ObterProduto', params];
  }

  execute(_: any, { empresaId, produtoId }: ObterProdutoInput): Promise<ObterProdutoRetorno> {

   

    const usuario =  UsuarioMaster.fromLocalStorageToken();
    if((usuario?.token||'').length>0){

    return FetchCardapioApiAsMasterUserJSON<JSONApi>(`/${empresaId}/produto/${produtoId}`).then(
      (produto) =>
        ({
          id: produto?.id,
          descricao: produto?.infAdic,
          nome: `${produto?.descricao} ${produto?.variacao[0]?.descricao}`,
          valor: produto?.variacao[0]?.valor,
          medida: produto?.variacao[0]?.medida,
          imagemUrl: produto?.variacao[0]?.foto[0]?.url,
          categoria: produto?.prodCategoria[0]?.categoria?.id
            ? (({
                id: produto?.prodCategoria[0]?.categoria?.id,
                nome: produto?.prodCategoria[0]?.categoria?.descricao,
                imagemUrl: produto?.prodCategoria[0]?.categoria?.imagemUrl,
                paiId: produto?.prodCategoria[0]?.categoria?.paiId,
              } as unknown) as CardapioCategoria)
            : undefined,
        } as ObterProdutoRetorno),
    );
  }else{

        return FetchCardapioApiAsPublicUserJSON<JSONApi>(empresaId,`/${empresaId}/produto/${produtoId}`).then(
      (produto) =>
        ({
          id: produto?.id,
          descricao: produto?.infAdic,
          nome: `${produto?.descricao} ${produto?.variacao[0]?.descricao}`,
          valor: produto?.variacao[0]?.valor,
          medida: produto?.variacao[0]?.medida,
          imagemUrl: produto?.variacao[0]?.foto[0]?.url,
          categoria: produto?.prodCategoria[0]?.categoria?.id
            ? (({
                id: produto?.prodCategoria[0]?.categoria?.id,
                nome: produto?.prodCategoria[0]?.categoria?.descricao,
                imagemUrl: produto?.prodCategoria[0]?.categoria?.imagemUrl,
                paiId: produto?.prodCategoria[0]?.categoria?.paiId,
              } as unknown) as CardapioCategoria)
            : undefined,
        } as ObterProdutoRetorno),
    );
    

  }
}
}
  


export const obterProduto = new ObterProduto();

export const useObterProduto = (
  input: ObterProdutoInput,
  config?: QueryConfig<ObterProdutoRetorno, ObterProdutoInput>,
  
) => {
  return useQuery(obterProduto.getKey(input), obterProduto.execute, config);
};

import { FetchPedidosApi } from './fetch-pedidos-api';
import { ObterTokenIntegrador } from 'api/master/obter-token-integrador';
import { MergeDeep } from 'utils';

const FetchPedidosApiWithUser = async (
  token: string,
  path: RequestInfo,
  options?: any,
  empresaId?: string,
): Promise<Response> => {
  const Default: any = {
    headers: {
      Authorization: `Bearer ${await validadeToken(token, empresaId)}`,
    },
  };
  const response = await FetchPedidosApi(path, MergeDeep(Default, options));

  return response;
};

export const FetchPedidosApiWithUserJSON = <Tipo = any>(
  token: string,
  path: RequestInfo,
  options?: any,
  empresaId?: string,
) => {
  return FetchPedidosApiWithUser(token, path, options, empresaId).then(
    (response) => response.json() as Promise<Tipo>,
  );
};
const validadeToken = async (token, empresaId) => {

  if (token.length > 0 && (token as any).empresaId === empresaId) {
    return token;
  }

  return await ObterTokenIntegrador(empresaId);
};

import React, { MouseEvent, useState, useCallback, memo } from 'react';
import { makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import classNames from 'classnames';
import {ModalConfirmacao } from '.';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

export interface MenuContaProps {
  wrapNavEdicao: () => any;
  logout: () => any;
  setCarregando: (carregando) => any;
  nomeUsuario?: string;
  somenteLeitura: boolean;
  rotaInvalida: boolean;
}

const useStyles = makeStyles((theme) => ({
  btnMenu: { display: 'flex', alignItems: 'center' },
  pointer: { cursor: 'pointer' },

  spanNome: { fontSize: '15px', marginLeft: '5px' },
  spanLogin: {
    fontSize: '11px',
    marginLeft: '5px',
    wordBreak: 'break-word',
    maxWidth: '132px',
  },
  expandLogin: { fontWeight: 'inherit', fontSize: '15px', marginLeft: 5, marginBottom: -3 },
  expandNome: { fontSize: '25px', fontWeight: 'inherit', marginBottom: '-7px' },
  menuConta: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 500,
    cursor: 'pointer',
  },
  rootModal: {
    height: '16vh',
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  btnsModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  btnModal: {
    fontWeight: 600,

    width: '40%',
  },
  textoModal: {
    fontSize: '1.8rem',
    marginTop: '1rem',
    fontWeight: 'bolder',
  },
}));
export const MenuContaComp = ({
  wrapNavEdicao,
  logout,
  setCarregando,
  nomeUsuario,
  rotaInvalida,
  somenteLeitura,
}: MenuContaProps) => {
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [modal, setModal] = useState(false);
  const handleMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorMenu(event.currentTarget);
    },
    [setAnchorMenu],
  );
  const handleClose = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleCloseWrapper = useCallback(
    (funcao, carregando?: boolean) => {
      handleClose();
      setCarregando(carregando || false);
      carregando ? setTimeout(() => funcao(), 1) : funcao();
    },
    [handleClose, setCarregando],
  );

  const handleModal = useCallback(() => {
    setModal((prev)=>!prev);
  }, []);


  return (
    <>
      <Typography
        aria-haspopup="true"
        className={classNames(classes.menuConta)}
        onClick={handleMenu}
      >
        <AccountCircle style={{ fontSize: '35px' }} />
        <span className={rotaInvalida === false ? classes.spanNome : classes.spanLogin}>
          Olá
          {rotaInvalida === false
            ? ` ${(nomeUsuario || '').split(' ')[0]}`
            : ', faça seu login ou cadastre-se'}
          {Boolean(anchorMenu) ? (
            <ExpandLessIcon
              color="inherit"
              className={rotaInvalida === false ? classes.expandNome : classes.expandLogin}
            />
          ) : (
            <ExpandMoreIcon
              color="inherit"
              className={rotaInvalida === false ? classes.expandNome : classes.expandLogin}
            />
          )}
        </span>
      </Typography>
      <Menu
        anchorEl={anchorMenu}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 40, horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorMenu)}
        onClose={handleClose}
      >
        {rotaInvalida === false ? (
          <MenuItem onClick={() => handleCloseWrapper(logout, !somenteLeitura)}>
            <Typography
              className={classNames(classes.btnMenu, classes.pointer)}
              color="textPrimary"
            >
              <ExitToAppRoundedIcon color="inherit" style={{ marginRight: '5px' }} />
              Deslogar
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleCloseWrapper(handleModal, false)}>
            <Typography
              className={classNames(classes.btnMenu, classes.pointer)}
              color="textPrimary"
            >
              <AccountCircleRoundedIcon
                color="inherit"
                style={{ marginRight: '5px', fontSize: '2rem' }}
              />
              Entrar
            </Typography>
          </MenuItem>
        )}
      </Menu>

      

      <ModalConfirmacao
      aberto={modal}
      quandoLogar={() => handleCloseWrapper(wrapNavEdicao, false)}
      quandoCancelar={handleModal}
      />
    </>
  );
};

export const MenuConta = memo(MenuContaComp);

import React from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import classNames from 'classnames';

export interface TituloProps {
  titulo?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'capitalize',
  },
}));

export const Titulo = ({
  children,
  titulo,
  ...props
}: TituloProps & Omit<TypographyProps, 'variant'>) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" {...props} className={classNames(classes.root, props.className)}>
      <strong>
        {titulo?.toLowerCase() || typeof children === 'string'
          ? children?.toString().toLowerCase()
          : children}
      </strong>
    </Typography>
  );
};

import { isEmpty, isEqual, isNil } from 'lodash';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { CardapioHeaderProps } from './cardapio-header-props';


export const useCardapioHeader = (props: CardapioHeaderProps) => {

  const [empresa, setarEmpresa] = useState(props.empresa);
  const [editandoNome, setarEditandoNome] = useState(false);
  const [editandoInformacao, setarEditandoInformacao] = useState(false);
  

  const mostrarConfirmacao = useMemo(
    () => props.atualizando === false && 
            (
              editandoNome === true ||
              editandoInformacao === true ||
              isEqual(empresa, props.empresa) === false
            ),
    [empresa, props.empresa, props.atualizando,editandoInformacao,editandoNome],
  );

  const carregando = isEmpty(empresa) || isNil(empresa);
  const somenteLeitura = (props.somenteLeitura === false && mostrarConfirmacao === false) === false;
  const mostrarDados = props.atualizando === false && carregando === false;

  useEffect(() => {
    setarEmpresa(props.empresa);
  }, [
    props.empresa,
    props.empresa?.capa,
    props.empresa?.informacao,
    props.empresa?.logo,
    props.empresa?.nome,
  ]);


  const quandoOsDadosMudarem = useCallback(async ({ capa, logo }: any) => {
    setarEmpresa((prev: any) => ({
      ...prev,
      capa: capa ? capa : prev.capa,
      logo: logo ? logo : prev.logo,
    }));
  }, []);

  const quandoEdicaoNomeForIniciada = useCallback(() => {
    setarEditandoNome(true);
    setarEmpresa((prev: any) => ({
      ...prev,
    }));
  }, []);

  const quandoEdicaoInformacoesIniciada = useCallback(() => {
    setarEditandoInformacao(true);
    setarEmpresa((prev: any) => ({
      ...prev,
    }));
  }, []);

  const quandoNomeEmpresaForAlterada = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setarEmpresa((prev: any) => ({
      ...prev,
      nome: event.target.value,
    }));
  }, []);

  const quandoInformacoesForAlterada = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setarEmpresa((prev: any) => ({
      ...prev,
      informacao: event.target.value,
    }));
  }, []);

  const quandoCancelado = useCallback(() => {
    setarEmpresa(props.empresa);
    setarEditandoInformacao(false);
    setarEditandoNome(false);
  }, [props.empresa]);

  const quandoAplicado = useCallback(async () => {
    setarEditandoInformacao(false);
    setarEditandoNome(false);
    if (props.solicitarAtualizacao) {
      props.solicitarAtualizacao(empresa);
    }
  }, [empresa, props]);

  return {
    operacoes: {
      quandoOsDadosMudarem,
      quandoEdicaoNomeForIniciada,
      quandoEdicaoInformacoesIniciada,
      quandoInformacoesForAlterada,
      quandoNomeEmpresaForAlterada,
      quandoCancelado,
      quandoAplicado,
      // logout,
      // navegarParaEdicao,
    },
    dados: {
      empresa,
      carregando,
      mostrarConfirmacao,
      editandoNome,
      editandoInformacao,
      somenteLeitura,
      mostrarDados,
    },
  };
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header:{
    width:'100%',
    maxWidth:'100%',
    padding:0,
  },
  divider: {
    marginTop: '70px',
    marginBottom: '50px',
    '@media (max-width: 380px)': {
      marginTop: '120px',
      marginBottom: '30px',
    },
  },
  pesquisa: {
    marginBottom: '200px',
  },
  sessao: {
    marginTop: '60px',
    marginBottom: '50px',
  },
}));

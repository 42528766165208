import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  block: {
    marginTop: '15px',
  },
  logo: {
    margin: 0,
    marginBottom: 20,
  },
  actions: {
    position: 'absolute',
    display: 'inline-flex',
    right: 30,
    bottom: 20,
    zIndex: 2,
  },
  action: {
    margin: '0 5px',
    textTransform: 'none',
  },
  busca: {
    marginTop: 30,
  },
  btnEntrar: ({ usuarioInvalido }: any) =>
    usuarioInvalido
      ? {
          position: 'absolute',
          right: 20,
          top: 20,
        }
      : {
          position: 'absolute',
          left: 20,
          top: 20,
         
        },
  btnDeslogar: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
}));

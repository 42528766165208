import React, { useCallback } from 'react';
import {
  Grid,
  Paper,
  Typography,
 Divider,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import { ColorPicker, useEmpresaTema } from 'componentes';
import { useFormik } from 'formik';
import { EditarTemaFormValidation } from './editar-tema-form-validation';
import { EditarTemaFormProps } from './editar-tema-form-props';
import { useStyles } from './editar-tema-form-styles';
import { EditarDadosInstancia } from '../editar-dados';
import classNames from 'classnames';

export const EditarTemaForm = React.forwardRef<EditarDadosInstancia, EditarTemaFormProps>(
  ({ className, alterarTema }: EditarTemaFormProps, ref) => {
    const classes = useStyles();
    const { tema } = useEmpresaTema();
    const theme = useTheme();
    React.useImperativeHandle(ref, () => ({
      submit: triggerSubmit,
    }));
    const {
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      setFieldValue,
    } = useFormik({
      enableReinitialize: true,
      validationSchema: EditarTemaFormValidation,
      initialValues: {
        type: tema?.type,
        primaryMain: tema?.primaryMain,
        primaryLight: tema?.primaryLight,
        primaryDark: tema?.primaryDark,
        primaryContrast: tema?.primaryContrast,
        secondaryMain: tema?.secondaryMain,
        secondaryLight: tema?.secondaryLight,
        secondaryDark: tema?.secondaryDark,
        secondaryContrast: tema?.secondaryContrast,
        textPrimary: tema?.textPrimary,
        textSecondary: tema?.textSecondary,
      },
      onSubmit: alterarTema as any,
    });

    const quandoCorMainForAlterada = useCallback(
      (tipo: string) => (cor?: string) => {
        const cores = theme.palette.augmentColor({
          '500': cor,
        });

        setFieldValue(`${tipo}Main`, cor, true);
        setFieldValue(`${tipo}Light`, cores.light, true);
        setFieldValue(`${tipo}Dark`, cores.dark, true);
        setFieldValue(`${tipo}Contrast`, cores.contrastText, true);
      },
      [setFieldValue, theme],
    );
    const triggerSubmit = () => {
      handleSubmit();
    };
    return (
      <Paper
        className={classNames(className, classes.form)}
        elevation={0}
        component="form"
        onSubmit={handleSubmit as any}
      >
        <Typography variant="h6" className={classes.marginBottom}>
          <strong>Edição do tema</strong>
          <Divider/>
        </Typography>
        <Typography component="p" variant="caption" gutterBottom className={classes.descricao}>
          O Cardapio é composto de 2 Cores Principais sendo elas{' '}
          <strong className={classes.primary}>Primária</strong> e{' '}
          <strong className={classes.secondary}>Secundária</strong> uma cor{' '}
          <strong className={classes.textPrimary}>Principal para textos</strong> e outra cor{' '}
          <strong className={classes.textSecondary}>Secundaria para textos</strong> sendo a primaria
          e secundaria compostas por:
          <br />
          <br />
          1. Cor Principal (Que define a visualização inicial).
          <br />
          2. Cor Clara geralmente mesma tonalidade de cor principal (Para efeitos visuais, etc.).
          <br />
          3. Cor Escura geralmente mesma tonalidade de cor principal (Para efeitos visuais, etc.).
          <br />
          4. Cor de Contraste geralmente usada para textos é uma cor se que destaca entre a cor
          escolhida.
          <br />
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FormControl
              error={Boolean(errors.type && touched.type)}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <InputLabel>Selecionar Modo</InputLabel>
              <Select
                name="type"
                label="Selecionar Modo"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="0">Light (Claro)</MenuItem>
                <MenuItem value="1">Dark (Escuro)</MenuItem>
              </Select>
              {errors.type && touched.type && <FormHelperText>{errors.type}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="primaryMain"
              label="Primária"
              className={classes.picker}
              value={values.primaryMain}
              onChange={quandoCorMainForAlterada('primary')}
              onBlur={handleBlur('primaryMain')}
              error={Boolean(errors.primaryMain && touched.primaryMain)}
              helperText={
                errors.primaryMain && touched.primaryMain ? errors.primaryMain : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="primaryDark"
              label="Primária Escura"
              className={classes.picker}
              value={values.primaryDark}
              onChange={handleChange('primaryDark') as any}
              onBlur={handleBlur('primaryDark')}
              error={Boolean(errors.primaryDark && touched.primaryDark)}
              helperText={
                errors.primaryDark && touched.primaryDark ? errors.primaryDark : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="primaryLight"
              label="Primária Clara"
              className={classes.picker}
              value={values.primaryLight}
              onChange={handleChange('primaryLight') as any}
              onBlur={handleBlur('primaryLight')}
              error={Boolean(errors.primaryLight && touched.primaryLight)}
              helperText={
                errors.primaryLight && touched.primaryLight ? errors.primaryLight : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="primaryContrast"
              label="Contraste Primária"
              className={classes.picker}
              value={values.primaryContrast}
              onChange={handleChange('primaryContrast') as any}
              onBlur={handleBlur('primaryContrast')}
              error={Boolean(errors.primaryContrast && touched.primaryContrast)}
              helperText={
                errors.primaryContrast && touched.primaryContrast
                  ? errors.primaryContrast
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="secondaryMain"
              label="Secundária"
              className={classes.picker}
              value={values.secondaryMain}
              onChange={quandoCorMainForAlterada('secondary')}
              onBlur={handleBlur('secondaryMain')}
              error={Boolean(errors.secondaryMain && touched.secondaryMain)}
              helperText={
                errors.secondaryMain && touched.secondaryMain ? errors.secondaryMain : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="secondaryDark"
              label="Secundária Escura"
              className={classes.picker}
              value={values.secondaryDark}
              onChange={handleChange('secondaryDark') as any}
              onBlur={handleBlur('secondaryDark')}
              error={Boolean(errors.secondaryDark && touched.secondaryDark)}
              helperText={
                errors.secondaryDark && touched.secondaryDark ? errors.secondaryDark : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="secondaryLight"
              label="Secundária Clara"
              className={classes.picker}
              value={values.secondaryLight}
              onChange={handleChange('secondaryLight') as any}
              onBlur={handleBlur('secondaryLight')}
              error={Boolean(errors.secondaryLight && touched.secondaryLight)}
              helperText={
                errors.secondaryLight && touched.secondaryLight ? errors.secondaryLight : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ColorPicker
              name="secondaryContrast"
              label="Contraste Secundária"
              className={classes.picker}
              value={values.secondaryContrast}
              onChange={handleChange('secondaryContrast') as any}
              onBlur={handleBlur('secondaryContrast')}
              error={Boolean(errors.secondaryContrast && touched.secondaryContrast)}
              helperText={
                errors.secondaryContrast && touched.secondaryContrast
                  ? errors.secondaryContrast
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ColorPicker
              name="textPrimary"
              label="Texto Principal"
              className={classes.picker}
              value={values.textPrimary}
              onChange={handleChange('textPrimary') as any}
              onBlur={handleBlur('textPrimary')}
              error={Boolean(errors.textPrimary && touched.textPrimary)}
              helperText={
                errors.textPrimary && touched.textPrimary ? errors.textPrimary : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColorPicker
              name="textSecondary"
              label="Texto Secundário"
              className={classes.picker}
              value={values.textSecondary}
              onChange={handleChange('textSecondary') as any}
              onBlur={handleBlur('textSecondary')}
              error={Boolean(errors.textSecondary && touched.textSecondary)}
              helperText={
                errors.textSecondary && touched.textSecondary ? errors.textSecondary : undefined
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, TextField, MenuItem, makeStyles, Grid } from '@material-ui/core';

import { FormikErrors, getIn, FormikTouched } from 'formik';

export interface Horario {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  change: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  blur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  setValueForm: any;
  values: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
  },
  tabs: {},
  typography: { marginBottom: '.1rem', marginTop: '.1rem', fontWeight: 'normal' },
}));

 const dias = [
    'segundas',
    'tercas',
    'quartas',
    'quintas',
    'sextas',
    'sabados',
    'domingos',
    'feriados',
  ];
export const Horarios = ({ errors, touched, blur, change, setValueForm, values }: Horario) => {
  const classes = useStyles();
  const [saveHorarioState, setSaveHorarioState] = useState(true);
  const horariosRef = useRef({});
 

  const [opcoes, setOpcoes] = useState({
    segundas: 0,
    tercas: 0,
    quartas: 0,
    quintas: 0,
    sextas: 0,
    sabados: 0,
    domingos: 0,
    feriados: 0,
  });
  const Situacoes = [
    {
      value: 1,
      label: 'Aberto',
    },
    {
      value: 0,
      label: 'Fechado',
    },
  ];
  useEffect(() => {
    let opcoesAux = {
      segundas:
        values.cardapioEmpresa?.horarios?.segundas?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.segundas?.fechamento !== null
          ? 1
          : 0,
      tercas:
        values.cardapioEmpresa?.horarios?.tercas?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.tercas?.fechamento !== null
          ? 1
          : 0,
      quartas:
        values.cardapioEmpresa?.horarios?.quartas?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.quartas?.fechamento !== null
          ? 1
          : 0,
      quintas:
        values.cardapioEmpresa?.horarios?.quintas?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.quintas?.fechamento !== null
          ? 1
          : 0,
      sextas:
        values.cardapioEmpresa?.horarios?.sextas?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.sextas?.fechamento !== null
          ? 1
          : 0,
      sabados:
        values.cardapioEmpresa?.horarios?.sabados?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.sabados?.fechamento !== null
          ? 1
          : 0,
      domingos:
        values.cardapioEmpresa?.horarios?.domingos?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.domingos?.fechamento !== null
          ? 1
          : 0,
      feriados:
        values.cardapioEmpresa?.horarios?.feriados?.abertura !== null &&
        values.cardapioEmpresa?.horarios?.feriados?.fechamento !== null
          ? 1
          : 0,
    };
    setOpcoes(opcoesAux);
  }, [values.cardapioEmpresa?.horarios]);

  const handleChange = useCallback((event) => {
    event.target.value = event.target.value === null ? null : `${event.target?.value}:00`;
    change(event);
  }, [change]);
  const handleBlur = useCallback((event, dia, opcao) => {
    let index = dias.findIndex((x) => x === dia);
    if (index <= 7) {
      index++;
      if (
        values?.cardapioEmpresa?.horarios[dias[index]][opcao] === null ||
        values?.cardapioEmpresa?.horarios[dias[index]].abertura === undefined
      ) {
        setValueForm(`cardapioEmpresa.horarios.${dias[index]}.${opcao}`, event.target.value);
      }
    }
    blur(event);
  }, [blur,values?.cardapioEmpresa?.horarios,setValueForm]);
  const handleChangeSituacao = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (saveHorarioState) {
      horariosRef.current = values.cardapioEmpresa?.horarios;
      setSaveHorarioState(false);
    }
    setOpcoes({ ...opcoes, [event.target.name]: parseInt(event.target.value) });
    if (parseInt(event.target.value) === 0) {
      setValueForm(`cardapioEmpresa.horarios.${event.target.name}.abertura`, null);
      setValueForm(`cardapioEmpresa.horarios.${event.target.name}.fechamento`, null);
    } else {
      let abertura;
      let fechamento;
      try {
        abertura = horariosRef.current[event.target.name]['abertura'];
      } catch {
        abertura = '00:00:00';
      }
      try {
        fechamento = horariosRef.current[event.target.name]['fechamento'];
      } catch {
        fechamento = '00:00:00';
      }
      if (abertura === null) {
        abertura = '00:00:00';
      }
      if (fechamento === null) {
        fechamento = '00:00:00';
      }
      setValueForm(`cardapioEmpresa.horarios.${event.target.name}.abertura`, abertura);
      setValueForm(`cardapioEmpresa.horarios.${event.target.name}.fechamento`, fechamento);
    }
  }, [opcoes,saveHorarioState,setValueForm,values.cardapioEmpresa?.horarios]);

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Segunda-feira
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-segunda"
              select
              name="segundas"
              label="Situação"
              value={opcoes.segundas}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              aria-label="segundas"
              label="Abertura"
              disabled={opcoes.segundas === 0}
              name="cardapioEmpresa.horarios.segundas.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'segundas', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.segundas?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.segundas.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.segundas.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.segundas.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.segundas.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.segundas.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.segundas === 0}
              name="cardapioEmpresa.horarios.segundas.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'segundas', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.segundas?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.segundas.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.segundas.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.segundas.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.segundas.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.segundas.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Terça-feira
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-terca"
              select
              name="tercas"
              label="Situação"
              value={opcoes.tercas}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.tercas === 0}
              name="cardapioEmpresa.horarios.tercas.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'tercas', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.tercas?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.tercas.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.tercas.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.tercas.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.tercas.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.tercas.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.tercas === 0}
              name="cardapioEmpresa.horarios.tercas.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'tercas', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.tercas?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.tercas.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.tercas.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.tercas.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.tercas.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.tercas.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Quarta-feira
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-quarta"
              select
              name="quartas"
              label="Situação"
              value={opcoes.quartas}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.quartas === 0}
              name="cardapioEmpresa.horarios.quartas.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'quartas', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.quartas?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.quartas.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.quartas.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.quartas.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.quartas.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.quartas.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.quartas === 0}
              name="cardapioEmpresa.horarios.quartas.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'quartas', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.quartas?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.quartas.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.quartas.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.quartas.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.quartas.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.quartas.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Quinta-feira
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-quinta"
              select
              name="quintas"
              label="Situação"
              value={opcoes.quintas}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.quintas === 0}
              name="cardapioEmpresa.horarios.quintas.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'quintas', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.quintas?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.quintas.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.quintas.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.quintas.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.quintas.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.quintas.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.quintas === 0}
              name="cardapioEmpresa.horarios.quintas.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'quintas', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.quintas?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.quintas.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.quintas.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.quintas.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.quintas.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.quintas.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Sexta-feira
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-sextas"
              select
              name="sextas"
              label="Situação"
              value={opcoes.sextas}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.sextas === 0}
              name="cardapioEmpresa.horarios.sextas.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'sextas', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.sextas?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.sextas.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.sextas.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.sextas.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.sextas.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.sextas.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.sextas === 0}
              name="cardapioEmpresa.horarios.sextas.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'sextas', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.sextas?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.sextas.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.sextas.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.sextas.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.sextas.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.sextas.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Sábado
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-sabado"
              select
              name="sabados"
              label="Situação"
              value={opcoes.sabados}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.sabados === 0}
              name="cardapioEmpresa.horarios.sabados.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'sabados', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.sabados?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.sabados.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.sabados.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.sabados.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.sabados.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.sabados.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.sabados === 0}
              name="cardapioEmpresa.horarios.sabados.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'sabados', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.sabados?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.sabados.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.sabados.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.sabados.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.sabados.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.sabados.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Domingo
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-domingo"
              select
              name="domingos"
              label="Situação"
              value={opcoes.domingos}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.domingos === 0}
              name="cardapioEmpresa.horarios.domingos.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'domingos', 'abertura')}
              value={values.cardapioEmpresa?.horarios?.domingos?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.domingos.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.domingos.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.domingos.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.domingos.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.domingos.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.domingos === 0}
              name="cardapioEmpresa.horarios.domingos.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={(event) => handleBlur(event, 'domingos', 'fechamento')}
              value={values.cardapioEmpresa?.horarios?.domingos?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.domingos.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.domingos.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.domingos.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.domingos.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.domingos.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.tabs}>
        <Typography variant="subtitle1" className={classes.typography}>
          Feriados
        </Typography>
        <Grid spacing={1} container>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              id="select-aberto-feriado"
              select
              name="feriados"
              label="Situação"
              value={opcoes.feriados}
              onChange={handleChangeSituacao}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Abertura"
              disabled={opcoes.feriados === 0}
              name="cardapioEmpresa.horarios.feriados.abertura"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={blur}
              value={values.cardapioEmpresa?.horarios?.feriados?.abertura}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.feriados.abertura') &&
                  getIn(errors, 'cardapioEmpresa.horarios.feriados.abertura'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.feriados.abertura') &&
                getIn(errors, 'cardapioEmpresa.horarios.feriados.abertura')
                  ? getIn(errors, 'cardapioEmpresa.horarios.feriados.abertura')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <TextField
              label="Fechamento"
              disabled={opcoes.feriados === 0}
              name="cardapioEmpresa.horarios.feriados.fechamento"
              onChange={(value) => handleChange(value)}
              type="time"
              onBlur={blur}
              value={values.cardapioEmpresa?.horarios?.feriados?.fechamento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.horarios.feriados.fechamento') &&
                  getIn(errors, 'cardapioEmpresa.horarios.feriados.fechamento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.horarios.feriados.fechamento') &&
                getIn(errors, 'cardapioEmpresa.horarios.feriados.fechamento')
                  ? getIn(errors, 'cardapioEmpresa.horarios.feriados.fechamento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

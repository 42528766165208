import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { CardapioProduto } from 'api/cardapio/models';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSessoesProdutos } from 'api/cardapio/queries';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { isEmpty } from 'lodash';

export interface SalvarProdutoInput {
  empresaId: string;
  id?: string;
  categoriaId: string | null;
  descricao: string;
  infAdic: string | null;
  medida: string;
  valor: number;
  imagem?: string | null;
}

interface APIAtualizarProdutoJSON extends Omit<SalvarProdutoInput, 'empresaId'> { }

class AtualizarProduto implements ApiMutation<SalvarProdutoInput, CardapioProduto> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ empresaId, ...produto }: SalvarProdutoInput) {
    let env: any = {
      descricao: produto.descricao,
      valor: produto.valor,
      infAdic: produto.infAdic ? produto.infAdic : null,
      medida: produto.medida,
      id: produto.id,
      categoriaId: produto.categoriaId ? produto.categoriaId : null,
      imagem: produto.imagem ? produto.imagem : null,
    }

    if (isEmpty(produto.id)) {
      delete env.id;
    }

    return FetchCardapioApiAsMasterUserJSON<APIAtualizarProdutoJSON>(
      `/${empresaId}/produto/simplificado`,
      {
        method: isEmpty(produto.id) ? 'POST' : 'PUT',
        body: JSON.stringify(env as SalvarProdutoInput),
      },
    ).then(
      (json) =>
      ({
        id: json.id,
        descricao: json.infAdic,
        nome: json.descricao,
        imagemUrl: json.imagem,
        medida: json.medida,
        valor: json.valor,
        categoriaId: json.categoriaId,
      } as CardapioProduto),
    );
  }

  updateCache(input: SalvarProdutoInput, retorno: CardapioProduto, cache: QueryCache) {
    const sessoesKey = obterSessoesProdutos.getKey(input.empresaId);
    return cache.invalidateQueries(sessoesKey);
  }
}

export const atualizarProduto = new AtualizarProduto();

export const useAtualizarProduto = (
  config?: MutationConfig<CardapioProduto, unknown, SalvarProdutoInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Produto atualizado com Sucesso.',
    },
    atualizarProduto,
  );

  return useMutation(atualizarProduto.execute, MergeDeep(defaultConfig, config));
};

import React, { useCallback, ChangeEvent } from 'react';
import { TextFieldProps, TextField, InputAdornment } from '@material-ui/core';
import { MergeDeep } from 'utils';

export interface NumberInputProps {
  identificador?: string;
  casasDecimais?: number;
  value?: number | string;
}

export const NumberInput = ({
  casasDecimais,
  identificador,
  onChange,
  value,
  ...props
}: TextFieldProps & NumberInputProps) => {
  const exibirValor = useCallback((valor: string | number | undefined) => {
    return valor ? valor?.toString().replace(/\./gi, ',') : valor;
  }, []);

  const onChangeWrap = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      const retValor = function (value: string, casasDecimais: number) {
        if (!value) return '';

        var numbers = value.match(/\d+/g)?.join('') || '';

        if (numbers.length > casasDecimais) {
          numbers = ((parseFloat(numbers) / Math.pow(10, casasDecimais)).toFixed(casasDecimais).replace('.', ','));
        }

        return numbers;
      };

      onChange(
        MergeDeep(event, {
          target: {
            value: retValor(event.target.value, casasDecimais || 0)
          },
        }) as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      );
    },
    [onChange, casasDecimais],
  );

  return (
    <TextField
      {...props}
      onChange={onChangeWrap}
      InputProps={{
        ...props.InputProps,
        startAdornment: identificador ? (
          <InputAdornment position="start">{identificador}</InputAdornment>
        ) : undefined,
      }}
      value={exibirValor(value)}
    />
  );
};

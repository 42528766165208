import React, { useState, useEffect, useCallback, memo } from 'react';
import { makeStyles, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Modal } from 'componentes';
import { MergedCardapioMaster } from 'api/merged';
import { Botao } from 'componentes';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RoomIcon from '@material-ui/icons/Room';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1.2rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.typography.pxToRem(535),
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(345),
      flexDirection: 'column-reverse',
    },
  },
  horarioContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  btnAberto: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
  },
  btnFechado: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
  },

  informacoes: { width: 'unset' },
  informacoesCarregando: { width: '85%' },
  textoInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  iconInfo: {
    marginRight: '.5rem',
  },
  popper: {
    zIndex: 1,
  },
  item: {
    padding: 0,
  },
  destaque: {
    fontWeight: 'bold',
  },
  listItem: { display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' },
  listPrimary: { marginRight: '1rem', flex: 1 },
  listSecondary: { flex: 1 },
}));

export interface CardapioHeaderInfoProps {
  empresa: MergedCardapioMaster | undefined;
}

export const CardapioHeaderInfoComp = ({ empresa }: CardapioHeaderInfoProps) => {
  const classes = useStyles();
  const [modalHorarioAberto, setAberto] = useState(false);
  const [statusEstabelecimento, setStatusEstabelecimento] = useState(true);
  const [endereco, setEndereco] = useState('');
  const [segmento, setSegmento] = useState('');
  const [carregandoInfo, setCarregandoInfo] = useState(true);
  const [destaque, setDestaque] = useState('');
  const [horarios, setHorarios] = useState({});

  const switchDiaSemana = useCallback((dia) => {
    switch (dia) {
      case 0:
        return 'domingos';
      case 1:
        return 'segundas';
      case 2:
        return 'tercas';
      case 3:
        return 'quartas';
      case 4:
        return 'quintas';
      case 5:
        return 'sextas';
      case 6:
        return 'sabados';
      default:
        return '';
    }
  }, []);
  const getDiaSemana = useCallback((key) => {
    switch (key) {
      case 'domingos':
        return 'Domingo';
      case 'segundas':
        return 'Segunda-feira';
      case 'tercas':
        return 'Terça-feira';
      case 'quartas':
        return 'Quarta-feira';
      case 'quintas':
        return 'Quinta-feira';
      case 'sextas':
        return 'Sexta-feira';
      case 'sabados':
        return 'Sábados';
      case 'feriados':
        return 'Feriados';
      default:
        return '';
    }
  }, []);

  const formatTime = useCallback((horario) => {
    if (horario) {
      if (horario.abertura !== null && horario.fechamento !== null) {
        try {
          return `${horario.abertura.slice(0, 5)}–${horario.fechamento.slice(0, 5)}`;
        } catch {}
      }
    }

    return 'Fechado';
  }, []);

  const showHorarioFuncionamento = useCallback(() => {
    setAberto(true);
  }, []);
  const hideHorarioFuncionamento = useCallback(() => {
    setAberto(false);
  }, []);
  //controle do horário
  useEffect(() => {
    const verificarRestauranteAberto = setInterval(() => {
      let dataAtual = new Date();

      let horaAtual = `${dataAtual
        .getHours()
        .toString()
        .padStart(2, '0')}:${dataAtual
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${dataAtual.getSeconds().toString().padStart(2, '0')}`;

      let horarioDia = empresa?.cardapioEmpresa.horarios[switchDiaSemana(dataAtual.getDay())];

      let isAberto =
        horaAtual > (horarioDia?.abertura || '') &&
        horaAtual < (horarioDia?.fechamento || '') &&
        empresa?.cardapioEmpresa.statusLoja === '1'; //se a loja está aberta, pois podemos fechar por algum motivo, ex algum evento extraordinario

      setStatusEstabelecimento(isAberto);
    }, 1000);
    return () => clearInterval(verificarRestauranteAberto);
  }, [empresa?.cardapioEmpresa.horarios, empresa?.cardapioEmpresa.statusLoja, switchDiaSemana]);

  const capitalize = useCallback((value: any) => {
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);
  const enderecoFormatado = useCallback(() => {
    return `${capitalize(empresa?.empresaMaster?.lgr?.toLowerCase())}, 
    ${capitalize(empresa?.empresaMaster?.nro?.toLowerCase())} - 
    ${capitalize(empresa?.empresaMaster?.bairro?.toLowerCase())}, 
    ${capitalize(empresa?.empresaMaster?.municipio?.toLowerCase())}/${empresa?.empresaMaster?.uf}`;
  }, [
    capitalize,
    empresa?.empresaMaster?.bairro,
    empresa?.empresaMaster?.lgr,
    empresa?.empresaMaster?.municipio,
    empresa?.empresaMaster?.nro,
    empresa?.empresaMaster?.uf,
  ]);

  const sortHorarios = useCallback(
    (horarios) => {
      try {
        let horariosFormatados = {};
        let diaSemana = new Date().getDay();
        for (let i = 0; i < 7; i++) {
          if (diaSemana === 7) {
            diaSemana = 0;
          }
          horariosFormatados[switchDiaSemana(diaSemana)] = horarios[switchDiaSemana(diaSemana)];
          diaSemana++;
        }
        horariosFormatados['feriados'] = horarios['feriados'];
        return horariosFormatados;
      } catch (exception) {
        return horarios;
      }
    },
    [switchDiaSemana],
  );
  //controle endereço
  useEffect(() => {
    if (empresa?.empresaMaster && empresa?.cardapioEmpresa) {
      setEndereco(enderecoFormatado());
      setSegmento(empresa?.cardapioEmpresa?.segmento);
      setHorarios(sortHorarios(empresa?.cardapioEmpresa?.horarios));
      setCarregandoInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa?.empresaMaster, empresa?.cardapioEmpresa]);
  // eslint-enable-next-line react-hooks/exhaustive-deps

  const redirectToMaps = useCallback(() => {
    window.open(`http://maps.google.com/maps?q=${enderecoFormatado().replace(' ', '%20')}`);
  }, [enderecoFormatado]);

  useEffect(() => {
    setDestaque(switchDiaSemana(new Date().getDay()));
  }, [switchDiaSemana]);
  return (
    <div className={classes.root}>
      <div className={carregandoInfo ? classes.informacoesCarregando : classes.informacoes}>
        {carregandoInfo ? (
          <Skeleton />
        ) : (
          <Typography variant="body1" component="h2" gutterBottom>
            <div className={classes.textoInfo}>
              <div className={classes.iconInfo}>
                <RestaurantIcon />
              </div>
              {segmento}
            </div>
          </Typography>
        )}
        {carregandoInfo ? (
          <Skeleton />
        ) : (
          <Typography
            className={classes.pointer}
            variant="body1"
            component="h2"
            onClick={redirectToMaps}
          >
            <div className={classes.textoInfo}>
              <div className={classes.iconInfo}>
                <RoomIcon />
              </div>
              {endereco}
            </div>
          </Typography>
        )}
      </div>

      <div className={classes.horarioContainer}>
        <Botao
          onClick={!carregandoInfo ? showHorarioFuncionamento : undefined}
          className={statusEstabelecimento ? classes.btnAberto : classes.btnFechado}
          size="medium"
          carregando={carregandoInfo}
          startIcon={!carregandoInfo ? <AccessTimeIcon /> : null}
        >
          {!carregandoInfo ? (statusEstabelecimento ? 'Aberto' : 'Fechado') : ''}
        </Botao>

        <Modal
          tamanho="xs"
          aberto={modalHorarioAberto}
          titulo="Horário de Funcionamento"
          quandoForFechado={hideHorarioFuncionamento}
        >
          <div>
            <List component="ul" aria-label="horario" disablePadding>
              {Object.keys(horarios || {}).map((keyName, i) => (
                <div key={i}>
                  {keyName === 'feriados' ? <hr></hr> : null}
                  <ListItem classes={{ root: classes.item }}>
                    <ListItemText
                      classes={{
                        root: classes.listItem,
                        primary:
                          destaque === keyName
                            ? classNames(classes.listPrimary, classes.destaque)
                            : classes.listPrimary,
                        secondary:
                          destaque === keyName
                            ? classNames(classes.listSecondary, classes.destaque)
                            : classes.listSecondary,
                      }}
                      primary={getDiaSemana(keyName)}
                      secondary={formatTime(horarios[keyName])}
                    />
                  </ListItem>
                  {keyName === 'feriados' ? (
                    <Typography variant="body2" color="secondary">
                      obs: não verificamos qual dia é feriado
                    </Typography>
                  ) : null}
                </div>
              ))}
            </List>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export const CardapioHeaderInfo = memo(CardapioHeaderInfoComp);

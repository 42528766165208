import React from 'react';
import { CardActionArea, makeStyles, Typography, Grid, Card } from '@material-ui/core';
import { ItemSacola } from 'storage';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import semFoto from 'arquivos-estaticos/global/imagens/semFoto.png';
const useStyles = makeStyles((theme) => ({
  card: { margin: '.6rem 0rem' },
  item: {
    display: 'flex',
    padding: '.3rem .3rem .3rem .0rem',
  },

  btnsAction: {
    cursor: 'pointer',
  },
  buttonPedir: {
    marginTop: '1rem',
    width: '100%',
  },

  img: {
    flex: '1 0 auto',
    width: '100px',
    height: '100px',
    margin: '10px',
    alignSelf: 'center',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  info: {
    padding: '0rem .3rem',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  infoText: {
    minHeight: '100px',
  },

  iconBtn: { color: theme.palette.text.secondary },
  btn: {
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: 450,
    cursor: 'pointer',
  },
  textosFooter: { fontWeight: 450, fontSize: '1.1rem' },
  textoTotal: {
    marginLeft: '1rem',
    fontWeight: 450,
    fontSize: '1.1rem',
  },
  textWithCrop: {
    wordBreak: 'break-all',
    textAlign: 'justify',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    [theme.breakpoints.down('sm')]: { lineClamp: 2 },
  },
  helperText: {
    position: 'absolute',
    bottom: '3px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '8px',
    left: '20px',
  },
  iconEditar: {
    fontSize: '10px',
    marginRight: '3px',
    color: theme.palette.text.secondary,
  },
}));

export interface itemSacolaModalProps {
  item: ItemSacola;
  quandoEditar: (ItemSacola: ItemSacola) => void;
  quandoRemover: (idProduto: string) => void;
}
export const ItemSacolaCard = ({ item, quandoEditar, quandoRemover }: itemSacolaModalProps) => {
  const classes = useStyles();

  const wrapEditar = React.useCallback(
    (event, item) => {
      event.stopPropagation();
      quandoEditar(item);
    },
    [quandoEditar],
  );
  const wrapRemover = React.useCallback(
    (event, item) => {
      event.stopPropagation();
      quandoRemover(item);
    },
    [quandoRemover],
  );
  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea className={classes.item} onClick={(event) => wrapEditar(event, item)}>
        <div>
          <img
            src={(item.imagemUrl || '').length > 0 ? item.imagemUrl : semFoto}
            className={classes.img}
            alt={item.nome}
          />
          <div className={classes.helperText}>
            <EditIcon className={classes.iconEditar} />
            <Typography variant="body2" style={{ fontSize: '8px' }} color="textSecondary">
              Clique para Editar
            </Typography>
          </div>
        </div>
        <div className={classes.info}>
          <Grid container direction="column" className={classes.infoText}>
            <Grid container item>
              <Typography
                gutterBottom
                variant="h5"
                color="textPrimary"
                style={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  wordBreak: 'break-all',
                  flex: 1,
                }}
              >
                {item.nome}
              </Typography>

              <DeleteIcon
                className={classes.iconBtn}
                onClick={(event) => wrapRemover(event, item.id)}
              />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2" className={classes.textWithCrop}>
                {item.descricao}
              </Typography>
            </Grid>
            {item.observacao.length > 0 ? (
              <Grid style={{ marginTop: '.3rem' }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="justify"
                  className={classes.textWithCrop}
                  gutterBottom
                >
                  Obs.: {item.observacao}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid container justify="flex-end" alignItems="center">
            <Typography variant="h5" className={classes.textosFooter} color="textPrimary">
              {item.quantidade} {item.medida} <span style={{ marginLeft: '5px' }}>x</span>
            </Typography>
            <Typography variant="h5" className={classes.textoTotal} color="primary">
              {item.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Typography>
          </Grid>
        </div>
      </CardActionArea>
    </Card>
  );
};

import { PerfilUsuario } from 'api/master/models';
import React from 'react';
import { UsuarioMaster } from './usuario-master';

interface UsuarioContextValue {
  usuario?: UsuarioMaster;
  logar: (token?: string | null, perfil?: PerfilUsuario | null) => boolean;
  deslogarUsuario: () => void;
}

const UsuarioContext = React.createContext<UsuarioContextValue>({
  usuario: undefined,
  logar: (token?: string | null | undefined, perfil?: PerfilUsuario | null) => false,
  deslogarUsuario: () => { },
});

export interface UsuarioProviderProps {
  children: React.ReactNode;
}

export const useUsuarioLogado = () => {
  return React.useContext(UsuarioContext);
};

export const UsuarioProvider = ({ children }: UsuarioProviderProps) => {
  var r = UsuarioMaster.fromLocalStorageToken();
  const [usuario, setarUsuario] = React.useState(r);
  console.log(usuario)
  const deslogarUsuario = React.useCallback(() => {
    setarUsuario(UsuarioMaster.logout());
  }, []);

  const logar = React.useCallback((token?: string | null, perfil?: PerfilUsuario | null) => {
    const resultado = UsuarioMaster.persistFromToken(token, perfil);
    setarUsuario(resultado);
    return resultado.invalido() === false;
  }, []);

  return (
    <UsuarioContext.Provider
      value={{
        usuario,
        deslogarUsuario,
        logar,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

import { UsuarioMaster } from 'componentes';
import { FetchMasterApiWithUserJSON } from '../utils';
import { EmpresaMaster } from '../models';


import { ApiQuery } from 'api/interfaces';

import { useQuery, QueryConfig } from 'react-query';

class ObterDadosEmpresaMaster implements ApiQuery<string, EmpresaMaster> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(empresaId: string): any[] {
    return ['obterDadosEmpresaMaster', empresaId];
  }

  execute(_: any, empresaId: string) {
    var token = UsuarioMaster.fromLocalStorageToken()?.token || '';
    return FetchMasterApiWithUserJSON<EmpresaMaster>(token, `/empresa/${empresaId}`,{},empresaId);

  }
}

export const obterDadosEmpresaMaster = new ObterDadosEmpresaMaster();

export const useObterDadosEmpresaMaster = (props: string, config: QueryConfig<EmpresaMaster>) => {
  return useQuery(obterDadosEmpresaMaster.getKey(props), obterDadosEmpresaMaster.execute, config);
};

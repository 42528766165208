import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'modulos/router';
import { ReactQueryDevtools } from 'react-query-devtools';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { VariaveisAmbiente } from 'ambiente';
import { CapturarErros, Notificacoes, EmpresaTema } from 'componentes';

const queryCache = new QueryCache({
  defaultConfig: {
    mutations: {
      useErrorBoundary: false,
      throwOnError: false,
    },
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <BrowserRouter>
        <CapturarErros>
          <EmpresaTema>
            <Notificacoes>
              {VariaveisAmbiente.isDev && <ReactQueryDevtools initialIsOpen={false} />}
              <Router />
            </Notificacoes>
          </EmpresaTema>
        </CapturarErros>
      </BrowserRouter>
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import React, { useCallback, useMemo } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useObterMedidaProduto } from 'api/cardapio/queries';
import { SaurusError } from 'erros/saurus-error';
import { isEmpty } from 'lodash';

export interface ProdutoMedidaAutocompleteProps {
  name?: string;
  value?: string;
  isVisible?: boolean;
  label?: string;
  placeholder?: string;
  empresaId: string;
  error?: boolean;
  helperText?: string | undefined;
  multiplo?: boolean;
  onChange?: (medida?: string) => any;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;
}

const filter = createFilterOptions<{
  nome: string;
  inputValue: string | undefined;
}>();

export const ProdutoMedidaAutocomplete = ({
  label,
  isVisible,
  placeholder,
  error,
  helperText,
  onChange,
  name,
  value,
  onBlur,
  multiplo,
  empresaId,
}: ProdutoMedidaAutocompleteProps) => {
  const { data: medidasApi, isLoading: carregandoMedidas, refetch } = useObterMedidaProduto(
    {
      empresaId,
      medida: '',
    },
    {
      enabled: Boolean(empresaId) && isVisible,
      useErrorBoundary: false,
      onError: (e) => {
        if (e instanceof SaurusError) {
        } else {
          setTimeout(() => {
            refetch()
          }, 5000);
        }
      },
    },
  );

  const medidas = useMemo(
    () =>
      medidasApi?.map((medida) => ({
        nome: medida,
        inputValue: undefined as string | undefined,
      })),
    [medidasApi],
  );

  const onChangeWrap = useCallback(
    (event: any, value: any) => {
      if (!onChange) {
        return;
      }

      if (typeof value === 'string') {
        onChange(value || '');
      } else if (value && value.inputValue) {
        onChange(value.inputValue || '');
      } else if (value && value.nome) {
        onChange(value?.nome);
      } else if(value===null){
        onChange('');
      }else {
        onChange(value);
      }
    },
    [onChange],
  );

  const onInputChange = useCallback((event, value) => {
    onChangeWrap(event, value);
  }, [onChangeWrap]);

  const getOptionLbl = useCallback((medida) => {
    if (typeof medida === 'string') {
      return medida;
    }

    if (medida.inputValue) {
      return medida.inputValue;
    }
    if (medida.nome)
      return medida.nome;

    return '';
  }, []);

  return (
    <Autocomplete
      autoComplete={false}
      clearOnEscape
           multiple={multiplo}
      options={medidas || []}
      loading={carregandoMedidas}
      filterOptions={(options, params) => {
        try {
          const valor = (isEmpty(params.inputValue) ? value : params.inputValue) || '';
          const filtered = filter(options, { inputValue: valor, getOptionLabel: getOptionLbl });

          if (valor !== '' && filtered.length <= 0) {
            filtered.push({
              nome: `Nova Medida "${valor}"`,
              inputValue: valor,
            });
          }

          return filtered;
        } catch {
          return options;
        }
      }}
      openOnFocus
      onChange={onChangeWrap}
      onInputChange={onInputChange}
      loadingText="Buscando medidas..."
      getOptionLabel={getOptionLbl}
      renderOption={(option) => option.nome}
      inputValue={value || ''}
      value={{ nome: value, inputValue: undefined } as any}
      clearOnBlur
      selectOnFocus
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant="outlined"
          size="small"
          margin="dense"
          onBlur={onBlur}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {carregandoMedidas ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

import React, { MouseEvent, ChangeEvent } from 'react';
import { TextField, InputAdornment, Button, makeStyles, withStyles } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import classNames from 'classnames';
export interface CardapioHeaderBuscaProps {
  termoPesquisa?: string;
  className?: string;
  quandoTermoForDigitado?: (event: ChangeEvent<HTMLInputElement>) => any;
  pesquisarClick?: (event: MouseEvent<HTMLButtonElement>) => any;
  referencia?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    width: '450px',
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '260px',
    },
    marginRight: -2,
  },
  textFieldArea: {
    fieldset: {
      background: '#F00',
    },
  },
  button: {
    marginLeft: '0px',
    borderRadius: '0px 5px 5px 0px',
    padding: '10px 22px',
  },
  controleBusca: {
    height: 56,
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& input::placeholder': {
        color: theme.palette.text.primary,
        opacity: 0.8,
      },
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  }, 
}))(TextField);

export const CardapioHeaderBusca = ({
  termoPesquisa,
  pesquisarClick,
  quandoTermoForDigitado,
  className,
  referencia
}: CardapioHeaderBuscaProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <CssTextField
        ref={referencia}
        placeholder="Buscar um Produto"
        size="medium"
        margin="none"
        onChange={quandoTermoForDigitado}
        value={termoPesquisa}
        className={[classes.controleBusca, classes.textField].join(' ')}
        InputProps={{
          className: classes.textFieldArea,
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        type="button"
        size="large"
        variant="contained"
        color="primary"
        className={[classes.controleBusca, classes.button].join(' ')}
        onClick={pesquisarClick}
      >
        Buscar
      </Button>
    </div>
  );
};

import { MergeDeep } from 'utils';
import { FetchCardapioApi } from './fetch-cardapio-api';
import { UsuarioMaster } from 'componentes';

export const FetchCardapioApiAsMasterUser = (
  path: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {
  const usuario = UsuarioMaster.fromLocalStorageToken();
  const Default: RequestInit = {
    headers: {
      Authorization: `Bearer ${usuario?.token}`,
    },
  };

  return FetchCardapioApi(path, MergeDeep(Default, options));
};

export const FetchCardapioApiAsMasterUserJSON = <Tipo = any>(
  path: RequestInfo,
  options?: RequestInit,
) => {
  return FetchCardapioApiAsMasterUser(path, options).then(
    (response) => response.json() as Promise<Tipo>,
  );
};

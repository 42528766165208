import React from 'react';
import { CapturarErros, ConfirmProvider, UsuarioProvider } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';
import { LoginRoute } from './componentes';
import { PedidosProvider } from 'componentes/providers/pedidos-provider';

export const CardapioRouter = () => {
  return (
    <UsuarioProvider>
      <PedidosProvider>
        <CapturarErros>
          <ConfirmProvider>
            <LoginRoute titulo={VariaveisAmbiente.appName + " - Validando Cardápio"} />
          </ConfirmProvider>
        </CapturarErros>
      </PedidosProvider>
    </UsuarioProvider>
  );
};

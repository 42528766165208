import React, { useState, useRef, useCallback, Fragment } from 'react';
import { TextField, Popover, TextFieldProps, makeStyles } from '@material-ui/core';
import { ChromePicker, ColorResult } from 'react-color';
import ColorLensRoundedIcon from '@material-ui/icons/ColorLensRounded';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

type ColorPickerProps = {
  onChange?: (color?: string) => any;
};

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '30px',
    bottom: '0px',
  },
  labelShrinked: {
    right: '10px',
  },
}));

export const ColorPicker = (props: Omit<TextFieldProps, 'onChange'> & ColorPickerProps) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const classes = useStyles();
  const { value, onChange } = props;
  const pickerId = uuidv4();

  const closePicker = useCallback(() => {
    setPickerOpen(false);
  }, []);

  const onInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const nextElement = event.nativeEvent.relatedTarget as any;

      if (nextElement?.id !== pickerId) {
        setPickerOpen(false);
      }
    },
    [pickerId],
  );

  const openPicker = useCallback(() => {
    setPickerOpen(true);
  }, []);

  const handleChromePickerChange = useCallback(
    (color: ColorResult) => {
      if (onChange) {
        onChange(color.hex);
      }
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  return (
    <Fragment>
      <TextField
        {...props}
        inputRef={textFieldRef}
        value={value}
        onChange={handleInputChange}
        onFocus={openPicker}
        onBlur={onInputBlur}
        InputLabelProps={{
          classes: {
            root: classes.root,
            shrink: classes.labelShrinked,
          },
        }}
        InputProps={{
          endAdornment: (
            <ColorLensRoundedIcon style={{ color: value ? (value as string) : 'inherit' }} />
          ),
        }}
      />
      <Popover
        PaperProps={{
          id: pickerId,
        }}
        open={pickerOpen}
        anchorEl={textFieldRef.current}
        onClose={closePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <ChromePicker
          onChange={handleChromePickerChange}
          color={value as string}
          disableAlpha={true}
        />
      </Popover>
    </Fragment>
  );
};

import React from 'react';
import { Grid, Paper, makeStyles, TextField } from '@material-ui/core';
import { InputComMascara } from 'componentes';
import { useFormik } from 'formik';
import { DadosDelivery, EnumOpcoesDelivery } from 'storage/delivery/models';
import { DeliveryDadosFormRetiradaValidation } from './delivery-dados-retirada-validation';
import { DeliveryDadosFormClienteValidation } from './delivery-dados-cliente-validation';
import { DeliveryDadosInstancia } from './delivery-dados-instancia';
const useStyles = makeStyles((theme) => ({
  form: {},
}));

export interface DeliveryDadosClientFormProps {
  dadosDelivery: DadosDelivery | undefined;
  modo: EnumOpcoesDelivery;
}

export const DeliveryDadosClienteForm = React.forwardRef<
  DeliveryDadosInstancia,
  DeliveryDadosClientFormProps
>(({ dadosDelivery, modo }: DeliveryDadosClientFormProps, ref) => {
  const classes = useStyles();
  

  React.useImperativeHandle(ref, () => ({
    submit: triggerSubmit,
  }));
  const { errors, touched, handleSubmit, handleChange, handleBlur, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      nome: dadosDelivery?.nome,
      documento: dadosDelivery?.documento,
      telefone: dadosDelivery?.telefone,
    },
    validationSchema:
      modo === EnumOpcoesDelivery.Retirada
        ? DeliveryDadosFormRetiradaValidation
        : DeliveryDadosFormClienteValidation,
    onSubmit: () => {},
  });

  const triggerSubmit = () => {
    handleSubmit();
    if (Object.entries(errors).length > 0 || values.nome === undefined) {
      return { sucesso: false, data: undefined, erros: errors };
    }
    return { sucesso: true, data: values, erros: undefined };
  };

  return (
    <Paper className={classes.form} elevation={0} component="form">
      <Grid spacing={1} container>
        <Grid item xs={12} sm={5}>
          <TextField
         
            label="Nome"
            placeholder="Nome"
            name="nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nome}
            error={Boolean(touched.nome && errors.nome)}
            helperText={touched.nome && errors.nome ? errors.nome : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputComMascara
            label={`Documento${modo === EnumOpcoesDelivery.Entrega ? ' (opcional)' : ''}`}
            placeholder="Documento"
            name="documento"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.documento}
            error={Boolean(touched.documento && errors.documento)}
            helperText={touched.documento && errors.documento ? errors.documento : undefined}
            InputLabelProps={{ shrink: true }}
            mascara="999.999.999-99"
            guiarUsuario={false}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputComMascara
            label={`Telefone${modo === EnumOpcoesDelivery.Entrega ? ' (opcional)' : ''}`}
            name="telefone"
            placeholder="DDD + Telefone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.telefone}
            error={Boolean(touched.telefone && errors.telefone)}
            helperText={touched.telefone && errors.telefone ? errors.telefone : undefined}
            InputLabelProps={{ shrink: true }}
            mascara="(99) 9999-99999"
            guiarUsuario={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
});

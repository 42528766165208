import parse from 'html-react-parser';
import { files } from 'arquivos-estaticos';
import { IResources } from './interface-resources';
/* eslint-disable no-eval */
const resMenuVip = () => {
  const fileReader = files();
  const getObject = () => {
    return {
      nome: 'MenuVip',
      favIcon: fileReader.loadLogo('menuVip', 'icon.png'),
      paginas: {
        paginaSimples: {
          logo: {
            src: fileReader.loadLogo('menuVip'.toString(), 'logo.png'),
            alt: 'Menu Vip Logo',
          },
        },
        novoCadastro: {
          titulo: 'Novo por aqui?',
          subtitulo: parse(`Olá bem vindo ao <strong>MenuVip!</strong>`),
          primeiroParagrafo: parse(`Para acessar o cardápio do estabelecimento que você está,
              <strong>solicite o QRCode</strong> para visualizar os produtos e serviços disponíveis.`),
          segundoParagrafo: `Se você tem um estabelecimento e gostaria de um cardápio digital para oferecer aos seus
        clientes, clique abaixo para iniciar sua experiência.`,
          textoBotao: `Quero um Cardápio!`,
        },
        cardapioFooter: {
          tituloFooter: (nomeRestaurante) => nomeRestaurante,

          textoFooter:
            'MenuVIP - Um produto Saurus Software © ' +
            eval('new Date().getFullYear()') +
            ' - Todos os direitos reservados.',
        },
      },
    } as IResources;
  };
  return { getObject };
};
export const menuVip = resMenuVip();
/* eslint-enable no-eval */

import React, { useCallback, useEffect, useState } from 'react';
import { GetAuthRedirect } from 'utils';
import { useQueryString } from 'hooks';
import { Carregando } from 'componentes/carregando';
import { useUsuarioLogado } from 'componentes/providers/usuario-provider';
import { Route, RouteProps } from 'componentes/route';
import { Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useEmpresaTema } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';
import { NaoEncontradoPaginaHOC } from 'modulos';
import { CardapioEdicao,CardapioConfiguracoes, CardapioHome, } from 'modulos/cardapio/paginas';
import { isEmpty } from 'lodash';
import { NotificationError } from 'erros';
import { ObterPerfilUsuario } from 'api/master';

export const LoginRoute = (props: RouteProps) => {
  const editarRoute: string = '/editar';
  const configuracoesRoute: string = '/configuracoes';

  const { usuario, logar, deslogarUsuario } = useUsuarioLogado();
  const usuarioValido = usuario?.invalido() === false;
  const { atualizarEmpresa, empresaLocalizada, carregandoEmpresa } = useEmpresaTema();
  const { empresaId } = useParams<{ empresaId: string }>();

  const { path: routeParams } = useRouteMatch();
  const history = useHistory();

  const [carregandoTitulo, setarTitulo] = useState<string | undefined>(
    usuarioValido ? undefined : 'Verificando seu usuário',
  );
  const [carregandoMsg, setarMsg] = useState<string | undefined>(
    usuarioValido ? undefined : 'Aguarde enquanto verificamos seu usuário!',
  );

  const query = useQueryString();
  const [carregandoInterno, setarCarregandoInterno] = useState<boolean>(false);

  const validarRotaEdicao = useCallback(() => {
    //SE O USUARIO ESTÁ AUTENTICADO
    if (usuarioValido) {
      //VERIFICO SE A EMPRESA QUE O CARA ACESSOU É DO USUARIO QUE ESTÁ LOGADO
      if (usuario?.perfil?.perfis[0].empresaId === empresaId) {
        return;
      }
      deslogarUsuario();
    }

    setarTitulo('Redirecionando...');
    setarMsg('Aguarde enquanto te redirecionamos para realizar o Login.');
    setarCarregandoInterno(true);

    const timeoutId = setTimeout(() => {
      window.location.assign(GetAuthRedirect(getRota()));
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [usuarioValido, empresaId, deslogarUsuario, usuario]);

  const getRota=()=>{
  if(  window.location.href.indexOf(configuracoesRoute) > -1){ return 'configuracoes'};

  return 'editar';
  }
  const validarRotaLogin = useCallback(() => {
    const paramToken = query.get('token');
    const paramEmpresa = query.get('empresaId');
    const cancelouLogin = query.get('loginCancelado');
    const rota = query.get('rota');

    //SE VEIO UM TOKEN ERRADO E NAO CANCELOU O LOGIN
    if (isEmpty(paramToken) && isEmpty(cancelouLogin))
      throw new NotificationError(
        'error',
        'Dados de Autorização não foram Encontrados',
        'Login Inválido',
        "O parametro 'token' não foi identificado.",
        false,
      );

    //SE CANCELOU O LOGIN
    if (isEmpty(paramToken) && !isEmpty(cancelouLogin)) {
      const ultimaEmpresa = sessionStorage.getItem('ultimoEmpresaId');
      if (isEmpty(ultimaEmpresa))
        throw new NotificationError(
          'error',
          'Acesse novamente a empresa para continuar.',
          'Login Cancelado',
          '',
          false,
        );

      const url = new URL(window.location.href);
      window.location.href = url.origin + '/' + ultimaEmpresa;
    }

    setarTitulo('Autenticando...');
    setarMsg('Autenticando os dados do usuário retornado.');
    setarCarregandoInterno(true);

    ObterPerfilUsuario(paramToken || '')
      .then((obj) => {
        if (isEmpty(obj?.perfis) || obj?.perfis?.length === 0) {
          throw new NotificationError(
            'error',
            'Dados de Autorização não foram Encontrados',
            'Login Inválido',
            "O objeto 'Perfil' não foi identificado.",
            false,
          );
        }
        //REALIZO O LOGIN NO PROVIDER
        logar(paramToken, obj);

        let empresaId = obj.perfis[0].empresaId;
        if (!isEmpty(paramEmpresa)) {
          if ((obj.perfis.filter((x) => x.empresaId === empresaId) || []).length > 0) {
            empresaId = paramEmpresa || empresaId;
          }
        }

        const url = new URL(window.location.href);
        window.location.href = url.origin + '/' + empresaId +(rota!==null?`/${rota}`:'');
        setarCarregandoInterno(false);
      })
      .catch((e) => {
        throw new NotificationError(
          'error',
          'Dados de Autorização não foram Encontrados',
          'Login Inválido',
          e.message,
          false,
        );
      });
  }, [logar, query]);

  //CARREGAMENTO DO COMPONENTE
  useEffect(() => {
   
    //VALIDO SE VEIO COM PARAMETROS DE LOGIN
    if (empresaId === 'login') return validarRotaLogin();

    setarTitulo('Carregando Cardápio...');
    setarMsg('Por favor aguarde um momento enquanto localizamos o cardápio do estabelecimento.');

    if (atualizarEmpresa) atualizarEmpresa(empresaId);
    
    if (!empresaLocalizada) return;

    sessionStorage.setItem('ultimoEmpresaId', empresaId);

    //SE ESTÁ NA ROTA DE EDIÇÃO, TEMOS QUE BATER A EMPRESA
    if (window.location.href.indexOf(editarRoute) > -1||window.location.href.indexOf(configuracoesRoute) > -1) {
      return validarRotaEdicao();
    }
  }, [atualizarEmpresa, empresaId, empresaLocalizada, validarRotaEdicao, validarRotaLogin]);

  const voltar = React.useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace(`/${routeParams}`);
    }
  }, [history, routeParams]);

  // if (true) {
  //   return (
  //     <Carregando
  //       titulo={carregandoTitulo}
  //       mensagem={carregandoMsg}
  //       modelo="fixed"
  //       carregando={true}
  //     />
  //   );
  // }

  return carregandoInterno || carregandoEmpresa ? (
    <Carregando
      titulo={carregandoTitulo}
      mensagem={carregandoMsg}
      modelo="fixed"
      carregando={true}
    />
  ) : (
    <Switch>
      {empresaLocalizada === false && (
        <Route
          titulo={VariaveisAmbiente.appName + ' - Cardápio não Encontrado!'}
          component={NaoEncontradoPaginaHOC({
            acaoText: 'Voltar à Página Anterior',
            acaoFn: voltar,
          })}
        />
      )}
      {empresaLocalizada === true && (
        <Switch>
          <Route
            exact
            path={`${routeParams}`}
            titulo={VariaveisAmbiente.appName}
            component={CardapioHome}
            
          />
          <Route
            exact
            path={`${routeParams}${editarRoute}`}
            titulo={`${VariaveisAmbiente.appName} - Edição`}
            component={CardapioEdicao}
          />    <Route
            exact
            path={`${routeParams}${configuracoesRoute}`}
            titulo={`${VariaveisAmbiente.appName} - Configurações`}
            component={CardapioConfiguracoes}
          />
        </Switch>
      )}
    </Switch>
  );
};

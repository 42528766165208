import parse from 'html-react-parser';
import { files } from 'arquivos-estaticos';
import {IResources} from './interface-resources'
/* eslint-disable no-eval */
const resCardapioSaurus = () => {
  const fileReader = files();
  const getObject = () => {
    return {
      nome: 'Cardapio Saurus',
      favIcon: fileReader.loadLogo('cardapioSaurus', 'icon.png'),
      paginas: {
        paginaSimples: {
          logo: {
            src: fileReader.loadLogo('cardapioSaurus', 'logo.png'),
            alt: 'Cardápio Saurus Logo',
          },
        },
        novoCadastro: {
          titulo: 'Novo por aqui?',
          subtitulo: parse(`Olá bem vindo ao <strong>Cardapio Saurus!</strong>`),
          primeiroParagrafo: parse(`Para acessar o cardápio do estabelecimento que você está,
                      <strong>solicite o QRCode</strong> para visualizar os produtos e serviços disponíveis.`),
          segundoParagrafo: `Se você tem um estabelecimento e gostaria de um cardápio digital para oferecer aos seus
                clientes, clique abaixo para iniciar sua experiência.`,
          textoBotao: `Quero um Cardápio!`,
        },
        cardapioFooter: {
          tituloFooter: (nomeRestaurante) => nomeRestaurante,
          textoFooter:
            'Cardápio Saurus - Um produto Saurus Software © ' +
              eval('new Date().getFullYear()') +
              ' - Todos os direitos reservados.',
          
        },
      },
    }as IResources;
  };

  return { getObject };
};

export const cardapioSaurus = resCardapioSaurus();
/* eslint-enable no-eval */

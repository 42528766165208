import * as Yup from 'yup';
import { ValidarCor } from 'utils';

const ValidacaoCor = Yup.string()
  .required('Você precisa informar uma cor!')
  .test('validarCor', 'Você precisa informar uma cor válida!', ValidarCor);

export const EditarTemaFormValidation = Yup.object().shape({
  type: Yup.number()
    .required('Você precisa informar o tipo!')
    .oneOf([0, 1], 'O tipo pode ser apenas escuro ou claro!'),
  primaryMain: ValidacaoCor,
  primaryLight: ValidacaoCor,
  primaryDark: ValidacaoCor,
  primaryContrast: ValidacaoCor,
  secondaryMain: ValidacaoCor,
  secondaryDark: ValidacaoCor,
  secondaryLight: ValidacaoCor,
  secondaryContrast: ValidacaoCor,
  textPrimary: ValidacaoCor,
  textSecondary: ValidacaoCor,
});

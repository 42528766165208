import React from 'react';
import { Divider } from '@material-ui/core';
import { EmptyState } from 'componentes';
import { isEmpty } from 'lodash';
import { SessoesProps } from './sessoes-props';
import { Sessao } from 'modulos/cardapio/componentes/produtos/sessao';
import { useStyles } from './sessoes-styles';
import { SessoesInstancia } from './sessoes-instancia';
import { useSessoes } from './use-sessoes';
// import { SalvarCategoriaInput } from 'api/cardapio/mutations';
export const Sessoes = React.forwardRef<SessoesInstancia, SessoesProps>((props, ref) => {
  const classes = useStyles();
  const { dados, operacoes } = useSessoes(props);

  const {
    className,
    somenteLeitura,
    sessoes,
    carregando,

    quandoSessaoForVisualizada,
    deletar,
    adicionarProdutoClick,
    onProdutoClick,
    deletarCategoria,
    reativarCategoria,
    desativarCategoria,
    editarCategoria,
  } = props;

  React.useImperativeHandle(ref, () => ({
    pesquisarSessoes: operacoes.pesquisarSessoes,
    rolarParaSessao: operacoes.rolarParaSessao,
    sessaoStateChanged: sessaoStateChanged,
  }));

  const existeSessoes = isEmpty(sessoes) === false;
  const mostrarEmptyState = existeSessoes === false && Boolean(carregando) === false;
  const [filtroSessoes, setFiltroSessoes] = React.useState(Array.from(new Array(1)));

  React.useEffect(() => {
    let sessoesArr = Array.isArray(sessoes)
      ? somenteLeitura
        ? sessoes.filter((x) => x.ativo === true).filter((x) => x.produtos.length > 0)
        : sessoes
      : [];
    setFiltroSessoes(sessoesArr);
  }, [sessoes,somenteLeitura]);

  const sessaoStateChanged = (categoriaId?: string) => {
    let refArray = filtroSessoes;
    let item = refArray.find((x) => x.categoriaId === categoriaId) || { ativo: false };
    item.ativo = !item.ativo;

    setFiltroSessoes(refArray);
  };

  return (
    <div className={className}>
      {mostrarEmptyState && somenteLeitura ? (
        <React.Fragment>
          <Divider className={classes.divider} />
          <EmptyState
            className={classes.emptyState}
            titulo={
              somenteLeitura ? (
                <div>Nenhum produto neste Estabelecimento</div>
              ) : (
                <div>Nenhum produto cadastrado no seu Estabelecimento</div>
              )
            }
            mensagem={
              somenteLeitura ? (
                <div>O responsável ainda não cadastrou os produtos para você consumir."</div>
              ) : (
                <div>
                  <strong
                    onClick={() => adicionarProdutoClick && adicionarProdutoClick(undefined)}
                    className={classes.link}
                  >
                    Clique Aqui
                  </strong>{' '}
                  para incluir um produto novo.
                </div>
              )
            }
          />
        </React.Fragment>
      ) : null}
      {mostrarEmptyState === false &&
        (carregando ? Array.from(new Array(1)) : filtroSessoes)?.map((sessao, index) => (
          <div key={sessao?.categoriaId || index}>
            <Divider className={classes.divider} />
            <div className={classes.sessao}>
              <Sessao
                ref={
                  isEmpty(dados.sessoesRef) === false
                    ? dados.sessoesRef[sessao?.categoriaId]
                    : undefined
                }
                sessaoDeBusca={false}
                somenteLeitura={somenteLeitura}
                carregando={carregando}
                sessao={sessao}
                deletar={deletar}
                quandoForVisualizada={quandoSessaoForVisualizada}
                adicionarProdutoClick={adicionarProdutoClick}
                onProdutoClick={onProdutoClick}
                deletarCategoria={deletarCategoria}
                desativarCategoria={desativarCategoria}
                reativarCategoria={reativarCategoria}
                editarCategoria={editarCategoria}
              />
            </div>
          </div>
        ))}
    </div>
  );
});

import { ObterTokenIntegrador } from 'api/master/obter-token-integrador';
import { MergeDeep } from 'utils';
import { FetchCardapioApi } from './fetch-cardapio-api';

export const FetchCardapioApiAsPublicUser = async (
  empresaId: string,
  path: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {

  const token = await ObterTokenIntegrador(empresaId);
  const optionsDefault: RequestInit = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return FetchCardapioApi(path, MergeDeep(optionsDefault, options));
};

export const FetchCardapioApiAsPublicUserJSON = <Tipo>(
  empresaId: string,
  path: RequestInfo,
  options?: RequestInit,
) => {
  return FetchCardapioApiAsPublicUser(empresaId, path, options).then(
    (response) => response.json() as Promise<Tipo>,
  );
};

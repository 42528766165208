import { PerfilUsuario } from 'api/master/models';
import { isEmpty } from 'lodash';

export interface JWTMasterDados {
  aud: string;
  chaveIntegracao: string;
  contratoId: string;
  exp: number;
  iss: string;
  usuarioEmail: string;
  usuarioId: string;
  usuarioNome: string;
}

const masterToken = 'masterCredentials';

export class UsuarioMaster {
  readonly dados?: JWTMasterDados;

  private constructor(public readonly token?: string | null, public readonly perfil?: PerfilUsuario | null, atualizarStorage: boolean = true) {
    try {
      this.dados = JSON.parse(atob(token!.split('.')[1]));
    } catch (e) {
      this.dados = undefined;
      this.perfil = undefined;
    }

    if (atualizarStorage === true) {
      this.persist();
    }
  }

  estaExpirado(): boolean {
    if (!this.dados?.exp) {
      return true;
    }

    return this.dados?.exp * 1000 <= new Date().getTime();
  }

  invalido(): boolean {
    return isEmpty(this.dados) || isEmpty(this.perfil) || this.estaExpirado();
  }

  invalidoRota(empresaId: string): boolean {
    let ret = this.invalido();
    if(!ret)
      if(empresaId === this.perfil?.perfis[0]?.empresaId)
        return false;
    return true;
  }

  persist(): void {
    return sessionStorage.setItem(masterToken, JSON.stringify({ token: this.token, perfil: this.perfil }));
  }

  static fromLocalStorageToken(): UsuarioMaster {
    let token = '';
    let perfil = null;

    if (sessionStorage.getItem(masterToken)) {
      const reg = JSON.parse(sessionStorage.getItem(masterToken) || '') as any;
      token = reg.token;
      perfil = reg.perfil;
    }

    return new UsuarioMaster(token, perfil, true);
  }

  static logout(): UsuarioMaster {
    return new UsuarioMaster(undefined, undefined);
  }

  static persistFromToken(token?: string | null, perfil?: PerfilUsuario | null): UsuarioMaster {
    return new UsuarioMaster(token, perfil);
  }

  static testar(token?: string | null): boolean {
    return new UsuarioMaster(token, undefined, true).invalido() === false;
  }
}

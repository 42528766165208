import React, { useEffect } from 'react';
import {
  Modal,
  ImagemInput,
  Botao,
  useNotificacoes,
  Carregando,
  ColorPicker,
  NumberInput,
} from 'componentes';
import { EditarCategoriaModalProps } from './editar-categoria-modal-props';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './editar-categoria-modal-styles';
import { useFormik } from 'formik';
import { CardapioCategoria } from 'api/cardapio/models';
import { CategoriaValidacao } from './editar-categoria-modal-validacao';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useObterCategoria } from 'api/cardapio/queries';
import { useConfirm } from 'material-ui-confirm';

export const EditarCategoriaModal = ({
  aberto,
  empresaId,
  categoriaId,
  quandoForFechado,
  atualizandoCategoria,
  deletarCategoria,
  salvarCategoria,
}: EditarCategoriaModalProps) => {
  const classes = useStyles();
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
  const confirm = useConfirm();

  const { showErrorMessage } = useNotificacoes();
  const { data: categoria, isLoading: carregandoCategoria } = useObterCategoria(
    {
      categoriaId: categoriaId || '',
      empresaId,
    },
    {
      enabled:
        Boolean(aberto) && Boolean(categoriaId) && categoriaId !== 'new' && Boolean(empresaId),
      useErrorBoundary: false,
      onError: (error: any) => {
        if (quandoForFechado) quandoForFechado();

        showErrorMessage(
          `Erro ao Localizar a Categoria '${categoriaId}'. Detalhe: ${error.message}`,
        );
      },
    },
  );

  const deleteWrap = React.useCallback(
    (categoria) => {
      if (!deletarCategoria) {
        return;
      }

      confirm({
        dialogProps: {
          open: false,
          style: {
            zIndex: 9999,
          },
        },
        title: 'Deletar Categoria',
        description:
          'Deseja realmente deletar esta categoria? Obs: Certifique-se de que não exista nenhum produto ou subcategoria para continuar.',
      })
        .then(() => {
          setTimeout(async () => {
            Promise.resolve(deletarCategoria(categoria))
              .catch(() => null)
              .then(() => {
                if (quandoForFechado) quandoForFechado();
              });
          }, 500);
        })
        .catch(() => {});
    },
    [deletarCategoria, confirm, quandoForFechado],
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    validationSchema: CategoriaValidacao,
    initialValues: {
      id: categoria?.id,
      categoriaPai: { id: categoria?.paiId || '' } as CardapioCategoria,
      nome: categoria?.descricao || '',
      imagemUrl: categoria?.imagemUrl || '',
      cor: categoria?.cor || '',
      rank: categoria?.rank || 0,
      ativo: categoria ? (categoria?.ativo === true ? 1 : 0) : 1,
    },
    onSubmit: async (categoria) => {
      if (salvarCategoria) {
        await Promise.resolve(
          salvarCategoria({
            id: categoria.id || '',
            empresaId: empresaId,
            imagemUrl: categoria.imagemUrl,
            descricao: categoria.nome,
            paiId: categoria.categoriaPai.id,
            cor: categoria.cor,
            rank: categoria.rank,
            ativo: categoria.ativo === 1,
          }),
        )
          .catch((e) => {
            showErrorMessage('Hove um erro ao salvar a Categoria. Detalhe: ' + e.message);
          })
          .then(() => {
            if (quandoForFechado) quandoForFechado();
          });
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    resetForm();
  }, [categoriaId, resetForm]);

  useEffect(() => {
    if (!aberto) {
      resetForm();
    }
  }, [resetForm, aberto]);

  const showLoading = carregandoCategoria || atualizandoCategoria;
  const carregando = (
    <Carregando
      carregando={showLoading}
      modelo="absolute"
      titulo={atualizandoCategoria ? 'Salvando Categoria' : 'Carregando Categoria'}
      mensagem="Por favor aguarde enquanto carregamos as informações..."
    ></Carregando>
  );

  return (
    <Modal
      tamanho="md"
      aberto={aberto}
      quandoForFechado={quandoForFechado}
      carregando={carregando}
      titulo="Cadastro de Categoria"
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.bloco}>
          <Grid item xs={12} md={7}>
            <TextField
              label="Nome da Categoria"
              placeholder="Ex: Bebidas"
              name="nome"
              value={values.nome}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.nome && touched.nome)}
              helperText={errors.nome && touched.nome ? errors.nome : undefined}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <ColorPicker
              name="cor"
              label="Cor"
              className={classes.picker}
              value={values.cor}
              onChange={handleChange('cor') as any}
              onBlur={handleBlur('cor')}
              error={Boolean(errors.cor && touched.cor)}
              helperText={errors.cor && touched.cor ? errors.cor : undefined}
            />
          </Grid>

          <Grid item xs={6} md={1}>
            <NumberInput
              label="Posição"
              placeholder="0...999"
              identificador=""
              name="rank"
              value={values.rank}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.rank && touched.rank)}
              helperText={errors.rank && touched.rank ? errors.rank : undefined}
            />
          </Grid>

          <Grid item xs={12} md={2} style={!isMobile ? { paddingLeft: '12px' } : {}}>
            <FormControl
              error={Boolean(errors.ativo && touched.ativo)}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <InputLabel>Ativo</InputLabel>
              <Select name="ativo" label="Ativo" value={values.ativo} onChange={handleChange}>
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={0}>Não</MenuItem>
              </Select>
              {errors.ativo && touched.ativo && <FormHelperText>{errors.ativo}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <ImagemInput
              name="imagemUrl"
              value={values.imagemUrl}
              onChange={({ base64 }: any) => {
                setFieldValue('imagemUrl', base64, true);
              }}
              onBlur={() => {
                setFieldTouched('imagemUrl', true, true);
              }}
              error={Boolean(errors.imagemUrl && touched.imagemUrl)}
              helperText={errors.imagemUrl && touched.imagemUrl ? errors.imagemUrl : undefined}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} justify="flex-end">
          <Grid sm={12} md={3} item className={classes.btn}>
            <Botao
              style={{ width: '100%' }}
              color="secondary"
              type="button"
              onClick={() => deleteWrap(categoria)}
              variant="contained"
            >
              <DeleteIcon fontSize="small" style={{ marginRight: 10 }}></DeleteIcon>Deletar
              Categoria
            </Botao>
          </Grid>

          <Grid sm={12} md={3} item className={classes.btn}>
            <Botao style={{ width: '100%' }} color="primary" type="submit" variant="contained">
              <SaveIcon fontSize="small" style={{ marginRight: 10 }}></SaveIcon>Salvar Categoria
            </Botao>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

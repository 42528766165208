import { useCallback, MutableRefObject, useState, ChangeEvent, useEffect } from 'react';
import { SessoesInstancia } from 'modulos/cardapio/componentes/produtos';
import { CategoriaCarroselInstancia } from 'modulos/cardapio/componentes/categorias/categorias-carrosel/categorias-carrosel-instancia';
import { CardapioCategoria, CardapioProduto } from 'api/cardapio/models';
import { SalvarCategoriaInput } from 'api/cardapio/mutations';
import { isEmpty } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { SessaoProduto } from 'api/cardapio/queries';
import { useObterSacola} from 'storage';
import { useHistory } from 'react-router-dom';
import { useUsuarioLogado } from 'componentes';
export const useCardapioLogica = (
  sessoes: MutableRefObject<SessoesInstancia | null>,
  categoria: MutableRefObject<CategoriaCarroselInstancia | null>,
  pesquisa: MutableRefObject<HTMLDivElement | null>,
  menuNavRef: MutableRefObject<HTMLDivElement | null>,
  produtos: SessaoProduto[],
  categorias: CardapioCategoria[],
  operacoesApi: any,
  empresaId: string,
  somenteLeitura: boolean,
) => {
  const history = useHistory();
  const [termoPesquisa, definirTermoPesquisa] = useState('');
  const [resultadoPesquisa, definirResultadoPesquisa] = useState<CardapioProduto[]>([]);
  const [carregandoPesquisa, definirCarregandoPesquisa] = useState(false);
  const [categoriaSendoExibida, definirCategoriaExibida] = useState<string | undefined>(undefined);
  const [produtoId, definirProdutoId] = useState<string | undefined>(undefined);
  const [categoriaSelecionada, definirCategoriaSelecionada] = useState<string | undefined>(
    undefined,
  );
  const [sacola, setSacola] = useState(false);
  const [abriuSacola, setAbriuSacola] = useState(false);
  const pesquisando = isEmpty(termoPesquisa) === false;

  const itensSacola = useObterSacola({ empresaId: empresaId });

  const { deslogarUsuario } = useUsuarioLogado();

  const countSacola = useCallback(() => {
    if (somenteLeitura) {
      if (itensSacola?.data?.length === 1 && !abriuSacola) {
        setSacola(true);
        setAbriuSacola(true);
    
      } else {
        if (itensSacola?.data?.length === 0 && abriuSacola) {
          setAbriuSacola(false);
        }
      }
    }
  }, [itensSacola?.data?.length, somenteLeitura, abriuSacola]);
  useEffect(() => {
    countSacola();
  }, [itensSacola, countSacola]);

  const { ref: header, inView: headerEstaSendoVisualizado } = useInView({
    threshold: 0,
    initialInView: true,
  });

  const abrirAdicaoProduto = useCallback((sessao?: SessaoProduto) => {
    definirProdutoId(undefined);
    definirCategoriaSelecionada(sessao?.categoriaId);
  }, []);

  const fecharEdicaoProduto = useCallback(() => {
    definirProdutoId(undefined);
    definirCategoriaSelecionada(undefined);
  }, []);

  const abrirEdicaoProduto = useCallback((produto?: CardapioProduto) => {
    definirProdutoId(produto?.id);
  }, []);

  const quandoSessaoForExibida = useCallback((sessao?: SessaoProduto) => {
    definirCategoriaExibida(sessao?.categoriaId);
  }, []);

  const categoriaClick = useCallback(
    (categoria?: CardapioCategoria) => {
      sessoes?.current?.rolarParaSessao(categoria?.id || '');
    },
    [sessoes],
  );

  const quandoTermoForDigitado = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      definirTermoPesquisa(event.target.value);
    },
    [],
  );
  const limparTermoPesquisa = useCallback(() => {
    definirTermoPesquisa('');
  }, []);

  const buscarClick = useCallback(() => {
    if (pesquisando === false) {
      return;
    }

    const pesquisaScrollInterval = setInterval(() => {
      if (!pesquisa?.current) {
        return;
      }

      clearInterval(pesquisaScrollInterval);
      // pesquisa.current?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      //   inline: 'nearest',

      // });

      window.scrollTo({
        behavior: 'smooth',
        top: (pesquisa?.current?.offsetTop || 0) - (menuNavRef?.current?.offsetHeight || 0) - 10,
      });
    }, 50);
  }, [pesquisa, pesquisando, menuNavRef]);

  const quandoTermoForDigitadoNoNavbar = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      definirTermoPesquisa(event.target.value);

      setTimeout(() => {
        buscarClick();
      }, 200);
    },
    [buscarClick],
  );

  useEffect(() => {
    definirCarregandoPesquisa(true);

    const timeoutId = setTimeout(() => {
      const resultado = sessoes?.current?.pesquisarSessoes(termoPesquisa);

      if (resultado) {
        definirResultadoPesquisa(resultado);
      }

      definirCarregandoPesquisa(false);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [termoPesquisa, sessoes, produtos]);

  const editarCategoriaWrapper = useCallback(
    (idCategoria: string) => {
      if (categoria?.current) {
        categoria?.current?.editarCategoria(idCategoria);
      }
    },
    [categoria],
  );
  const deletarCategoriaWrapper = useCallback(
    async (idCategoria: string) => {
      let categoria = categorias.find((x) => x.id === idCategoria);
      await operacoesApi.deletarCategoria.executar(categoria);
      operacoesApi.buscarSessoes.executar();
    },
    [categorias, operacoesApi.buscarSessoes, operacoesApi.deletarCategoria],
  );
  const deletarCategoriaFullWrapper = useCallback(
    async (categoria?: CardapioCategoria) => {
      await operacoesApi.deletarCategoria.executar(categoria);
      operacoesApi.buscarSessoes.executar();
    },
    [operacoesApi.buscarSessoes, operacoesApi.deletarCategoria],
  );
  const desativarCategoriaWrapper = useCallback(
    async (idCategoria: string) => {
      let categoria = categorias.find((x) => x.id === idCategoria);
      let retorno = await operacoesApi.desativarCategoria.executar(categoria);
      if (retorno) sessoes?.current?.sessaoStateChanged(idCategoria);
    },
    [sessoes, operacoesApi.desativarCategoria, categorias],
  );
  const reativarCategoriaWrapper = useCallback(
    async (idCategoria: string) => {
      let categoria = categorias.find((x) => x.id === idCategoria);
      let retorno = await operacoesApi.reativarCategoria.executar(categoria);
      if (retorno) sessoes?.current?.sessaoStateChanged(idCategoria);
    },
    [categorias, operacoesApi.reativarCategoria, sessoes],
  );
  const atualizarCategoriaWrapper = useCallback(
    async (categoria: SalvarCategoriaInput) => {
      await operacoesApi.atualizarCategoria.executar(categoria);
      operacoesApi.buscarSessoes.executar();
    },
    [operacoesApi.atualizarCategoria, operacoesApi.buscarSessoes],
  );

  const abrirSacola = useCallback(() => {
    setSacola(true);
  }, []);
  const fecharSacola = useCallback(() => {
    setSacola(false);
  }, []);


  const navegarConfigurando = useCallback(() => {
    history.push(`/${empresaId}/configuracoes`);
  }, [empresaId, history]);
  const navegarParaEdicao = useCallback(() => {
    history.push(`/${empresaId}/editar`);
  }, [empresaId, history]);

  const logout = useCallback(() => {
    history.push(`/${empresaId}`);
    deslogarUsuario();
  }, [history, deslogarUsuario, empresaId]);
  return {
    operacoes: {
      categoriaClick,
      quandoTermoForDigitado,
      buscarClick,
      quandoTermoForDigitadoNoNavbar,
      quandoSessaoForExibida,
      fecharEdicaoProduto,
      abrirEdicaoProduto,
      abrirAdicaoProduto,
      editarCategoriaWrapper,
      deletarCategoriaWrapper,
      deletarCategoriaFullWrapper,
      desativarCategoriaWrapper,
      reativarCategoriaWrapper,
      atualizarCategoriaWrapper,
      abrirSacola,
      fecharSacola,
      configurandoWrapper: navegarConfigurando,
      navegarParaEdicao,
      logout,
      limparTermoPesquisa,
    },
    dados: {
      termoPesquisa,
      carregandoPesquisa,
      resultadoPesquisa,
      pesquisando,
      header,
      headerEstaSendoVisualizado,
      categoriaSendoExibida,
      produtoId: produtoId,
      produtoAberto: Boolean(produtoId) || Boolean(categoriaSelecionada),
      categoriaSelecionada,
      sacola,
    
    },
  };
};

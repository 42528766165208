import React, { useCallback, memo } from 'react';
import { makeStyles, Typography, Dialog } from '@material-ui/core';
import { Botao, useNotificacoes } from 'componentes';

export interface ModalConfirmacaoAdmProps {
  aberto: boolean;
  quandoCancelar: () => any;
  quandoLogar: () => any;
}

const useStyles = makeStyles((theme) => ({
  rootModal: {
    height: '160px',
    [theme.breakpoints.down('sm')]: { height: '105px' },
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  btnsModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  btnModal: {
    fontWeight: 600,

    width: '40%',
  },
  textoModal: {
    fontSize: '1.8rem',
    marginTop: '1rem',
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: { fontSize: '17px',marginTop: 'unset' },
  },
}));
export const ModalConfirmacaoAdmComp = ({
  aberto,
  quandoCancelar,
  quandoLogar,
}: ModalConfirmacaoAdmProps) => {
  const classes = useStyles();

  const { showInfoMessage } = useNotificacoes();

  const cancelarLogin = useCallback(() => {
    showInfoMessage(
      'Por enquanto o login está restrito apenas aos administradores dos cardápios...',
    );
    quandoCancelar();
  }, [quandoCancelar,showInfoMessage]);

  return (
    <>
      <Dialog open={aberto}>
        <div className={classes.rootModal}>
          <Typography className={classes.textoModal}>Você é Administrador do Cardápio?</Typography>
          <div className={classes.btnsModal}>
            <Botao
              className={classes.btnModal}
              color="secondary"
              style={{ marginRight: '1rem' }}
              onClick={cancelarLogin}
            >
              Não
            </Botao>
            <Botao className={classes.btnModal} color="primary" onClick={quandoLogar}>
              Sim
            </Botao>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const ModalConfirmacao = memo(ModalConfirmacaoAdmComp);

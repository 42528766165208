import React from 'react';
import { Typography, Grid, useTheme, useMediaQuery, Button, makeStyles } from '@material-ui/core';
import OlaImg from 'arquivos-estaticos/global/imagens/ola.png';
import { useResources } from 'resources';
import { SimplesPagina } from '../templates';
import { GetAuthRedirect } from 'utils';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    minHeight: '100vh',
  },
  olaImg: {
    objectFit: 'contain',
    width: '100%',
    margin: '0 auto',
  },
  cadastrar: {
    marginTop: '10px',
  },
  imgLogo: {
    height: '100px',
    margin: 'auto',
    display: 'flex',
    marginLeft: '.5rem',
  },
  bemvindo: {
    alignItems: 'flex-end',
    display: 'inline-flex',
  },
}));

export const NovoCadastroPaginaHOC = () => {
  return <NovoCadastroPagina />;
};

export const NovoCadastroPagina = () => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();
  const novoCardapioClick = () => {
    window.location.href = GetAuthRedirect();
  };
  const resources = useResources();

  return (
    <SimplesPagina>
      <Grid
        container
        spacing={2}
        className={classes.gridRoot}
        direction={isMobile ? 'column' : 'row-reverse'}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Grid container justify="center">
            <Grid item xs={6} sm={8}>
              <img className={classes.olaImg} src={OlaImg} alt="Imagem não encontrado" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            align={isMobile ? 'center' : 'left'}
            gutterBottom
          >
            <strong>{resources.paginas.novoCadastro.titulo}</strong>
          </Typography>
          <div className={classes.bemvindo}>
            <Typography variant="h5" align={isMobile ? 'center' : 'left'}>
              {resources.paginas.novoCadastro.subtitulo}
            </Typography>
            {/* <img src={menuVip} alt="Logo MenuVip" className={classes.imgLogo} /> */}
          </div>
          <br />
          <br />
          <Typography variant="subtitle1" align={isMobile ? 'justify' : 'left'}>
            {resources.paginas.novoCadastro.primeiroParagrafo}
          </Typography>
          <br />
          <Typography variant="subtitle1" align={isMobile ? 'justify' : 'left'}>
            {resources.paginas.novoCadastro.segundoParagrafo}
          </Typography>
          <Button
            fullWidth={isMobile}
            onClick={novoCardapioClick}
            className={classes.cadastrar}
            variant="contained"
            color="primary"
            size="large"
          >
            Quero um Cardápio!
          </Button>
        </Grid>
      </Grid>
    </SimplesPagina>
  );
};

import React, { useCallback, useRef, useState, memo } from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import { isEmpty } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { ImagemBlobParaBase64 } from 'utils';
import { BotaoTransparente } from 'componentes';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: '15vh',
    minHeight: '100px',
    margin: 0,
    objectFit: 'contain',
  },
  root: {
    position: 'relative',
  },
  iconButton: {
    zIndex: 1,
    position: 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  icon: {
    marginRight: '5px',
  },
}));

export interface CardapioHeaderLogoProps {
  logoUrl?: string;
  className?: string;
  somenteLeitura: boolean;
  onLogoChange?: (props: { logo: string }) => any;
}

const CardapioHeaderLogoComp = ({
  logoUrl,
  className,
  somenteLeitura,
  onLogoChange,
}: CardapioHeaderLogoProps) => {
  const classes = useStyles();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const existeLogo = Boolean(logoUrl);
  const [fileValue, setFileValue] = useState<any>(null);

  const onImportarClick = useCallback(() => {
    inputFileRef?.current?.click();
  }, []);

  const onLogoCarregado = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event?.target?.files)) {
        return;
      }

      setFileValue(event?.target.value);
      const file = event.target.files![0];
      const logo = await ImagemBlobParaBase64(file);

      if (onLogoChange) {
        onLogoChange({ logo });
      }
    },
    [onLogoChange],
  );

  const exibirIconButton = existeLogo === true && somenteLeitura === false;
  const exibirButton = existeLogo === false && somenteLeitura === false;

  if (existeLogo === false && somenteLeitura) {
    return null;
  }

  return (
    <div className={classNames(className, classes.root)}>
      <input
        ref={inputFileRef}
        multiple={false}
        type="file"
        accept="image/*"
        onChange={onLogoCarregado}
        value={fileValue}
        hidden
      />
      <Fade in={existeLogo} mountOnEnter unmountOnExit>
        <img src={logoUrl} className={classes.logo} alt="" />
      </Fade>
      <Fade in={exibirIconButton} mountOnEnter unmountOnExit>
        <BotaoTransparente
          tipo="iconButton"
          onClick={onImportarClick}
          className={classes.iconButton}
        >
          {existeLogo && <EditIcon fontSize="small" />}
          {!existeLogo && <AddIcon fontSize="small" />}
        </BotaoTransparente>
      </Fade>
      <Fade in={exibirButton} mountOnEnter unmountOnExit>
        <BotaoTransparente tipo="button" onClick={onImportarClick} size="small">
          <AddIcon className={classes.icon} fontSize="small" />
          Adicionar Logotipo
        </BotaoTransparente>
      </Fade>
    </div>
  );
};
export const CardapioHeaderLogo = memo(CardapioHeaderLogoComp);

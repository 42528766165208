import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { CardapioProduto } from 'api/cardapio/models';
import { useQuery, QueryConfig } from 'react-query';

interface APIProdutoVariacaoJSON {
  id: string;
  codigo: string;
  descricao: string;
  valor: number;
  imagemUrl: string;
  medida: string;
}

interface APIProdutoJSON {
  id: string;
  descricao: string;
  infAdic: string;
  vMinimo: number;
  variacao: APIProdutoVariacaoJSON[];
}

interface APISessaoProdutoJSON {
  id: string;
  descricao: string;
  ativo: boolean;
  produtos: APIProdutoJSON[];
}

export interface SessaoProduto {
  categoriaId?: string;
  categoria: string;
  ativo: boolean;
  produtos: CardapioProduto[];
}

class ObterSessoesProdutos implements ApiQuery<string, SessaoProduto[]> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: string): any[] {
    return ['ObterSessoesProdutos', params];
  }

  execute(_: any, empresaId: string): Promise<SessaoProduto[]> {
    return FetchCardapioApiAsPublicUserJSON<APISessaoProdutoJSON[]>(
      empresaId,
      `/${empresaId}/categoria/detalhe-produto`,
    ).then((response) =>
      Array.isArray(response) === false
        ? ([] as SessaoProduto[])
        : response.map((sessao) => ({
          categoriaId: sessao.id || undefined,
          categoria: sessao.descricao || 'Sem Categoria',
          ativo: sessao.ativo || false,
          produtos: sessao?.produtos.map((produto) => ({
            id: produto?.id,
            nome: `${produto?.descricao} ${produto?.variacao[0]?.descricao}`,
            descricao: produto?.infAdic,
            valor: produto?.vMinimo,
            imagemUrl: produto?.variacao[0]?.imagemUrl,
            medida: produto?.variacao[0]?.medida || 'UND',
          })),
        })),
    );
  }
}

export const obterSessoesProdutos = new ObterSessoesProdutos();

export const useObterSessoesProdutos = (
  input: string,
  config: QueryConfig<SessaoProduto[], string>,
) => {
  return useQuery(obterSessoesProdutos.getKey(input), obterSessoesProdutos.execute, config);
};

import React, { useRef } from 'react';
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  Divider,
  MenuItem,
} from '@material-ui/core';
import { InputComMascara, UfSelect, CepInput } from 'componentes';
import { useFormik, getIn } from 'formik';
import { EditarDadosFormValidation } from './edicao-dados-validation';
import { MergedCardapioMaster } from 'api/merged';
import { EditarDadosInstancia } from '../editar-dados';
import { Horarios } from './horario-funcionamento';
import classNames from 'classnames';
const useStyles = makeStyles((theme) => ({
  form: { padding: '.5rem' },
  descricao: {},
  acoes: {
    display: 'flex',
    margin: '10px 0px',
    width: '100%',
    justifyContent: 'flex-end',
  },
  acao: {
    textTransform: 'none',
  },
  typographyMarginTop: {
    marginTop: '1rem',
  },
  typographyMarginBottom: { marginBottom: '1rem' },
}));

const Situacoes = [
  {
    value: '0',
    label: 'Fechada',
  },
  {
    value: '1',
    label: 'Aberta',
  },
];
const opcoes = [
  {
    value: 'false',
    label: 'Não',
  },
  {
    value: 'true',
    label: 'Sim',
  },
];

export interface EditarDadosFormsProps {
  atualizandoDados: boolean;
  empresa: MergedCardapioMaster | undefined;
  salvarDados?: (empresa: MergedCardapioMaster) => any;
}

export const EditarDadosForm = React.forwardRef<EditarDadosInstancia, EditarDadosFormsProps>(
  ({ atualizandoDados, empresa, salvarDados }: EditarDadosFormsProps, ref) => {
    const classes = useStyles();
    const inputNumeroRef = useRef<HTMLDivElement | null>(null);
    React.useImperativeHandle(ref, () => ({
      submit: triggerSubmit,
    }));

    const {
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      setFieldValue,
    } = useFormik({
      enableReinitialize: true,
      validationSchema: EditarDadosFormValidation,
      initialValues: {
        empresaMaster: {
          lgr: empresa?.empresaMaster?.lgr,
          nro: empresa?.empresaMaster?.nro,
          bairro: empresa?.empresaMaster?.bairro,
          cep: empresa?.empresaMaster?.cep,
          cpl: empresa?.empresaMaster?.cpl,
          municipio: empresa?.empresaMaster?.municipio,
          uf: empresa?.empresaMaster?.uf,
          telefone: empresa?.empresaMaster?.telefone,
          fant: empresa?.empresaMaster?.fant,
        },
        cardapioEmpresa: {
          nome: empresa?.cardapioEmpresa?.nome,
          informacao: empresa?.cardapioEmpresa?.informacao,
          segmento: empresa?.cardapioEmpresa?.segmento,
          horarios: {
            segundas: {
              abertura: empresa?.cardapioEmpresa?.horarios?.segundas?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.segundas?.fechamento,
            },
            tercas: {
              abertura: empresa?.cardapioEmpresa?.horarios?.tercas?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.tercas?.fechamento,
            },
            quartas: {
              abertura: empresa?.cardapioEmpresa?.horarios?.quartas?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.quartas?.fechamento,
            },
            quintas: {
              abertura: empresa?.cardapioEmpresa?.horarios?.quintas?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.quintas?.fechamento,
            },
            sextas: {
              abertura: empresa?.cardapioEmpresa?.horarios?.sextas?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.sextas?.fechamento,
            },
            sabados: {
              abertura: empresa?.cardapioEmpresa?.horarios?.sabados?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.sabados?.fechamento,
            },
            domingos: {
              abertura: empresa?.cardapioEmpresa?.horarios?.domingos?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.domingos?.fechamento,
            },
            feriados: {
              abertura: empresa?.cardapioEmpresa?.horarios?.feriados?.abertura,
              fechamento: empresa?.cardapioEmpresa?.horarios?.feriados?.fechamento,
            },
          },
          statusLoja: empresa?.cardapioEmpresa?.statusLoja || '1',
          permiteEntrega: empresa?.cardapioEmpresa?.permiteEntrega || 'false',
          permiteRetirada: empresa?.cardapioEmpresa?.permiteRetirada || 'false',
        },
      },
      onSubmit: salvarDados as any,
    });

    const quandoEncontrarUmCepExecutar = (dados: any) => {
      setFieldValue('empresaMaster.lgr', dados.consultaCEP.Logradouro, true);
      setFieldValue('empresaMaster.bairro', dados.consultaCEP.Bairro, true);
      setFieldValue('empresaMaster.municipio', dados.consultaCEP.Municipio, true);
      setFieldValue('empresaMaster.uf', dados.consultaCEP.UF, true);
      inputNumeroRef?.current?.focus();
    };

    const triggerSubmit = () => {
      if (Object.entries(errors).length > 0) {
        return errors;
      }
      handleSubmit();
      return false;
    };
    return (
      <Paper className={classes.form} elevation={0} component="form" onSubmit={handleSubmit as any}>
        <Typography variant="h6" className={classes.typographyMarginBottom}>
          <strong>Dados Cadastrais</strong>
          <Divider />
        </Typography>

        <Grid spacing={1} container>
          <Grid item xs={12} md={5}>
            <TextField
              label="Nome Fantasia"
              placeholder=""
              name="empresaMaster.fant"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.fant}
              error={Boolean(
                getIn(touched, 'empresaMaster.fant') && getIn(errors, 'empresaMaster.fant'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.fant') && getIn(errors, 'empresaMaster.fant')
                  ? getIn(errors, 'empresaMaster.fant')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <CepInput
              label="CEP"
              placeholder="CEP"
              name="empresaMaster.cep"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.cep}
              quandoEncontrarUmCepExecutar={quandoEncontrarUmCepExecutar}
              error={Boolean(
                getIn(touched, 'empresaMaster.cep') && getIn(errors, 'empresaMaster.cep'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.cep') && getIn(errors, 'empresaMaster.cep')
                  ? getIn(errors, 'empresaMaster.cep')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <TextField
              label="Logradouro"
              placeholder="Logradouro"
              name="empresaMaster.lgr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.lgr}
              error={Boolean(
                getIn(touched, 'empresaMaster.lgr') && getIn(errors, 'empresaMaster.lgr'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.lgr') && getIn(errors, 'empresaMaster.lgr')
                  ? getIn(errors, 'empresaMaster.lgr')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              inputRef={inputNumeroRef}
              label="Número"
              name="empresaMaster.nro"
              placeholder="Número"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.nro}
              error={Boolean(
                getIn(touched, 'empresaMaster.nro') && getIn(errors, 'empresaMaster.nro'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.nro') && getIn(errors, 'empresaMaster.nro')
                  ? getIn(errors, 'empresaMaster.nro')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Bairro"
              name="empresaMaster.bairro"
              placeholder="Bairro"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.bairro}
              error={Boolean(
                getIn(touched, 'empresaMaster.bairro') && getIn(errors, 'empresaMaster.bairro'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.bairro') && getIn(errors, 'empresaMaster.bairro')
                  ? getIn(errors, 'empresaMaster.bairro')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="Complemento"
              name="empresaMaster.cpl"
              placeholder="Complemento"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.cpl}
              error={Boolean(
                getIn(touched, 'empresaMaster.cpl') && getIn(errors, 'empresaMaster.cpl'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.cpl') && getIn(errors, 'empresaMaster.cpl')
                  ? getIn(errors, 'empresaMaster.cpl')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label="Município"
              name="empresaMaster.municipio"
              placeholder="Município"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.municipio}
              error={Boolean(
                getIn(touched, 'empresaMaster.municipio') &&
                getIn(errors, 'empresaMaster.municipio'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.municipio') &&
                  getIn(errors, 'empresaMaster.municipio')
                  ? getIn(errors, 'empresaMaster.municipio')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <UfSelect
              {...{
                label: 'UF',
                placeholder: 'UF',
                name: 'empresaMaster.uf',
                error: Boolean(
                  getIn(touched, 'empresaMaster.uf') && getIn(errors, 'empresaMaster.uf'),
                ),
                helperText: Boolean(
                  getIn(touched, 'empresaMaster.uf') && getIn(errors, 'empresaMaster.uf'),
                )
                  ? getIn(errors, 'empresaMaster.uf')
                  : undefined,
              }}
              onBlur={handleBlur}
              ufValue={values.empresaMaster?.uf ? values.empresaMaster?.uf : ''}
              onInputChange={(event, newValue) => {
                if (event) {
                  setFieldValue('empresaMaster.uf', newValue, true);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputComMascara
              label="Telefone"
              name="empresaMaster.telefone"
              placeholder="DDD + Telefone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.empresaMaster.telefone}
              error={Boolean(
                getIn(touched, 'empresaMaster.telefone') && getIn(errors, 'empresaMaster.telefone'),
              )}
              helperText={
                getIn(touched, 'empresaMaster.telefone') && getIn(errors, 'empresaMaster.telefone')
                  ? getIn(errors, 'empresaMaster.telefone')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
              mascara="( 99 ) 9999-99999"
              guiarUsuario={false}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          className={classNames(classes.typographyMarginTop, classes.typographyMarginBottom)}
        >
          <strong>Configurações do Cardápio</strong>
          <Divider />
        </Typography>

        <Grid spacing={1} container>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Nome Empresa"
              name="cardapioEmpresa.nome"
              placeholder="Nome Empresa"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cardapioEmpresa.nome}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.nome') && getIn(errors, 'cardapioEmpresa.nome'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.nome') && getIn(errors, 'cardapioEmpresa.nome')
                  ? getIn(errors, 'cardapioEmpresa.nome')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Informação"
              placeholder="Informação Empresa"
              name="cardapioEmpresa.informacao"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cardapioEmpresa.informacao}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.informacao') &&
                getIn(errors, 'cardapioEmpresa.informacao'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.informacao') &&
                  getIn(errors, 'cardapioEmpresa.informacao')
                  ? getIn(errors, 'cardapioEmpresa.informacao')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Segmento"
              placeholder="Segmento"
              name="cardapioEmpresa.segmento"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cardapioEmpresa.segmento}
              error={Boolean(
                getIn(touched, 'cardapioEmpresa.segmento') &&
                getIn(errors, 'cardapioEmpresa.segmento'),
              )}
              helperText={
                getIn(touched, 'cardapioEmpresa.segmento') &&
                  getIn(errors, 'cardapioEmpresa.segmento')
                  ? getIn(errors, 'cardapioEmpresa.segmento')
                  : undefined
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="select-status-loja"
              select
              name="statusLoja"
              label="Status da Loja"
              value={values.cardapioEmpresa.statusLoja}
              onChange={(event) => {
                if (event) {
                  setFieldValue('cardapioEmpresa.statusLoja', event.target.value, true);
                }
              }}
              helperText=""
            >
              {Situacoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="select-permite-entrega"
              select
              name="permiteEntrega"
              label="Permite Entrega"
              value={values.cardapioEmpresa.permiteEntrega}
              onChange={(event) => {
                if (event) {
                  setFieldValue('cardapioEmpresa.permiteEntrega', event.target.value, true);
                }
              }}
              helperText=""
            >
              {opcoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="select-permite-retirada"
              select
              name="permiteRetirada"
              label="Permite Retirada"
              value={values.cardapioEmpresa.permiteRetirada}
              onChange={(event) => {
                if (event) {
                  setFieldValue('cardapioEmpresa.permiteRetirada', event.target.value, true);
                }
              }}
              helperText=""
            >
              {opcoes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          className={classNames(classes.typographyMarginTop, classes.typographyMarginBottom)}
        >
          <strong>Horário de funcionamento</strong>
          <Divider />
        </Typography>
        <Horarios
          errors={errors}
          blur={handleBlur}
          change={handleChange}
          touched={touched}
          values={values}
          setValueForm={setFieldValue}
        />
      </Paper>
    );
  },
);

import React, { useEffect } from 'react';
import { Modal, ImagemInput, Botao, NumberInput, useNotificacoes, Carregando } from 'componentes';
import { Grid, TextField } from '@material-ui/core';
import { CategoriaAutoComplete } from 'modulos/cardapio/componentes/categorias/categoria-autocomplete';
import { ProdutoMedidaAutocomplete } from 'modulos/cardapio/componentes/produtos/produto-medida-autocomplete';
import { useFormik } from 'formik';
import { EditarProdutoProps } from './editar-produto-props';
import { useStyles } from './editar-produto-styles';
import { useObterProduto } from 'api/cardapio/queries';
import { CardapioCategoria } from 'api/cardapio/models';
import SaveIcon from '@material-ui/icons/Save';
import { ProdutoValidacao } from './editar-produto-modal-validacao';
export const EditarProdutoModal = ({
  aberto,
  quandoForFechado,
  empresaId,
  produtoId,
  categoriaId,
  atualizandoProduto,
  salvarProduto,
}: EditarProdutoProps) => {
  const classes = useStyles();
  const { showErrorMessage } = useNotificacoes();
  const { data: produto, isLoading: carregandoProduto } = useObterProduto(
    {
      empresaId,
      produtoId: produtoId || '',
    },
    {
      enabled: Boolean(empresaId) && Boolean(produtoId) && Boolean(aberto),
      useErrorBoundary: false,
      onError: (error: any) => {
        if (quandoForFechado)
          quandoForFechado();

        showErrorMessage(`Erro ao Localizar o Produto '${produtoId}'. Detalhe: ${error.message}`);
      },
    },
  );

  const showLoading = carregandoProduto || atualizandoProduto;

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    validationSchema: ProdutoValidacao,
    initialValues: {
      nome: produto?.nome || '',
      informacoes: produto?.descricao || '',
      imagemUrl: produto?.imagemUrl || '',
      medida: produto?.medida || 'UN',
      valor: (produto?.valor || 0).toFixed(2).replace('.', ','),
      categoria: {
        descricao: '',
        id: produto?.categoria?.id || categoriaId,
        imagemUrl: '',
        paiId: '',
      } as CardapioCategoria,
    },
    enableReinitialize: true,
    onSubmit: async (form) => {
      if (salvarProduto) {
        await Promise.resolve(
          salvarProduto({
            empresaId,
            categoriaId: form.categoria.id,
            descricao: form?.nome,
            infAdic: form?.informacoes,
            id: produtoId || '',
            medida: form?.medida,
            valor: parseFloat((form?.valor || '').toString().replace(',', '.')),
            imagem: form?.imagemUrl,
          }),
        ).catch((e) => {
          showErrorMessage('Hove um erro ao salvar o Produto. Detalhe: ' + e.message);
        }).then(() => {
          if (quandoForFechado)
            quandoForFechado();
        });

      }
    },
  });

  useEffect(() => {
    resetForm();
  }, [produtoId, resetForm]);

  useEffect(() => {

    if (!aberto) {
      resetForm();
    }
  }, [resetForm, aberto]);

  const carregando = <Carregando carregando={showLoading} modelo='absolute' titulo={atualizandoProduto ? "Salvando Produto" : "Carregando Produto"} mensagem="Por favor aguarde enquanto carregamos as informações..."></Carregando>;

  return (
    <Modal
      titulo={'Cadastro de Produtos'}
      aberto={aberto}
      carregando={carregando}
      quandoForFechado={quandoForFechado}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.bloco}>
          <Grid item xs={12}>
            <CategoriaAutoComplete
              empresaId={empresaId}
              label="Categoria"
              name="categoria"
              valueId={values?.categoria?.id || categoriaId || ''}
              onChange={(categoria) => {
                setFieldValue('categoria', categoria, true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.categoria && touched.categoria)}
              helperText={errors.categoria && touched.categoria ? errors.categoria : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nome"
              placeholder="Digite o nome aqui!"
              name="nome"
              value={values.nome}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.nome && touched.nome)}
              helperText={errors.nome && touched.nome ? errors.nome : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Informações do produto"
              placeholder="Digite as informações aqui!"
              rowsMax={3}
              multiline
              name="informacoes"
              value={values.informacoes}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.informacoes && touched.informacoes)}
              helperText={errors.informacoes && touched.informacoes ? errors.informacoes : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProdutoMedidaAutocomplete
              isVisible={aberto}
              empresaId={empresaId}
              label="Medida"
              placeholder="Selecione a medida"
              name="medida"
              
              value={values.medida}
              onChange={(medida) => {
                setFieldValue('medida', medida, true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.medida && touched.medida)}
              helperText={errors.medida && touched.medida ? errors.medida : undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              label="Valor"
              placeholder="0,00"
              casasDecimais={2}
              identificador="R$"
              name="valor"
              value={values.valor}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.valor && touched.valor)}
              helperText={errors.valor && touched.valor ? errors.valor : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <ImagemInput
              name="imagemUrl"
              value={values.imagemUrl}
              onChange={({ base64 }: any) => {
                setFieldValue('imagemUrl', base64, true);
              }}
              onBlur={() => {
                setFieldTouched('imagemUrl', true, true);
              }}
              error={Boolean(errors.imagemUrl && touched.imagemUrl)}
              helperText={errors.imagemUrl && touched.imagemUrl ? errors.imagemUrl : undefined}
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Botao color="primary" type="submit" variant="contained" disabled={showLoading}>
            <SaveIcon fontSize="small" style={{ marginRight: 10 }}></SaveIcon>Salvar Produto
          </Botao>
        </Grid>
      </form>
    </Modal>
  );
};

import React, { useEffect } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { CategoriaAutoCompleteProps, Value } from './categoria-autocomplete-props';
import { useObterCategorias } from 'api/cardapio/queries';
import { CardapioCategoria } from 'api/cardapio/models';
import { useNotificacoes } from 'componentes';

export const CategoriaAutoComplete = <Multiple extends boolean | undefined>({
  empresaId,
  filter,
  error,
  label,
  helperText,
  name,
  valueId,
  onChange,
  onBlur,
}: CategoriaAutoCompleteProps<Multiple>) => {
  const { showErrorMessage } = useNotificacoes();

  const { isLoading: carregandoCategorias, data: categorias } = useObterCategorias(empresaId, {
    enabled: Boolean(empresaId),
    useErrorBoundary: false,
    onError: (e: any) => {
      showErrorMessage('Houve um erro ao carregar as Categorias. Detalhe: ' + e.message);
    },
    onSuccess: () => {},
  });

  const categoriasFiltradas = isEmpty(categorias)
    ? []
    : filter
    ? categorias?.filter(filter)
    : categorias;

  const catOrdenadas = (categoriasFiltradas || []).sort(function (a, b) {
    if ((a.descFormatado || '').toLowerCase() < (b.descFormatado || '').toLowerCase()) return -1;
    if ((a.descFormatado || '').toLowerCase() > (b.descFormatado || '').toLowerCase()) return 1;
    return 0;
  });

  const [value, setValue] = React.useState<CardapioCategoria>();
  const [inputValue, setInputValue] = React.useState<string>('');

  useEffect(() => {
    const newVal =
      !isEmpty(categorias) && valueId
        ? (categorias?.find((x) => x.id === valueId) as CardapioCategoria)
        : undefined;

    setValue(newVal);
    setInputValue(newVal?.descFormatado || '');
  }, [valueId, categorias]);

  return (
    <React.Fragment>
      <Autocomplete
        options={catOrdenadas || []}
        loading={carregandoCategorias}
        inputValue={inputValue}
        noOptionsText="Nenhuma categoria encontrada"
        loadingText="Carregando categorias..."
        openOnFocus
        autoHighlight
        getOptionLabel={(option) => option.descFormatado || option.descricao}
        getOptionSelected={(option, value) => option?.id === value?.id}
        value={value}
        autoSelect={true}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, valor) => {
          if (onChange) {
            onChange(valor as Value<CardapioCategoria, Multiple>);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            margin="dense"
            name={name}
            onBlur={onBlur}
            label={label}
            helperText={helperText}
            placeholder={'Selecione ou Digite'}
            error={error as any}
            {...params}
            inputProps={{
              ...params.inputProps,
              shrink: true,
              autoComplete: 'off',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {carregandoCategorias ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

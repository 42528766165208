import { useState, useCallback, useEffect } from 'react';
import { CategoriasCarroselProps } from './categorias-carrosel-props';
import { CardapioCategoria } from 'api/cardapio/models';
import { isEmpty } from 'lodash';

export const useCategoriasCarrosel = ({
  categorias: categoriaProps,
  onCategoriaClick,
  carregando = false,
  somenteLeitura,
}: CategoriasCarroselProps) => {
  const [categorias, setarCategorias] = useState(categoriaProps || []);
  const [editarCategoria, setarEdicao] = useState<string | undefined>(undefined);

  const editar = useCallback(
    (categoria?: CardapioCategoria) => {
      if (somenteLeitura === false) {
        setarEdicao(categoria?.id);
        return;
      }
    },
    [somenteLeitura],
  );

  const selecionarCategoriaNav = useCallback(
    (categoria?: CardapioCategoria) => () => {
      if (onCategoriaClick) onCategoriaClick(categoria);
    },
    [onCategoriaClick],
  );

  const selecionarCategoria = useCallback(
    (categoria?: CardapioCategoria) => {
      if (onCategoriaClick) onCategoriaClick(categoria);
    },
    //eslint-disable-next-line 
    [onCategoriaClick, categoriaProps],
  );

  const abrirCadastroCategoria = useCallback(() => {
    setarEdicao('new');
  }, [setarEdicao]);

  const fecharEdicaoCategoria = useCallback(() => {
    setarEdicao(undefined);
  }, []);

  useEffect(() => {
    setarCategorias(categoriaProps || []);
  }, [categoriaProps]);

  return {
    dados: {
      categorias: categorias.length <= 0 ? categoriaProps : categorias,
      existeCategorias: isEmpty(categorias) === false,
      editarCategoria: Boolean(editarCategoria) ? editarCategoria : false,
    },
    operacoes: {
      selecionarCategoria,
      selecionarCategoriaNav,
      abrirCadastroCategoria,
      fecharEdicaoCategoria,
      editar,
    },
  };
};

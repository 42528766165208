import React from 'react';
import { useEmpresaTema } from 'componentes/empresa-tema';
import { useResources } from 'resources';
interface DocumentTitleProps {
  titulo: string;
}

export const DocumentTitle = ({ titulo }: DocumentTitleProps) => {
  const { empresa } = useEmpresaTema();
  const resources = useResources();
  React.useEffect(() => {
    document.title = empresa && empresa.nome ? `${empresa?.nome} - ${titulo}` : `${titulo}`;
    try {
      let link;
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = resources.favIcon;
      document.getElementsByTagName('head')[0].appendChild(link);
    } catch {}
  }, [empresa, titulo, resources.favIcon]);

  return null;
};

import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { CardapioCategoria } from 'api/cardapio/models';
import { useQuery, QueryConfig } from 'react-query';
import { isEmpty } from 'lodash';

class ObterCategorias implements ApiQuery<string, CardapioCategoria[]> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(empresaId: string): any[] {
    return ['ObterCategorias', empresaId];
  }

  execute(_: any, empresaId: string) {
    return FetchCardapioApiAsPublicUserJSON<CardapioCategoria[]>(
      empresaId,
      `/${empresaId}/categoria`,
    ).then((json) => {
      const ret = Array.isArray(json)
        ? json.map(
            (categoria) =>
              ({
                cor: categoria?.cor,
                descricao: categoria?.descricao,
                id: categoria?.id,
                imagemUrl: categoria?.imagemUrl,
                ativo: categoria?.ativo,
                paiId: categoria?.paiId || '',
                rank: categoria?.rank,
                nivel: 0,
                descFormatado: '',
                herancaId: '',
              } as CardapioCategoria),
          )
        : [];
      preencheCategorias(ret);
      return ret;
    });
  }
}

const preencheCategorias = (categorias: CardapioCategoria[], categoriaPai?: CardapioCategoria) => {
  let cats = categorias.filter(
    (x) => x.paiId === (isEmpty(categoriaPai?.id) ? '' : categoriaPai?.id),
  );
  cats.forEach((v, index) => {
    v.descFormatado = v.descricao;
    v.herancaId = v?.id || '';
    v.nivel = (categoriaPai?.nivel || 0) + 1;
    preencheCategorias(categorias, v);
  });
};

export const obterCategorias = new ObterCategorias();

export const useObterCategorias = (
  empresaId: string,
  config?: QueryConfig<CardapioCategoria[]>,
) => {
  return useQuery(obterCategorias.getKey(empresaId), obterCategorias.execute, config);
};

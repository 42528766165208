import React, { useRef, useCallback, useState } from 'react';
import { makeStyles, FormHelperText, emphasize } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ImagemBlobParaBase64 } from 'utils';
import { Botao } from '../botao';
import classNames from 'classnames';
import CSS from 'csstype';

const useStyles = makeStyles((theme) => ({
  root: ({ value }: ImagemInputProps) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: value ? '10px 20px' : undefined,
    border: value ? `2px dashed ${theme.palette.primary.main}` : undefined,
    borderRadius: theme.shape.borderRadius,
  }),
  img: ({ imageWidth, imageHeight, mode }: ImagemInputProps) => ({
    objectFit: mode || 'cover',
    width: imageWidth || '150px',
    height: imageHeight || '150px',
    marginTop: '10px',
  }),
  removerImagem: ({ imageWidth }: ImagemInputProps) => ({
    marginTop: '10px',
    width: imageWidth || '150px',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: emphasize(theme.palette.error.main, 0.15),
    },
  }),
}));

interface ImagemInputProps {
  accept?: string;
  value?: string;
  label?: string;
  className?: string;
  onChange?: (dados?: Resposta) => any;
  helperText?: string;
  error?: boolean;
  name?: string;
  onBlur?: (name: string) => any;
  imageWidth?: CSS.Properties['width'];
  imageHeight?: CSS.Properties['height'];
  mode?: CSS.Properties['objectFit'];
}

interface Resposta {
  arquivo?: File | undefined;
  base64?: string;
}

export const ImagemInput = (props: ImagemInputProps) => {
  const classes = useStyles(props);
  const { accept, onChange, value, label, className, helperText, error, name, onBlur } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [carregandoImportacao, setCarregandoImportacao] = useState(false);

  const abrirSelecaoDeArquivo = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const carregarArquivo = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event.target.files) || carregandoImportacao) {
        return;
      }

      setCarregandoImportacao(true);

      const arquivo = event.target.files![0];
      const base64 = await ImagemBlobParaBase64(arquivo);
      const dados = {
        arquivo,
        base64,
      };

      if (onChange) {
        onChange(dados);
      }

      setTimeout(() => {
        setCarregandoImportacao(false);
      }, 500);
    },
    [carregandoImportacao, onChange],
  );

  const limparArquivo = useCallback(() => {
    const dados = {
      arquivo: undefined,
      base64: undefined,
    };

    if (onChange) {
      onChange(dados);
    }
  }, [onChange]);

  const onBlurWrap = useCallback(
    (event?: any) => {
      if (onBlur) {
        onBlur(name || '');
      }
    },
    [onBlur, name],
  );

  return (
    <div className={classNames(classes.root, className)} onBlur={onBlurWrap}>
      <input
        ref={inputFileRef}
        name={name}
        type="file"
        multiple={false}
        onChange={carregarArquivo}
        accept={accept}
        hidden
      />
      <Botao
        color="primary"
        carregando={carregandoImportacao}
        variant="contained"
        onBlur={onBlurWrap}
        fullWidth={true}
        onClick={abrirSelecaoDeArquivo}
      >
        {label || (value ? 'Importar nova imagem' : 'Importar Imagem')}
      </Botao>
      {value && <img src={value} alt="Imagem Carregada" className={classes.img} />}
      {value && (
        <Botao
          color="inherit"
          disabled={carregandoImportacao}
          variant="contained"
          className={classes.removerImagem}
          fullWidth={false}
          onClick={limparArquivo}
        >
          Remover Imagem
        </Botao>
      )}
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};

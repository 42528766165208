import React from 'react';
import { ProdutoProps } from './produto-props';
import { useStyles } from './produto-styles';
import { Skeleton } from '@material-ui/lab';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Botao } from 'componentes';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useConfirm } from 'material-ui-confirm';
import classNames from 'classnames';

export const Produto = (props: ProdutoProps) => {
  const classes = useStyles(props);
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
  const confirm = useConfirm();
  const [deletando, definirDeletando] = React.useState(false);

  const { carregando: carregandoProp, somenteLeitura, produto, onClick, deletar } = props;

  const carregando = Boolean(carregandoProp) || Boolean(produto) === false;
  const existeImagem = Boolean(produto?.imagemUrl);

  const onClickWrap = React.useCallback(() => {
    if (onClick) {
      onClick(produto);
    }
  }, [produto, onClick]);

  const deletarWrap = React.useCallback(() => {
    confirm({
      description: 'O produto não irá mais aparecer no cardapio, você tem certeza ?',
    })
      .then(() => {
        if (deletar) {
          definirDeletando(true);

          setTimeout(async () => {
            await Promise.resolve(deletar(produto)).catch(() => null);
            definirDeletando(false);
          }, 500);
        }
      })
      .catch(() => {});
  }, [produto, deletar, confirm]);

  const Conteudo = React.useMemo(
    () => ({ children }: any) => (
      <CardActionArea onClick={onClickWrap} className={classes.conteudo}>
        {existeImagem && (
          <img src={produto?.imagemUrl} className={classes.img} alt={produto?.nome} />
        )}
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </CardActionArea>
    ),

    [
      onClickWrap,
      classes.conteudo,
      classes.cardContent,
      existeImagem,
      produto?.imagemUrl,
      produto?.nome,
      classes.img,
    ],
  );
  if (carregando) {
    return <Skeleton variant="rect" height="185px" />;
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <Conteudo>
          <div>
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              className={classNames(classes.text, classes.nome)}
              gutterBottom
            >
              {produto?.nome}
            </Typography>
            <Typography
              variant={isMobile ? 'caption' : 'subtitle2'}
              color="textSecondary"
              className={classNames(classes.text, classes.descricao)}
              gutterBottom
            >
              {produto?.descricao}
            </Typography>
          </div>
          <Typography
            color="primary"
            style={{ marginBottom: '-15px' }}
            className={classes.text}
            align="right"
            variant={isMobile ? 'h5' : 'h5'}
          >
            <strong className={classes.valor}>
              {`R$ ${(+`${produto?.valor || 0}`)?.toFixed(2).replace('.', ',')}`}&nbsp;
              <small className={classes.medida}>{produto?.medida}</small>
            </strong>
          </Typography>
        </Conteudo>
      </Card>
      {somenteLeitura === false && (
        <Botao
          fullWidth
          onClick={deletarWrap}
          variant="text"
          size="small"
          color="primary"
          className={classes.deleteButton}
          carregando={deletando}
        >
          <CancelRoundedIcon fontSize="small" className={classes.deleteIcon} />
          Deletar Produto
        </Botao>
      )}
    </div>
  );
};

import { ApiQuery } from 'api/interfaces';
import { ItemSacola, CardapioStorageKeys, storageCardapio } from 'storage'
import { useQuery, QueryConfig } from 'react-query';
interface ObterSacolaInput {
  empresaId: string;
}

class ObterSacola implements ApiQuery<ObterSacolaInput, ItemSacola[]> {
  getKey(params: ObterSacolaInput): any[] {
    return ['ObterSacola', params];
  }

  execute(_: any, { empresaId }: ObterSacolaInput): ItemSacola[] {

    let itens = storageCardapio.load(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString()) as ItemSacola[];
    if (Object.entries(itens).length === 0) {
      itens = [] as ItemSacola[];
    }
    return itens.filter((x) => x.idRestaurante === empresaId);
  }
}

export const obterSacola = new ObterSacola();

export const useObterSacola = (
  input: ObterSacolaInput,
  config?: QueryConfig<ItemSacola[], ObterSacolaInput>,
) => {
  return useQuery(obterSacola.getKey(input), obterSacola.execute, config);
};

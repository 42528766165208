import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  bloco: {
    marginBottom: '30px',
  },
  picker: {
    padding: '0 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  btn: {
    width: '100%',
  },
}));

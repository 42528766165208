import React from 'react';
import { CategoriaProps } from './categoria-props';
import { Button, ButtonBase } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './categoria-styles';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
export const Categoria = ({
  carregando,
  somenteLeitura,
  categoria,
  onClick,
  editar,
  reativar,
  desativar,
}: CategoriaProps) => {
  const classes = useStyles();
  const carregandoCategoria = carregando || Boolean(categoria) === false;
   // eslint-disable-next-line
  const [acoes, definirAcoes] = React.useState(false);

  const handleAcoesOpen = React.useCallback(() => {
    definirAcoes(true);
  }, []);

  const handleAcoesClose = React.useCallback(() => {
    definirAcoes(false);
  }, []);

  const onClickWrap = React.useCallback(() => {
    if (onClick) {
      onClick(categoria);
    }
  }, [categoria, onClick]);



  if (carregandoCategoria) {
    return <Skeleton variant="rect" height="85px" width="175px" />;
  }

  const statusCat = somenteLeitura ? null : (
    <span className={classes.status}>
      {categoria?.ativo === true && (
        <div>
          <DoneIcon fontSize="small" />
          Ativo
        </div>
      )}
      {categoria?.ativo !== true && (
        <div>
          <CloseIcon fontSize="small" />
          Inativo
        </div>
      )}
    </span>
  );

  return (
    <div
      role="presentation"
      className={classes.botaoContainer}
      onMouseEnter={handleAcoesOpen}
      onMouseLeave={handleAcoesClose}
      onFocus={handleAcoesOpen}
      onBlur={handleAcoesClose}
    >
      {Boolean(categoria?.imagemUrl) === false ? (
        <Button
          type="button"
          variant="contained"
          disableElevation
          color="primary"
          style={{
            backgroundColor: categoria?.cor || undefined,
            opacity: categoria?.ativo === true ? 1 : 0.6,
          }}
          className={classes.botao}
          onClick={onClickWrap}
        >
          {statusCat}
          <strong className={classNames(classes.texto, classes.textoBtn)}>
            {categoria?.descricao}
          </strong>
        </Button>
      ) : (
        <ButtonBase
          type="button"
          className={classes.botao}
          style={{
            backgroundImage: `url(${categoria?.imagemUrl})`,
            opacity: categoria?.ativo === true ? 1 : 0.8,
          }}
          onClick={onClickWrap}
          focusRipple
        >
          <span
            className={classes.backdrop}
            style={{
              backgroundColor: categoria?.cor || undefined,
            }}
          />
          <span className={classes.textoContainer}>
            {statusCat}
            <strong className={classNames(classes.texto, classes.textoImg)}>
              {categoria?.descricao}
            </strong>
          </span>
        </ButtonBase>
      )}
      {/* {somenteLeitura === false && (
        <div
          role="menu"
          aria-orientation="horizontal"
          className={classNames(
            classes.acoes,
            acoesAberto === false ? classes.acoesFechado : undefined,
          )}
        >
          <Fab
            tooltip={`Editar ${categoria?.descricao || 'Categoria'}`}
            size="small"
            color="primary"
            style={{ marginRight: '10px' }}
            className={classNames(classes.acao, !acoesAberto && classes.acaoFechado)}
            onClick={editWrap}
          >
            <EditRoundedIcon />
          </Fab>
          {categoria?.ativo === true &&
            <Fab
              tooltip={`Desativar ${categoria?.descricao || 'Categoria'}`}
              size="small"
              color="primary"
              carregando={carregandoCategoria}
              className={classNames(classes.acao, !acoesAberto && classes.acaoFechado)}
              onClick={desativarWrap}
            >
              <CloseIcon />
            </Fab>
          }
          {categoria?.ativo === false &&
            <Fab
              tooltip={`Reativar ${categoria?.descricao || 'Categoria'}`}
              size="small"
              color="primary"
              carregando={carregandoCategoria}
              className={classNames(classes.acao, !acoesAberto && classes.acaoFechado)}
              onClick={reativarWrap}
            >
              <ReplayIcon />
            </Fab>
          }
        </div>
      )} */}
    </div>
  );
};

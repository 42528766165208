import { ApiQuery } from 'api/interfaces';
import { CardapioCategoria } from 'api/cardapio/models';
import { useQuery, QueryConfig } from 'react-query';
import { FetchPedidosApiWithUserJSON } from '../utils';
import { UsuarioMaster } from 'componentes';

interface ObterLojaInput {
    documento: string;
}

class ObterLoja implements ApiQuery<ObterLojaInput, CardapioCategoria> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(params: ObterLojaInput): any[] {
        return ['ObterLoja', params];
    }

    execute(documento: string) {
        var token = UsuarioMaster.fromLocalStorageToken()?.token || '';
        return FetchPedidosApiWithUserJSON<any>(
            token,
            `/v1/loja?${documento}`,
        ).then(
            (json) => {
                return json
            }
        );
    }
}

export const obterLoja = new ObterLoja();

export const useObterCategoria = (
    props: ObterLojaInput,
    config: QueryConfig<any>,
) => {
    return useQuery(obterLoja.getKey(props), obterLoja.execute, config);
};

import { merge } from 'lodash';
import { IAmbientes, IAmbiente } from './ambiente-tipos';

const isDev = false; //mudar para alternar entre mostrar e não mostrar o controle de queries
export const AmbienteEnviado = ('#{Ambiente}#' as keyof IAmbientes) as string;
// const AmbientePadrao = AmbienteEnviado || ('#{Ambiente}#' as keyof IAmbientes);

const Ambientes: IAmbientes = {
  comum: {
    isDev: isDev,
    whatsAppLink: 'https://api.whatsapp.com/send?phone=@telefone&text=@mensagem',
    saurusUtilsWebService: 'https://wsmaster.saurus.net.br/v003/servicemaster.asmx',
  },
  menuVip: {
    cardapioApi: 'https://api-gestao-produto.saurus.net.br/api',
    masterApi: 'https://api-master.saurus.net.br/api',
    pedidosApi: 'https://apisauruspedidos-homologacao.azurewebsites.net/api',
    authFront: 'https://auth.saurus.net.br/',
    appName: 'MenuVIP',
  },
  cardapioSaurus: {
    cardapioApi: 'https://api-gestao-produto.saurus.net.br/api',
    masterApi: 'https://api-master.saurus.net.br/api',
    pedidosApi: 'https://apisauruspedidos-homologacao.azurewebsites.net/api',
    authFront: 'https://auth.saurus.net.br/',
    appName: 'CardapioSaurus',
  },
};

const Ambiente = (Ambientes[AmbienteEnviado] as IAmbiente) || Ambientes.menuVip;

export const VariaveisAmbiente = merge(Ambientes.comum, Ambiente);

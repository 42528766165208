import React, { ReactNode, useCallback, useRef, memo } from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ImagemBlobParaBase64 } from 'utils';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import { BotaoTransparente } from 'componentes';

export interface CardapioHeaderCapaProps {
  capaUrl?: string;
  height: number | string;
  children?: ReactNode;
  somenteLeitura?: boolean;
  quandoUmaNovaCapaForSelecionada?: (args: { capa: string }) => any;
}

const useStyles = makeStyles((theme) => ({
  conteudo: ({ capaUrl, height }: any) => ({
    minHeight: '400px',
    backgroundImage: `url(${capaUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    position: 'relative',
    height: height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  conteudoFilho: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  action: {
    position: 'absolute',
    top: '20px',
    left: '10px',
    zIndex: 1,
  },
  marginRight: {
    marginRight: '10px',
  },
}));

const CardapioHeaderCapaComp = ({
  capaUrl,
  somenteLeitura,
  children,
  height,
  quandoUmaNovaCapaForSelecionada,
}: CardapioHeaderCapaProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const atualizarFotoDeCapa = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const uploadCapa = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event?.target?.files)) {
        return;
      }

      const file = event?.target?.files![0];
      const capa = await ImagemBlobParaBase64(file);

      if (quandoUmaNovaCapaForSelecionada) {
        quandoUmaNovaCapaForSelecionada({
          capa,
        });
      }
    },
    [quandoUmaNovaCapaForSelecionada],
  );

  const classes = useStyles({ capaUrl, height });

  return (
    <div className={classes.conteudo}>
      <Fade in={somenteLeitura === false} appear={false}>
        <div className={classes.action}>
          <input
            ref={inputFileRef}
            multiple={false}
            type="file"
            accept="image/*"
            hidden
            onChange={uploadCapa}
          />
          <BotaoTransparente tipo="button" size="small" onClick={atualizarFotoDeCapa}>
            <AddAPhotoRoundedIcon fontSize="small" className={classes.marginRight} />
            {capaUrl ? 'Alterar Capa' : 'Adicionar Capa'}
          </BotaoTransparente>
        </div>
      </Fade>
      <div className={classes.conteudoFilho}>{children}</div>
    </div>
  );
};
export const CardapioHeaderCapa = memo(CardapioHeaderCapaComp);

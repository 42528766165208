import { salvarDadosLojaPost } from 'api/pedidos/mutations/enviar-dados-pedidos/cadastrar-dados-loja';
import { obterLoja } from 'api/pedidos/queries';
import { useMasterApi } from 'modulos/cardapio/containers/cardapio/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface PedidosContextValue {
    // salvarDadosLoja: () => void;
}

const PedidosContext = React.createContext<PedidosContextValue>({
    // salvarDadosLoja: () => { },
});

export interface PedidosProviderProps {
    children: React.ReactNode;
}

export const PedidosProvider = ({ children }: PedidosProviderProps) => {

    const { empresaId } = useParams<{ empresaId: string }>();
    const { dados: apiMasterDados } = useMasterApi(empresaId);
    const [lojas, setLojas] = useState([])

    const documento = apiMasterDados?.empresa?.data?.doc

    const pegarLoja = useCallback(async () => {
        try {
            const ret = await obterLoja.execute(documento || '')
            setLojas(ret)
        } catch (e: any) {
            console.log(e.message)
        }
    }, [documento])

    const salvarDadosLoja = useCallback(async (api: string, documento: string) => {
        try {
            if (documento) {
                await salvarDadosLojaPost.execute({ documento: api }, documento)
            }
        } catch (e: any) {
            console.log(e.message)
        }
    }, [])

    const verificarCadastroLoja = useCallback(async () => {
        await pegarLoja()
        if (lojas.length === 0) {
            await salvarDadosLoja(documento || '', documento || '')
        }
    }, [documento, lojas.length, pegarLoja, salvarDadosLoja])

    useEffect(() => {
        verificarCadastroLoja()
    }, [verificarCadastroLoja])

    return (
        <PedidosContext.Provider
            value={{
            }}
        >
            {children}
        </PedidosContext.Provider>
    );
};

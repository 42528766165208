import * as Yup from 'yup';

const NomeValidacao = Yup.string()
  .min(2, 'Você precisa informar ao menos 2 caracteres!')
  .max(50, 'A categoria pode ter no máximo 50 caracteres!')
  .required('Você precisa informar o nome da categoria!');

export const CategoriaValidacao = Yup.object().shape({
  nome: NomeValidacao,
});

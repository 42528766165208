import { ApiMutation } from 'api/interfaces';
import { UsuarioMaster } from 'componentes';
import { FetchMasterApiWithUserJSON } from 'api/master/utils';
import { EmpresaMaster } from 'api/master/models';

import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterDadosEmpresaMaster } from 'api/master/queries';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

class AtualizarDadosEmpresaMaster implements ApiMutation<EmpresaMaster, EmpresaMaster> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

 async execute(empresa: EmpresaMaster) {
    var token = UsuarioMaster.fromLocalStorageToken()?.token || '';
  return  await FetchMasterApiWithUserJSON<EmpresaMaster>(token, `/empresa`, {
      method: 'PUT',
      body: JSON.stringify(empresa),
    });
  }

  updateCache(input: EmpresaMaster, retorno: EmpresaMaster, cacheInstance: QueryCache) {
    const empresaKey = obterDadosEmpresaMaster.getKey(input.id || '');
    return cacheInstance.invalidateQueries(empresaKey);
  }
}

export const atualizarDadosEmpresaMaster = new AtualizarDadosEmpresaMaster();

export const useAtualizarDadosEmpresaMaster = (
  config?: MutationConfig<EmpresaMaster, unknown, EmpresaMaster>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Empresa atualizada com sucesso!',
    },
    atualizarDadosEmpresaMaster,
  );
  return useMutation(atualizarDadosEmpresaMaster.execute, MergeDeep(defaultConfig, config));
};

import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { CardapioCategoria } from 'api/cardapio/models';
import classNames from 'classnames';
import { MeuMenu, DrawerMenu, MenuConta, BarraPesquisa, CategoriaTabs } from '.';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  AppBar,
  Container,
  IconButton,
  Fade,
} from '@material-ui/core';
import { Carregando, useUsuarioLogado } from 'componentes';
import { ButtonSacola } from 'modulos/cardapio/containers/cardapio/cardapio-sacola/componentes/button-sacola';
import { useHistory } from 'react-router-dom';
import { Titulo, QRCodeModal } from 'componentes';
import { isEmpty } from 'lodash';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles((theme) => ({
  appBar: ({ imagemUrl, pesquisa, categorias, configurando }: any) => ({
    transition: 'all 0.3s ease-in-out !important',
    backgroundImage: imagemUrl
      ? theme.palette.type !== 'dark'
        ? `linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 30%, rgba(255, 255, 255, 0.05) 120%), url(${imagemUrl})`
        : `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.05) 120%), url(${imagemUrl})`
      : 'none',
    height:
      isEmpty(categorias) || configurando
        ? pesquisa
          ? '135px'
          : '68px'
        : pesquisa
        ? '175px'
        : '107px',
    backgroundRepeat: 'no-repeat',
    borderRadius: 'none',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    color: theme.palette.text.primary,
    position: 'sticky',
    top: 0,

    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
    },
  }),
  conteudoSomenteLeitura: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  btnSacola: {
    marginRight: '.3rem',
  },

  btnMenuLogin: {
    marginRight: '-8px',
    marginBottom: '-8px',
    display: 'flex',
  },
  logo: {
    maxHeight: '40px',
    margin: 0,
    marginRight: '1rem',
    objectFit: 'contain',
  },
  contentUsuarioLogado: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  searchIcon: {
    color: theme.palette.text.primary,
    marginRight: '-6px',
    marginBottom: '-11px',
  },
  nomeEmpresa: {
    lineHeight: '50px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  containerEmpresa: {
    width: '100%',
  },
  titulo: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: '1rem',
  },
}));

export interface CardapioHeaderNavBarProps {
  somenteLeitura: boolean;
  logoUrl?: string;
  nomeEmpresa?: string;
  infoEmpresa?: string;
  empresaId: string;
  categorias?: CardapioCategoria[];
  imagemUrl?: string;
  configurando: boolean;
  termoPesquisa?: string;
  categoriaSelecionada?: string;
  referencia?: any;
  contextoSelecionado?: string;
  categoriaClick?: (categoria?: CardapioCategoria) => any;
  quandoPesquisar?: (event: ChangeEvent<HTMLInputElement>) => any;
  navegarEditar: () => any;
  logout: () => any;
  quandoSacolaClick: () => any;
  setConfigurando: () => any;
  limparTermo: () => any;
}
export const CardapioHeaderNavBar = ({
  somenteLeitura,
  nomeEmpresa,
  infoEmpresa,
  logoUrl,
  empresaId,
  categorias,
  imagemUrl,
  configurando,
  termoPesquisa,
  categoriaSelecionada,
  referencia,
  contextoSelecionado,
  categoriaClick,
  quandoPesquisar,
  navegarEditar,
  logout,
  quandoSacolaClick,
  setConfigurando,
  limparTermo,
}: CardapioHeaderNavBarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { usuario } = useUsuarioLogado();
  const rotaInvalida = usuario?.invalidoRota(empresaId) || false;
  const [pesquisa, definirPesquisa] = useState(false);
  const classes = useStyles({ imagemUrl, categorias, pesquisa, configurando });
  const history = useHistory();
  const [mostrarQr, setMostrarQr] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [mostrarCategorias, setMostrarCategorias] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMostrarCategorias(
        isEmpty(categorias) === false && Array.isArray(categorias) && !configurando,
      );
    }, 1);
  }, [categorias, somenteLeitura, configurando]);

  useEffect(() => {
    if (!rotaInvalida) {
      definirPesquisa(false);
    }
  }, [rotaInvalida]);
  const wrapNavEdicao = useCallback(() => {
    navegarEditar();
  }, [navegarEditar]);
  const onVisualizarClick = useCallback(() => {
    history.push(`/${empresaId}`);
  }, [history, empresaId]);

  const onEditarConfiguracoesClick = useCallback(() => {
    setConfigurando();
  }, [setConfigurando]);

  const gerarQRCode = useCallback(() => {
    setMostrarQr(true);
  }, []);

  const quandoQRForFechado = useCallback(() => {
    setMostrarQr(false);
  }, []);
  const onPesquisaClick = React.useCallback(() => {
    definirPesquisa((prev) => !prev);
  }, []);

  return (
    <>
      <AppBar
        ref={referencia}
        position="static"
        className={classes.appBar}
        style={{ transition: 'height 0.3s ease-in-out  !important' }}
        color="default"
        elevation={4}
      >
        <Container fixed>
          <div className={classes.conteudoSomenteLeitura}>
            {!isMobile && (
              <div
                className={classNames(classes.flex, rotaInvalida ? classes.containerEmpresa : '')}
              >
                <img src={logoUrl} className={classes.logo} alt="Logotipo do Cliente" />
                {rotaInvalida === false ? (
                  <MeuMenu
                    setCarregando={setCarregando}
                    gerarQRCode={gerarQRCode}
                    onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                    somenteLeitura={somenteLeitura}
                    contextoSelecionado={contextoSelecionado}
                    onVisualizarClick={onVisualizarClick}
                    wrapNavEdicao={wrapNavEdicao}
                  />
                ) : (
                  <BarraPesquisa
                    modo="static"
                    pesquisa={true}
                    termoPesquisa={termoPesquisa}
                    onPesquisaClick={onPesquisaClick}
                    quandoPesquisar={quandoPesquisar}
                    limparPesquisa={limparTermo}
                  />
                )}
              </div>
            )}

            {isMobile && (
              <>
                <DrawerMenu
                  capa={imagemUrl}
                  logoUrl={logoUrl}
                  somenteLeitura={somenteLeitura}
                  rotaInvalida={rotaInvalida}
                  setCarregando={setCarregando}
                  gerarQRCode={gerarQRCode}
                  onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                  onVisualizarClick={onVisualizarClick}
                  wrapNavEdicao={wrapNavEdicao}
                  logout={logout}
                  abrirSacola={quandoSacolaClick}
                  buscarProds={onPesquisaClick}
                />

                <div className={classes.titulo}>
                  <Titulo style={{ fontSize: !somenteLeitura ? '20px' : '2rem' }}>
                    {!somenteLeitura ? 'Editando Cardápio' : 'Cardápio'}
                  </Titulo>
                </div>
              </>
            )}
            <div className={classes.flex}>
              {rotaInvalida === false && !configurando && (
                <Fade in={!pesquisa} timeout={200}>
                  <IconButton
                    onClick={onPesquisaClick}
                    aria-label="pesquisar"
                    aria-haspopup="true"
                    className={classes.searchIcon}
                    style={{
                      pointerEvents: !pesquisa ? 'all' : 'none',
                      opacity: !pesquisa ? 1 : 0,
                    }}
                  >
                    <SearchRoundedIcon style={{ fontSize: '35px' }} />
                  </IconButton>
                </Fade>
              )}

              <div className={classes.btnSacola}>
                <ButtonSacola
                  empresaId={empresaId}
                  quandoClicado={quandoSacolaClick}
                  colorBadge="secondary"
                  corLogo="textPrimary"
                  size="35"
                />
              </div>
              {!isMobile && (
                <div className={classes.btnMenuLogin}>
                  <MenuConta
                    logout={logout}
                    rotaInvalida={rotaInvalida}
                    setCarregando={setCarregando}
                    somenteLeitura={somenteLeitura}
                    wrapNavEdicao={wrapNavEdicao}
                    nomeUsuario={usuario?.dados?.usuarioNome}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>

        <Fade in={mostrarCategorias} mountOnEnter unmountOnExit timeout={200}>
          <CategoriaTabs
            categorias={categorias}
            categoriaClick={categoriaClick}
            categoriaSelecionada={categoriaSelecionada}
          />
        </Fade>

         
          <BarraPesquisa
            pesquisa={pesquisa && !configurando}
            termoPesquisa={termoPesquisa}
            onPesquisaClick={onPesquisaClick}
            quandoPesquisar={quandoPesquisar}
            limparPesquisa={limparTermo}
          />
        
      </AppBar>

      <QRCodeModal
        aberto={mostrarQr}
        quandoForFechado={quandoQRForFechado}
        empresaId={empresaId}
        nomeEmpresa={nomeEmpresa}
        infoEmpresa={infoEmpresa}
      />
      <Carregando
        carregando={carregando}
        modelo="fixed"
        titulo={'Carregando...'}
        mensagem={`Aguarde enquanto carregamos a ${
          somenteLeitura ? 'edição' : 'visualização'
        } do cardápio...`}
        className={classes.carregando}
      />
    </>
  );
};

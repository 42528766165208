import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { CardapioEmpresa } from 'api/cardapio/models';
import { isEmpty } from 'lodash';
import { useResources } from 'resources';
interface CardapioFooterProps {
  empresa?: CardapioEmpresa;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    boxShadow: '-2px 0px 5px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    overflow: 'hidden',
    padding: '20px',
    paddingBottom: '15px',
    position: 'relative',
  },
  logo: {
    maxHeight: '40px',
    margin: 0,
    marginRight: 20,
    objectFit: 'contain',
  },
  containerEmpresa: {
    zIndex: 1,
    width: '100%',
    position: 'relative',
    flex: '1 0 auto',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBg: {
    zIndex: 0,
    opacity: 0.3,
    backgroundPosition: 'bottom center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

export const CardapioFooter = ({ empresa }: CardapioFooterProps) => {
  const classes = useStyles();
  const resources = useResources();
  return (
    <footer className={classes.footer}>
      <div
        className={classes.footerBg}
        style={{ backgroundImage: `url('${empresa?.capa}')` }}
      ></div>
      <div className={classes.containerEmpresa}>
        {!isEmpty(empresa?.logo) && (
          <img src={empresa?.logo} className={classes.logo} alt="Logotipo do Cliente"></img>
        )}
        <div>
          <Typography variant="h6" align="left">
           {resources.paginas.cardapioFooter.tituloFooter(empresa?.nome)}
          </Typography>
          <Typography variant="body2" align="left" style={{ marginTop: -5 }}>
            {resources.paginas.cardapioFooter.textoFooter}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

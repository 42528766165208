import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { CardapioCategoria } from 'api/cardapio/models';
import { obterCategorias } from 'api/cardapio/queries';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { isEmpty } from 'lodash';

export interface SalvarCategoriaInput extends CardapioCategoria {
  empresaId: string;
}

class AtualizarCategoria implements ApiMutation<SalvarCategoriaInput, CardapioCategoria> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ empresaId, ...categoria }: SalvarCategoriaInput) {
    let env: any = {
      paiId: categoria?.paiId ? categoria?.paiId : null,
      id: categoria?.id,
      descricao: categoria?.descricao,
      rank: categoria?.rank,
      cor: categoria?.cor,
      ativo: categoria?.ativo,
      imagemUrl: categoria?.imagemUrl ? categoria?.imagemUrl : null,
    };

    if (isEmpty(categoria?.id)) {
      delete env.id;
    }

    return FetchCardapioApiAsMasterUserJSON<CardapioCategoria>(
      `/${empresaId}/categoria`,
      {
        method: isEmpty(categoria?.id) ? 'POST' : 'PUT',
        body: JSON.stringify(env as SalvarCategoriaInput),
      }).then(
        (json) => (json as CardapioCategoria));
  }

  updateCache(input: SalvarCategoriaInput, retorno: CardapioCategoria, cache: QueryCache) {
    const todasAsCategoriasKey = obterCategorias.getKey(input.empresaId);
    return cache.invalidateQueries(todasAsCategoriasKey);
  }
}

export const atualizarCategoria = new AtualizarCategoria();

export const useAtualizarCategoria = (
  config?: MutationConfig<CardapioCategoria, unknown, SalvarCategoriaInput>,
  successMessage?: string
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: successMessage || 'Categoria atualizada com sucesso!',
    },
    atualizarCategoria,
  );

  return useMutation(atualizarCategoria.execute, MergeDeep(defaultConfig, config));
};

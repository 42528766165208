import * as Yup from 'yup';

export const EditarDadosFormValidation = Yup.object().shape({
  empresaMaster: Yup.object().shape({
    lgr: Yup.string().required('O endereço é obrigatório.'),
    nro: Yup.string().required('Número obrigatório.'),
    bairro: Yup.string().required('O bairro obrigatório.'),
    cep: Yup.string()
      .required('CEP inválido.')
      .test('len', 'CEP inválido.', (val) => {
        const val_length_without_dashes = val?.replace(/-|_/g, '').length;
        return val_length_without_dashes === 8;
      }),
    cpl: Yup.string(),
    municipio: Yup.string().required('O município é obrigatório.'),
    uf: Yup.string().required('UF inválida.'),
    fant: Yup.string().required('Nome fantasia obrigatório.'),
    telefone: Yup.string().nullable().optional().min(10, 'Telefone inválido.'),
  }),
  cardapioEmpresa: Yup.object().shape({
    nome: Yup.string(),
    informacao: Yup.string(),
    segmento: Yup.string(),
    horarios: Yup.object().shape({
      segundas: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      tercas: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      quartas: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      quintas: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      sextas: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      sabados: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      domingos: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
      feriados: Yup.object().shape({
        abertura: Yup.string().nullable(),
        fechamento: Yup.string().nullable(),
      }),
    }),
  }),
});

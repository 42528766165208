import { VariaveisAmbiente } from "ambiente";

export const GetAuthCallbackUrl = (rota:string) => {
  const urlAtual = new URL(window.location.href);
  const novaUrlCallback = new URL(urlAtual.origin + '/login');
  novaUrlCallback.searchParams.set('token', '@token');
  if(rota.length>0){
    novaUrlCallback.searchParams.set('rota', rota); 
  }
  return btoa(novaUrlCallback.href.replace(/%40/gi, '@'));
};

export const GetAuthRedirect = (rota:string='') =>{
    return VariaveisAmbiente.authFront + `login/auth?callback=${GetAuthCallbackUrl(rota)}`;
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerTelaCheia: {
    background: 'transparent',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    zIndex: 9999999,
    height: '100%',
    width: '100%',
    position: 'fixed',
    margin: '0',
    padding: '0',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  containerTelaCheiaModal: {
    background: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    zIndex: 9999999,
    height: '100%',
    width: '100%',
    position: 'absolute',
    margin: '0',
    padding: '0',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  spinner: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    marginBottom: '15px',
  },
  loadingGif:{
    height:'80px'
  },
}));

import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, ItemSacola, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';


export interface AtualizarSacolaInput {
  item: ItemSacola;
  idEmpresa: string;
  editando: boolean;
}

class AtualizarSacola implements ApiMutation<AtualizarSacolaInput, ItemSacola> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ item, editando, idEmpresa }: AtualizarSacolaInput) {
    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
    ) as ItemSacola[];
    let itemProcurado;

    if (Object.entries(itens).length === 0) {
      itemProcurado = undefined;
      itens = [] as ItemSacola[];
    } else {
      itemProcurado = itens.find((val) => val.id === item.id);
    }
    item.idRestaurante = idEmpresa;
    if (itemProcurado) {
      if (!editando) {
        itemProcurado.qtd += item.quantidade;
      } else {
        itemProcurado.qtd = item.quantidade;
        itemProcurado.obs = item.observacao;
      }
    } else {
      itens.push(item);
    }
    return storageCardapio
      .save(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(), itens)

  }

  updateCache(input: AtualizarSacolaInput, retorno: ItemSacola, cache: QueryCache) {
    const sessoesKey = obterSacola.getKey({ empresaId: input.idEmpresa });
    return cache.invalidateQueries(sessoesKey);
  }
}

export const atualizarSacola = new AtualizarSacola();

export const useAtualizarSacola = (
  config?: MutationConfig<ItemSacola, unknown, AtualizarSacolaInput>

) => {
 
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Sacola Atualizada!',
    },
    atualizarSacola,
  );

  return useMutation(atualizarSacola.execute, MergeDeep(defaultConfig, config));
};

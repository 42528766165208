import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carregando, Botao } from 'componentes';
import { makeStyles, Container } from '@material-ui/core';
import { useAtualizarDadosEmpresa } from 'api/cardapio/mutations';
import { useAtualizarDadosEmpresaMaster } from 'api/master/mutations';
import { useObterDadosEmpresaMaster } from 'api/master/queries';
import { useObterDadosEmpresa } from 'api/cardapio/queries';
import { CardapioEmpresa, CardapioEmpresaTema } from 'api/cardapio/models';
import { EmpresaMaster } from 'api/master/models';
import { MergedCardapioMaster } from 'api/merged';
import { EditarTemaForm } from '../editar-tema-form';
import { EditarDadosForm } from '../edicao-dados-form';
import { useNotificacoes } from 'componentes';
import { EditarDadosInstancia } from './edicao-dados-instancia';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  container: { marginTop: '1rem' },
  fab: {
    position: 'sticky',
    bottom: theme.spacing(4),
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    marginTop: '.5rem',
    paddingRight: '.5rem',
  },
  carregando: { minHeight: '400px' },
}));

export interface EditarDadosModalProps {
  aberto: boolean;
  empresaId: string;
  quandoForFechado: any;
}

export const EditarDados = ({ aberto, empresaId, quandoForFechado }: EditarDadosModalProps) => {
  const classes = useStyles();
  const { showMessage, showErrorMessage } = useNotificacoes();
  //mutations
  const [atualizarEmpresa, { isLoading: atualizandoEmpresa }] = useAtualizarDadosEmpresa();
  const [
    atualizarEmpresaMaster,
    { isLoading: atualizandoEmpresaMaster },
  ] = useAtualizarDadosEmpresaMaster();

  //queries
  const { refetch: buscarEmpresa, isLoading: carregandoEmpresa, ...empresa } = useObterDadosEmpresa(
    empresaId,
    {
      enabled: empresaId,
    },
  );
  const {
    refetch: buscarEmpresaMaster,
    isLoading: carregandoEmpresaMaster,
    ...empresaMaster
  } = useObterDadosEmpresaMaster(empresaId, {
    enabled: empresaId,
  });

  const [carregando, setCarregando] = useState(true);
  const [carregandoBtn, setCarregandoBtn] = useState(false);
  const refFormDados = useRef<EditarDadosInstancia>(null);
  const refFormTema = useRef<EditarDadosInstancia>(null);
  const refMerged = useRef<MergedCardapioMaster>({} as MergedCardapioMaster);
  const [merged, setMerged] = useState<MergedCardapioMaster>({} as MergedCardapioMaster);

  //controle do carregando
  useEffect(() => {
    if (aberto)
      setTimeout(() => {
        setCarregando(false);
      }, 1);
  }, [aberto]);

  useEffect(() => {
    if (aberto && !carregandoEmpresa && !carregandoEmpresaMaster) {
      setMerged({
        empresaMaster: empresaMaster.data,
        cardapioEmpresa: empresa.data,
      } as MergedCardapioMaster);
    }
  }, [aberto, empresaMaster.data, empresa.data, carregandoEmpresa, carregandoEmpresaMaster]);

  const salvarDadosEmpresaMaster = useCallback(
    async (empresa: EmpresaMaster) => {
      let master = empresaMaster.data;
      try {
        master!.lgr = empresa?.lgr;
        master!.nro = empresa?.nro;
        master!.bairro = empresa?.bairro;
        master!.cep = empresa?.cep.replace(/\D/g, '');
        master!.cpl = empresa?.cpl;
        master!.uf = empresa?.uf;
        master!.telefone = empresa?.telefone;
        master!.fant = empresa?.fant
        atualizarEmpresaMaster(master);
      } catch {
        showMessage('ocorreu um error ao salvar dados', 'error');
      }
    },
    [atualizarEmpresaMaster, showMessage, empresaMaster.data],
  );

  const salvarDadosCardapio = useCallback(
    (cardapio: CardapioEmpresa) => {
      atualizarEmpresa({
        empresaId,
        style: cardapio.style,
        informacao: cardapio.informacao,
        nome: cardapio.nome,
        segmento: cardapio.segmento,
        horarios: cardapio.horarios,
        permiteEntrega:cardapio.permiteEntrega,
        permiteRetirada:cardapio.permiteRetirada,
        statusLoja:cardapio.statusLoja
      });
    },
    [empresaId, atualizarEmpresa],
  );

  //quando fechar
  const fechar = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    setTimeout(() => {
      setCarregando(true);

      quandoForFechado();
    }, 500);
  }, [quandoForFechado]);

  const salvarConfiguracoes = useCallback(
    (tema: CardapioEmpresaTema) => {
      let merged = refMerged.current;
      merged.cardapioEmpresa.style = tema;

      salvarDadosCardapio(merged.cardapioEmpresa);
      salvarDadosEmpresaMaster(merged.empresaMaster);
      fechar();
    },

    [salvarDadosEmpresaMaster, salvarDadosCardapio, fechar],
  );

  const salvarDadosCadastrais = useCallback((empresa: MergedCardapioMaster) => {
    setCarregandoBtn(true);
    setTimeout(() => {
      refMerged.current = empresa;
      if (refFormTema) {
        refFormTema.current?.submit();
      }
    }, 500);
  }, []);

  const submitForms = useCallback(() => {
    if (refFormDados) {
      let hasErrors = refFormDados.current?.submit();
      if (hasErrors) {
        try {
          let message = Object.values(hasErrors[Object.keys(hasErrors)[0]])[0] as string;
          showErrorMessage(message || 'Preencha todos os dados');
        } catch {
          showErrorMessage('Preencha todos os dados');
        }
      }
    }
  }, [showErrorMessage]);

  if (carregando) {
    return (
      <Carregando
        className={classes.carregando}
        carregando={true}
        modelo="normal"
        titulo={
          atualizandoEmpresa || atualizandoEmpresaMaster
            ? 'Salvando Configurações'
            : 'Carregando configurações'
        }
        mensagem={
          atualizandoEmpresa || atualizandoEmpresaMaster
            ? 'Por favor aguarde enquanto salvamos as informações...'
            : 'Por favor aguarde enquanto carregamos as informações...'
        }
      ></Carregando>
    );
  }

  return (
    <Container className={classes.container} fixed>
      <EditarDadosForm
        ref={refFormDados}
        empresa={merged}
        salvarDados={salvarDadosCadastrais}
        atualizandoDados={atualizandoEmpresaMaster}
      />
      <EditarTemaForm ref={refFormTema} alterarTema={salvarConfiguracoes} />
      <div className={classes.fab}>
        <Botao color="secondary" style={{ marginRight: '1rem' }} onClick={fechar}>
          Voltar
        </Botao>
        <Botao carregando={carregandoBtn} color="primary" onClick={submitForms}>
          <SaveIcon style={{marginRight:'5px'}} />
          Salvar
        </Botao>
      </div>
    </Container>
  );
};

import { ApiQuery } from 'api/interfaces';
import { FetchPedidosApiWithUserJSON } from '../utils';
import { Pedido } from '../models';
import { UsuarioMaster } from 'componentes';

class ObterDadosPedidos implements ApiQuery<string, Pedido> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(empresaId: string): any[] {
        return ['ObterDadosPedidos', empresaId];
    }

    execute(documento: string) {
        var token = UsuarioMaster.fromLocalStorageToken()?.token || '';
        return FetchPedidosApiWithUserJSON<any>(
            token,
            `/v1/pedido`,
            {
                headers: {
                    documento: documento
                }
            }
        ).then((json) => {
            return json;
        });
    }
}

export const obterDadosPedidos = new ObterDadosPedidos();